import { Label } from "@/components/ui/label";
import { RadioGroupItem } from "@/components/ui/radio-group";

type Props = { id: number; label: string; defaultChecked: boolean };

export const RadioQuiz = ({ label, id, defaultChecked }: Props) => {
  return (
    <div className="mx-auto grid w-[180px] grid-cols-[auto_1fr] items-center lg:flex lg:flex-col-reverse lg:items-center lg:gap-4">
      <RadioGroupItem
        className="mr-auto border-solid border-darkGreen lg:mr-0"
        value={label}
        id={id.toString()}
        checked={defaultChecked}
      />

      <Label
        htmlFor={id.toString()}
        className="w-full text-center font-quicksand text-[15px] font-[400] text-gray26 lg:text-lg"
      >
        {label}
      </Label>
    </div>
  );
};
