export const US_FINANCIAL_OPTION: Array<{ title: string; detail: string }> = [
  {
    title: "$7,500 Federal Tax Credit",
    detail:
      "Eligible  buyers receive $7,500 off the purchase price of all new 2024 Model Y.  Applied to the purchase price at time of delivery.",
  },
  {
    title: "Lease Starting at $379 /mo",
    detail:
      "Excludes taxes and fees with price subject to change. Available in select states. Demo vehicles are not eligible for lease.",
  },
];
