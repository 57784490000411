import { gql } from "@apollo/client";

export const postsQuery = gql`
  query getPosts(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $categoryId: Int
  ) {
    posts(
      first: $first
      last: $last
      after: $after
      before: $before
      where: { search: $search, categoryId: $categoryId }
    ) {
      edges {
        cursor
        node {
          categories {
            nodes {
              name
            }
          }
          tags {
            nodes {
              name
            }
          }
          id
          date
          title
          content
          databaseId
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
        }
      }
      pageInfo {
        total
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

export const categoriesQuery = gql`
  query getCategories {
    categories {
      nodes {
        name
        databaseId
      }
    }
  }
`;

export const postQuery = gql`
  query getPost($id: ID!) {
    post(id: $id, idType: DATABASE_ID) {
      content
      date
      featuredImage {
        node {
          altText
          description
          sourceUrl
          id
        }
      }
      title
      uri
      databaseId
      slug
    }
  }
`;
