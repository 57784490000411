import { WhyUs } from "@/types/whyUs";
import { Banner } from "../types/banner";
import { Intro } from "../types/intro";
import { Mission } from "@/types/mission";

export const ABOUT_US_HEADER: Intro = {
  title: "We work for your future",
  description:
    "At GreenCarLane, our dedicated team of experienced and ambitious individuals is working towards sustainable development and reducing emissions and noise pollution from transportation.",
  image: [
    "/images/aboutUs/about-us-header-small.webp",
    "/images/aboutUs/about-us-header.webp",
  ],
};

export const ABOUT_US_MISSION: Mission[] = [
  {
    title: "Our mission is to increase Low Emission Vehicles worldwide",
    description:
      "We aim at to increase them from **2% to 50% worldwide by 2040**. We believe that by guiding consumers towards the right electric vehicle and charging infrastructure, we can help reduce overwhelm and anxiety while contributing to a healthier and more robust planet.",
    image: "/images/aboutUs/mission1.webp",
    alt: "green car lane mission",
  },
  {
    title: "Environmentally-Friendly & Sustainable Transportation",
    description:
      "Our vision is for transportation worldwide to be environmentally friendly and sustainable through the production process and the vehicle's entire life cycle. We believe that by combining technological prowess, environmental prosperity, sustainable development, and business acumen, we can create a better future for us all.",
    image: "/images/aboutUs/mission2.webp",
    alt: "green car lane mission",
  },
];

export const ABOUT_US_BANNER: Banner = {
  title: "Partnering for Environmental Prosperity",
  subheading: `We partner with public administrations, municipalities, businesses and other stakeholders to manage climate goals within transportation, including charging infrastructure deployment and parking. Our digital solutions for consumers, public administration, and corporate stakeholders provide support and peace of mind, helping to nudge consumers in the right direction towards a greener future.
  Do you work with sustainability goals? We can work together:`,
  image: "/images/aboutUs/partners-GreenCarLane.webp",
};

export const ABOUT_US_WHY: WhyUs[] = [
  {
    title: "Climate & Environmental Impact",
    description:
      "Align mutual objectives for higher impact. By supporting the electric vehicle transition through our platform, stakeholders and partners contribute to a cleaner, greener and healthier future.",
    image: "/images/aboutUs/why-us-1.webp",
    alt: "Climate environment",
  },
  {
    title: "Long-Term Growth Potential",
    description:
      "With increasing global demand and continuous innovation, investing and collaborating in this sector offers the prospect of enduring growth as well as development, making it an attractive option for businesses.",
    image: "/images/aboutUs/why-us-2.webp",
    alt: "Long-Term Growth Potential",
  },
  {
    title: "Innovation Catalyst",
    description:
      "Investing in the electric vehicle & charging sectors and enhancing digitisation in the automotive sector means participating in ground breaking advancements for stakeholders, partners and customers.",
    image: "/images/aboutUs/why-us-3.webp",
    alt: "Innovation Catalyst",
  },
];
