import { Member } from "@/types/teamMembers";

export const TEAM_MEMBERS: Member[] = [
  {
    name: "Tim Paarup Dridi",
    role: "Commercial",
    image: "/images/Team_members/Tim-Paarup-Dridi-GreenCarLane.webp",
  },
  {
    name: "Bogumiła Stępień",
    role: "Product Researcher & UX/UI",
    image: "/images/Team_members/Bogumiła-Stępień-GreenCarLane.webp",
  },
  {
    name: "Helena Maretti",
    role: "Product Researcher",
    image: "/images/Team_members/Helena-Maretti-GreenCarLane.webp",
  },
  {
    name: "Anita Tran",
    role: "Product Researcher",
    image: "/images/Team_members/Anita-Tran-GreenCarLane.webp",
  },
  {
    name: "Eleni Papageorgiou",
    role: "Data Scientist",
    image: "/images/Team_members/Eleni-Papageorgiou-GreenCarLane.webp",
  },
  {
    name: "Yun Qin",
    role: "Data Engineer",
    image: "/images/Team_members/Yun-Qin-GreenCarLane.webp",
  },
  {
    name: "Alexandra Festila",
    role: "Data Engineer",
    image: "",
  },
  {
    name: "Hanna Huzava",
    role: "Front-end Developer",
    image: "/images/Team_members/Hanna-Huzava-GreenCarLane.webp",
  },
  {
    name: "Michele Carmagnani",
    role: "Front-end Developer",
    image: "/images/Team_members/Michele-Carmagnani-GreenCarLane.webp",
  },
  {
    name: "Vivian De Mitry",
    role: "Front-end Developer",
    image: "/images/Team_members/Vivian-De-Mitry-GreenCarLane.webp",
  },
  {
    name: "Roman Kuzero",
    role: "Front-end Developer",
    image: "/images/Team_members/Roman-Kuzero-GreenCarLane.webp",
  },
  {
    name: "Sakura Tanaka",
    role: "Front-end Developer",
    image: "/images/Team_members/Sakura -Tanaka-GreenCarLane.webp",
  },
  {
    name: "Bryan Bessieres",
    role: "Back-end Developer",
    image: "/images/Team_members/Bryan-Bessieres-GreenCarLane.webp",
  },
  {
    name: "Kostas Stathakis",
    role: "ICT/Data Analytics",
    image: "/images/Team_members/Kostas-Stathakis-GreenCarLane.webp",
  },
  {
    name: "Kevin Gida",
    role: "Full Stack Web Developer",
    image: "/images/Team_members/Kevin-Gida-GreenCarLane.webp",
  },
  {
    name: "Fernanda Souza",
    role: "Front-end Developer",
    image: "/images/Team_members/Fernanda-Souza-GreenCarLane.webp",
  },
  {
    name: "Hajar El Mhassani",
    role: "Software developer",
    image: "/images/Team_members/Hajar-El-Mhassani-GreenCarLane.webp",
  },
  {
    name: "Aleksandra Zawisza",
    role: "UX/UI Designer",
    image: "/images/Team_members/Aleksandra-Zawisza-GreenCarLane.webp",
  },
  {
    name: "Elena Matoska",
    role: "UX/UI Designer",
    image: "/images/Team_members/Elena -Matoska-GreenCarLane.webp",
  },
  {
    name: "Maja Piwowar",
    role: "UX/UI Designer",
    image: "/images/Team_members/Maja-Piwowar-GreenCarLane.webp",
  },
  {
    name: "Diana Sabitova",
    role: "UX/UI Designer",
    image: "/images/Team_members/Diana-Sabitova-GreenCarLane.webp",
  },
  {
    name: "Estella Pütsep",
    role: "UX/UI Designer",
    image: "/images/Team_members/Estella-Pütsep-GreenCarLane.webp",
  },
  {
    name: "Laura Lievajová",
    role: "Social Media Marketer",
    image: "/images/Team_members/Laura-Lievajová-GreenCarLane.webp",
  },
  {
    name: "Alevtina Roshchina",
    role: "Marketing and Content",
    image: "/images/Team_members/Alevtina-Roshchina-GreenCarLane.webp",
  },
  {
    name: "Danila Mollo",
    role: "Graphic Designer",
    image: "/images/Team_members/Danila-Mollo-GreenCarLane.webp",
  },
  {
    name: "Alejandra Bolaños",
    role: "Graphic Designer",
    image: "/images/Team_members/Alejandra-Bolaños-GreenCarLane.webp",
  },
  {
    name: "Kristina Lysak",
    role: "UX/UI Designer",
    image: "/images/Team_members/Kristina-Lysak-FreenCarLane.webp",
  },
  {
    name: "Mario Afonso",
    role: "Graphic Designer",
    image: "/images/Team_members/Mario-Afonso-GreenCarLane.webp",
  },
];
