import { SVGProps } from "@/features/quiz/types/svg";

export const Spinner = ({ className, color }: SVGProps) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 48 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M34.9481 1.34323C39.2911 3.76213 42.7108 7.55122 44.673 12.1187C46.6352 16.6863 47.0296 21.775 45.7945 26.5903C44.5593 31.4056 41.7642 35.6763 37.8458 38.7354C33.9273 41.7945 29.106 43.4698 24.1349 43.4996C19.1638 43.5294 14.3228 41.9121 10.3679 38.9002C6.41303 35.8883 3.56695 31.6514 2.27416 26.8513C0.981362 22.0512 1.31465 16.958 3.22197 12.3673C5.12929 7.77657 8.50325 3.94675 12.8169 1.47594"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
};
