import { SVGProps } from "@/features/quiz/types/svg";

export const Loader = ({ className, color }: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="81"
      viewBox="0 0 80 81"
      fill="none"
      className={className}
    >
      <path
        d="M80 40.5C80 62.5914 62.0914 80.5 40 80.5C17.9086 80.5 0 62.5914 0 40.5C0 18.4086 17.9086 0.5 40 0.5C62.0914 0.5 80 18.4086 80 40.5ZM7.27753 40.5C7.27753 58.5721 21.9279 73.2225 40 73.2225C58.0721 73.2225 72.7225 58.5721 72.7225 40.5C72.7225 22.4279 58.0721 7.77753 40 7.77753C21.9279 7.77753 7.27753 22.4279 7.27753 40.5Z"
        fill="url(#paint0_linear_6886_28410)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6886_28410"
          x1="7.16667"
          y1="8.09656"
          x2="106.239"
          y2="31.4261"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.345145" stopColor="#8BC63F" stopOpacity="0.7" />
          <stop offset="0.617159" stopColor="#3CB64A" stopOpacity="0.6" />
          <stop offset="1" stopColor="#024034" stopOpacity="0.79" />
        </linearGradient>
      </defs>
    </svg>
  );
};
