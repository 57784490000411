import { darkGreen } from "@/styles/colors";
import { ButtonInfoTrigger } from "../components/ButtonInfoTrigger";
import { GroupButtonCarType } from "../components/GroupButtonCarType";
import { GroupButtonStorage } from "../components/GroupButtonStorage";
import { GroupButtonText } from "../components/GroupButtonText";
import { GroupRadio } from "../components/GroupRadio";
import { SelectCarBrand } from "../components/SelectCarBrand";
import { SelectCountry } from "../components/SelectCountry";
import { SelectPrice } from "../components/SelectPrice";
import { SelectQuiz } from "../components/SelectQuiz";
import { TrunkIcon } from "../svgs/TrunkIcon";
import { Question } from "../types/question";
import {
  ACCESSORIES_OPTIONS,
  CHARGING_OPTIONS,
  DELIVERY_TIME_OPTIONS,
  EDUCATION_OPTIONS,
  GENDER_OPTIONS,
  IN_A_GROUP_OPTIONS,
  LIKERT_SCALE,
  RANGE_OPTIONS,
  RESIDENCE_TYPE_OPTIONS,
  SEAT_OPTIONS,
  TOWING_OPTIONS,
  WARRANTY_TIME_OPTIONS,
} from "./quiz-option";

const h1Style =
  "font-[500] font-poppins text-lg text-gray26 text-center md:text-2xl";
const questionContainerStyle = "flex flex-col gap-5";
const questionInfoStyle =
  "text-gray26 font-[15px] font-quicksand text-center max-w-[90%] md:text-lg mx-auto";

export const QUESTIONS: Question[] = [
  {
    id: 0,
    num: "Q20",
    question: <h1 className={h1Style}>What is your country of residence?</h1>,
    answer: (answerKey) => <SelectCountry answerKey={answerKey} />,
    answerKey: "residence_country",
  },
  {
    id: 1,
    num: "break",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>Let’s keep going!</h1>
        <p className={questionInfoStyle}>
          In the next few questions, we’d like to learn about your car
          preferences and driving habits to find the best car for your needs.
        </p>
      </div>
    ),
    answer: () => null,
  },
  {
    id: 2,
    num: "Q1",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>What’s your budget for a car? </h1>
      </div>
    ),
    answer: (answerKey: string) => <SelectPrice answerKey={answerKey} />,
    answerKey: "budget",
  },
  {
    id: 3,
    num: "Q5",
    question: (
      <h1 className={h1Style}>How many seats do you want in the car?</h1>
    ),
    answer: (answerKey) => {
      return (
        <GroupButtonText
          buttonData={SEAT_OPTIONS}
          answerKey={answerKey}
          className="px-4 py-[14px]"
        />
      );
    },
    answerKey: "seats",
  },
  {
    id: 4,
    num: "Q4",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>How much trunk space do you need?</h1>
        <div className="flex items-center justify-center gap-2 font-quicksand text-sm font-[400] md:text-lg">
          <span>1</span>
          <span> x</span>

          <TrunkIcon color={darkGreen} className="w-4 md:w-5" />
          <span className="w-fit text-center">
            = 60x43x26 cm (24x17x10 inches) and 50-60 liters
          </span>
        </div>
      </div>
    ),
    answer: (answerKey) => <GroupButtonStorage answerKey={answerKey} />,
    answerKey: "cargo_space",
  },
  {
    id: 5,
    num: "Q3",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>What body style are you looking for?</h1>
        <span className="text-center font-quicksand text-[15px] font-normal text-gray26 md:hidden">
          <ButtonInfoTrigger /> to see body descriptions.
        </span>
      </div>
    ),
    answer: (answerKey) => <GroupButtonCarType answerKey={answerKey} />,
    answerKey: "body_style",
  },
  {
    id: 6,
    num: "Q2",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>What brand do you prefer?</h1>
        <p className={questionInfoStyle}>
          You can be sure that the selection will not narrow down the end
          results of the quiz. We will just make sure that the brand you chose
          will appear in a few results if criteria matches any of their models.
        </p>
      </div>
    ),
    answer: (answerKey) => <SelectCarBrand answerKey={answerKey} />,
    answerKey: "fav_brand",
  },
  {
    id: 7,
    num: "Q7",
    question: (
      <h1 className={h1Style}>
        How many kilometers do you expect to drive on average per week?
      </h1>
    ),
    answer: (answerKey) => {
      return (
        <GroupButtonText
          buttonData={RANGE_OPTIONS}
          answerKey={answerKey}
          className="px-4 py-[14px]"
        />
      );
    },
    answerKey: "week_kms",
  },
  {
    id: 8,
    num: "Q8",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>What are your charging options?</h1>
        <p className={questionInfoStyle}>
          This is a multiple choice question. You can choose up to 3 options.
        </p>
      </div>
    ),

    answer: (answerKey) => {
      return (
        <GroupButtonText
          isMultipleChoice={true}
          buttonData={CHARGING_OPTIONS}
          answerKey={answerKey}
          className="px-4 py-[14px]"
        />
      );
    },
    answerKey: "charge_options",
  },
  {
    id: 9,
    num: "Q9",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>What do you expect to tow?</h1>
        <p className={questionInfoStyle}>
          This is a multiple choice question. You can choose up to 3 options.
        </p>
        <img
          src="/quiz/cargo.svg"
          className="mx-auto w-[140px] md:w-[220px]"
          alt="Car with tow pulling another car"
        />
        <p className={questionInfoStyle}>
          Tow means to pull another vehicle along with a rope, chain, or tow bar
        </p>
      </div>
    ),
    answer: (answerKey) => {
      return (
        <GroupButtonText
          isMultipleChoice={true}
          buttonData={TOWING_OPTIONS}
          answerKey={answerKey}
          className="px-2 py-3 md:px-4 md:py-[14px]"
          containerClassName="grid-cols-3"
        />
      );
    },
    answerKey: "towing",
  },
  {
    id: 10,
    num: "Q10",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>What accessories do you need?</h1>
        <p className={questionInfoStyle}>
          This is a multiple choice question. You can choose up to 3 options.
        </p>
      </div>
    ),
    answerKey: "accessories",
    answer: (answerKey) => {
      return (
        <GroupButtonText
          isMultipleChoice={true}
          buttonData={ACCESSORIES_OPTIONS}
          answerKey={answerKey}
          className="px-2 py-3 md:px-4 md:py-[14px]"
        />
      );
    },
  },
  {
    id: 11,
    num: "Q21",
    question: (
      <h1 className={h1Style}>
        How long are you willing to wait for your car delivery?
      </h1>
    ),
    answerKey: "delivery",
    answer: (answerKey) => {
      return (
        <GroupRadio radioData={DELIVERY_TIME_OPTIONS} answerKey={answerKey} />
      );
    },
  },
  {
    id: 12,
    num: "Q6",
    question: <h1 className={h1Style}>What minimum warranty do you expect?</h1>,
    answerKey: "warranty",
    answer: (answerKey) => {
      return (
        <GroupRadio radioData={WARRANTY_TIME_OPTIONS} answerKey={answerKey} />
      );
    },
  },
  {
    id: 13,
    num: "break",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>You’re doing great!</h1>
        <p className={questionInfoStyle}>
          Now, please select the statements that best describe you so we can
          match you with the car choices that suit your personality.
        </p>
      </div>
    ),
    answer: () => null,
  },
  {
    id: 14,
    num: "Q11",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>
          "When I'm in a group of people, I'm most likely to..."
        </h1>
        <p className={questionInfoStyle}>
          Choose the option that describes you best.
        </p>
      </div>
    ),
    answerKey: "in_a_group",
    answer: (answerKey) => {
      return (
        <GroupButtonText
          buttonData={IN_A_GROUP_OPTIONS}
          answerKey={answerKey}
          className="px-4 py-[14px]"
        />
      );
    },
  },
  {
    id: 15,
    num: "Q12",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>
          "I plan ahead while making an important decision."
        </h1>
        <p className={questionInfoStyle}>
          Please rate to what extent you agree with the sentence above.
        </p>
      </div>
    ),
    answerKey: "decisions",
    answer: (answerKey) => {
      return <GroupRadio radioData={LIKERT_SCALE} answerKey={answerKey} />;
    },
  },
  {
    id: 16,
    num: "Q13",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>"I am open to new ideas."</h1>
        <p className={questionInfoStyle}>
          Please rate to what extent you agree with the sentence above.
        </p>
      </div>
    ),
    answerKey: "new_ideas",
    answer: (answerKey) => {
      return <GroupRadio radioData={LIKERT_SCALE} answerKey={answerKey} />;
    },
  },
  {
    id: 17,
    num: "Q14",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>
          "I remain calm and efficient during emergencies."
        </h1>
        <p className={questionInfoStyle}>
          Please rate to what extent you agree with the sentence above.
        </p>
      </div>
    ),
    answerKey: "emergencies",
    answer: (answerKey) => {
      return <GroupRadio radioData={LIKERT_SCALE} answerKey={answerKey} />;
    },
  },
  {
    id: 18,
    num: "break",
    question: (
      <div className={questionContainerStyle}>
        <h1 className={h1Style}>You are almost there!</h1>
        <p className={questionInfoStyle}>
          In these final four questions, we’d like to get to know you a little
          better to recommend the perfect car for you.
        </p>
      </div>
    ),
    answer: () => null,
  },
  {
    id: 19,
    num: "Q16",
    question: <h1 className={h1Style}>What year were you born?</h1>,
    answerKey: "year_of_birth",
    answer: (answerKey) => {
      const generateYearOptions = () => {
        const options = [];
        const startYear = 1900;
        const endYear = new Date().getFullYear() - 18;
        for (let i = endYear; i >= startYear; i--) {
          options.push({ answerId: endYear - i + 1, amount: i });
        }
        return options;
      };

      return (
        <SelectQuiz
          options={generateYearOptions()}
          answerKey={answerKey}
          placeholder="Select a year"
        />
      );
    },
  },
  {
    id: 20,
    num: "Q17",
    question: <h1 className={h1Style}>What is your gender?</h1>,
    answerKey: "gender",
    answer: (answerKey) => {
      return (
        <GroupButtonText
          buttonData={GENDER_OPTIONS}
          answerKey={answerKey}
          className="px-4 py-[14px]"
        />
      );
    },
  },
  {
    id: 21,
    num: "Q18",
    question: (
      <h1 className={h1Style}>
        What is your highest achieved level of education?
      </h1>
    ),
    answerKey: "education",
    answer: (answerKey) => {
      return (
        <SelectQuiz
          options={EDUCATION_OPTIONS}
          answerKey={answerKey}
          placeholder="Select an educational level"
        />
      );
    },
  },
  {
    id: 22,
    num: "Q19",
    question: <h1 className={h1Style}>What is your current residence type?</h1>,
    answerKey: "residence_type",
    answer: (answerKey) => {
      return (
        <SelectQuiz
          options={RESIDENCE_TYPE_OPTIONS}
          answerKey={answerKey}
          placeholder="Select a residence type"
        />
      );
    },
  },
  {
    id: 23,
    num: "loading",
    question: (
      <div className="flex flex-col gap-3">
        <h1 className={h1Style}>Congratulations, you finished the Quiz!</h1>
        <p className={questionInfoStyle}>
          Give us a moment while we find the best EV for you!
        </p>
      </div>
    ),
    answer: () => null,
  },
];
