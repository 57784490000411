import { configureStore } from "@reduxjs/toolkit";
import quizReducer from "./quizSlice";

import useFetchReducer from "./useFetchSlice";
import authReducer, { checkTokenExpiration } from "./authSlice";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const persistQuizConfig = {
  key: "quiz",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedQuizReducer = persistReducer(persistQuizConfig, quizReducer);

export const store = configureStore({
  reducer: {
    AUTH: persistedAuthReducer,
    quiz: persistedQuizReducer,
    DATA: useFetchReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },

      checkTokenExpiration,
    }),
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
