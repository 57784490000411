import { quizResultAtom } from "../atoms/quiz";
import { useAtomValue } from "jotai";
import { CardCarSmall } from "./carCard/CarCardSmall";

export const RecommendationMoreSection = () => {
  const quizResult = useAtomValue(quizResultAtom);
  const otherRecommendations = quizResult?.slice(3, -1) || [];

  return (
    <div className="mx-auto grid w-fit gap-6 lg:w-full lg:max-w-[1600px]">
      {otherRecommendations.length > 0 && (
        <h2 className="text-center font-poppins text-xl font-bold text-black lg:text-2xl xl:text-start">
          YOU MAY ALSO LIKE
        </h2>
      )}

      <div className="mx-auto grid w-fit justify-items-center gap-6 lg:w-full lg:grid-cols-2">
        {otherRecommendations.length > 0 &&
          otherRecommendations.map((car, index) => (
            <CardCarSmall car={car} key={index} />
          ))}
      </div>
    </div>
  );
};
