import { carBrandOptionsAtom } from "../atoms/quiz";
import { useAtomValue } from "jotai";
import { SelectQuiz } from "./SelectQuiz";
import { Skeleton } from "@/components/ui/skeleton";

import { ProviderGetCarBrand } from "./ProviderGetCarBrand";

export const SelectCarBrand = ({ answerKey }: { answerKey: string }) => {
  const isloadingCarBrandOptions = useAtomValue(carBrandOptionsAtom);

  return (
    <ProviderGetCarBrand>
      {isloadingCarBrandOptions.isLoading ? (
        <Skeleton className="mx-auto h-[40px] w-full rounded-lg bg-gray-300 md:w-[400px]" />
      ) : isloadingCarBrandOptions.options?.length > 0 ? (
        <SelectQuiz
          options={isloadingCarBrandOptions.options}
          answerKey={answerKey}
          placeholder="Select a brand"
        />
      ) : null}
    </ProviderGetCarBrand>
  );
};
