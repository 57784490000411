import { ReactNode, useEffect } from "react";
import { carBrandOptionsAtom, currentQuizErrorAtom } from "../atoms/quiz";
import { useAtom } from "jotai";
import { RootState } from "@/store/store";
import { useSelector } from "react-redux";
import { getEvs } from "../services/evs";

export const ProviderGetCarBrand = ({ children }: { children: ReactNode }) => {
  const token = useSelector((state: RootState) => state.AUTH.token);
  const [_, setError] = useAtom(currentQuizErrorAtom);
  const [isloadingCarBrandOptions, setLoadCarBrandOptions] =
    useAtom(carBrandOptionsAtom);

  useEffect(() => {
    if (isloadingCarBrandOptions?.options.length > 0) return;
    (async () => {
      setLoadCarBrandOptions((prev) => ({ ...prev, isLoading: true }));
      const evs = await getEvs(token);
      if (evs.status === 400) {
        setLoadCarBrandOptions((prev) => ({ ...prev, isLoading: false }));
        return setError({
          error: true,
          message: "Failed to fetch data",
        });
      }

      const uniqueBrandsSet = new Set(
        evs.data.map((item: any) => item.carBrand),
      ) as Set<string>;

      const sortedUniqueBrandsArray = [...uniqueBrandsSet].sort((a, b) =>
        a.localeCompare(b),
      );

      const uniqueBrandsArray = [...sortedUniqueBrandsArray].map(
        (brand, index) => ({
          answerId: index + 1,
          amount: brand,
        }),
      );

      setLoadCarBrandOptions({
        options: [
          { answerId: 0, amount: "No preference" },
          ...uniqueBrandsArray,
        ],
        isLoading: false,
      });
    })();
  }, []);
  return <>{children}</>;
};
