import { navList } from "@/statics/header";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Button } from "./ui/button";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { useOutSideClick } from "@/hooks/useOutsideClick";
import { RootState } from "@/store/store";
import { ChevronArrowDown } from "./svgs/ChevronArrowDown";
import { Profile } from "./svgs/Profile";

type Props = {
  handleLogout: () => void;
  closeSheet: () => void;
};

const linkHoverStyle =
  "border-b-transparent transition-color duration-300 border-b hover:border-b-black";

export const NavMenuSp = ({ handleLogout, closeSheet }: Props) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.AUTH.isAuthenticated,
  );
  const popupRef = useRef<HTMLLIElement>(null);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const location = useLocation();

  // Close popup by clicking outside of popup
  useOutSideClick(popupRef, () => setIsOpenPopup(false));
  return (
    <>
      <div
        className="fixed right-0 top-0 z-0 h-screen w-screen bg-black/40"
        onClick={closeSheet}
      />
      <div className="fixed left-0 top-0 z-10 flex h-screen w-[300px] flex-col items-center bg-white px-4 pt-20">
        <Link to="/" className="mx-auto block w-fit">
          <img src="/logo.svg" className="w-[200px]" alt="GreenCarLane Logo" />
        </Link>
        <ul className="flex w-full flex-col gap-8 pt-16">
          {navList.map((item) => {
            const listStyle = "mx-2.5 text-base";
            if (item.href) {
              return (
                <li className={listStyle} key={item.text}>
                  <a
                    href={item.href}
                    target="__blank"
                    rel="noopener noreferrer"
                    className={[linkHoverStyle].join(" ")}
                  >
                    {item.text}
                  </a>
                </li>
              );
            } else if (item.link) {
              return (
                <li className={listStyle} key={item.text}>
                  <NavLink
                    to={item.link}
                    className={[linkHoverStyle].join(" ")}
                  >
                    {item.text}
                  </NavLink>
                </li>
              );
            }
          })}
          <li ref={popupRef}>
            <div
              role="button"
              onClick={() => setIsOpenPopup((prev) => !prev)}
              className={[
                "mx-2.5 flex w-fit cursor-pointer items-center gap-1.5 pb-1 pr-1 text-base transition-opacity duration-300 hover:opacity-70",
              ].join(" ")}
            >
              <Profile className="mr-1 w-[15px]" />
              Profile
              <ChevronArrowDown
                className={`-translate-x-0.5 text-[15px] transition-transform duration-300 ${
                  isOpenPopup ? "rotate-180" : ""
                }`}
              />
            </div>
            {isOpenPopup && (
              <div className="ml-2 mt-2 flex w-48 flex-col">
                <ul className="*:cursor-pointer *:transition-colors *:duration-300">
                  {isAuthenticated ? (
                    <>
                      <li>
                        <button
                          disabled
                          className="block h-full w-full px-8 py-3.5"
                        >
                          <p className={[linkHoverStyle, "w-fit"].join(" ")}>
                            My Profile
                          </p>
                        </button>
                      </li>
                      <li>
                        <button
                          disabled
                          className="block h-full w-full px-8 py-3.5"
                        >
                          <p className={[linkHoverStyle, "w-fit"].join(" ")}>
                            History
                          </p>
                        </button>
                      </li>
                      <li>
                        <button
                          disabled
                          className="block h-full w-full px-8 py-3.5"
                        >
                          <p className={[linkHoverStyle, "w-fit"].join(" ")}>
                            Profile Settings
                          </p>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={handleLogout}
                          className="h-full w-full px-8 py-3.5 text-start"
                        >
                          <p className={[linkHoverStyle, "w-fit"].join(" ")}>
                            Log out
                          </p>
                        </button>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link
                          to="/login"
                          className="block h-full w-full px-8 py-3.5"
                        >
                          <p className={[linkHoverStyle, "w-fit"].join(" ")}>
                            Log in
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/signup"
                          className="block h-full w-full px-8 py-3.5"
                        >
                          <p className={[linkHoverStyle, "w-fit"].join(" ")}>
                            Sign up
                          </p>
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            )}
          </li>
        </ul>
        {location.pathname !== "/quiz" && (
          <Button asChild variant="primary" size="md" className="mt-10 w-full">
            <Link to="/quiz-start">Find my EV match</Link>
          </Button>
        )}
      </div>
    </>
  );
};
