import * as React from "react";
import * as ProgressPrimitive from "@radix-ui/react-progress";

import { cn } from "@/lib/utils";

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn("relative h-4 w-full rounded-full bg-gray-200", className)}
    {...props}
  >
    <div className="relative h-full" style={{ width: `${value || 0}%` }}>
      {value > 0 && (
        <span className="absolute -right-2 -top-8 font-quicksand font-[400] text-darkGreen md:text-xl">
          {value}%
        </span>
      )}
      <ProgressPrimitive.Indicator className="h-full w-full flex-1 rounded-full bg-darkGreen transition-all" />
    </div>
  </ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
