interface PotraitProps {
  image: string;
  alt: string;
}

const Potrait = ({ image, alt }: PotraitProps) => {
  return (
    <div className="relative flex h-[142px] w-[142px] items-end justify-end lg:h-[250px] lg:w-[250px]">
      <div className="absolute left-0 z-0 h-[90px] w-[90px] rounded-br-[80px] rounded-tl-[80px] rounded-tr-[80px] bg-gradient-to-br from-[#8bc63f] via-[#3cb64a] to-[#024034] lg:h-[160px] lg:w-[160px]"></div>
      <img
        src={image}
        alt={alt}
        className="z-1 absolute aspect-auto h-full rounded-br-[80px] rounded-tl-[80px] rounded-tr-[80px] object-cover"
      />
    </div>
  );
};

export default Potrait;
