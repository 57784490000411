import { useAtom, useSetAtom } from "jotai";
import {
  currentSelectedOptionAtom,
  residenceCountryCurrencyAtom,
} from "../atoms/quiz";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { COUNTRY_LIST } from "../statics/country-list";

export const SelectCountry = ({ answerKey }: { answerKey: string }) => {
  const [selectedItem, setSelectedItem] = useAtom(currentSelectedOptionAtom);
  const setResidenceCountryCurrencyCode = useSetAtom(
    residenceCountryCurrencyAtom,
  );

  const countryOptions = COUNTRY_LIST.map((country, index) => ({
    index: index + 1,
    name: country.country,
    value: country.code,
  }));

  const handleSelect = (v: string) => {
    const selectedCountry = countryOptions.find((i) => i.name === v);
    const formedOption = {
      [answerKey]: {
        countryName: selectedCountry.name,
        countryCode: selectedCountry.value,
      },
    };
    setResidenceCountryCurrencyCode(selectedCountry.value);
    setSelectedItem(formedOption);
  };

  return (
    <Select
      onValueChange={handleSelect}
      value={selectedItem?.[answerKey]?.countryName}
      defaultValue={
        (selectedItem && selectedItem?.[answerKey]?.countryName) ?? ""
      }
    >
      <SelectTrigger className="mx-auto w-full max-w-[360px]">
        <SelectValue placeholder="Select a country" />
      </SelectTrigger>
      <SelectContent>
        {countryOptions.map((i) => (
          <SelectItem value={i.name} key={i.name}>
            {i.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
