import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getFirstSentence(text: string) {
  const match = /<p>(.*?)<\/p>/g.exec(text);
  return match ? match[0].split(".")[0] : "";
}

export function multiLineText(text: string) {
  return (
    <>
      {text.split("\n").map((i, key) => {
        return <p key={key}>{i}</p>;
      })}
    </>
  );
}
