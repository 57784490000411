import { Facebook } from "@/components/svgs/Facebook";
import { Linkedin } from "@/components/svgs/Linkedin";
import { X } from "@/components/svgs/X";
import { Footer } from "@/types/footer";

export const footer: Array<Footer> = [
  {
    col: "site",
    links: [
      { slug: "/about", text: "About" },
      { slug: "/contact", text: "Contact" },
      // { slug: null, text: 'Our Partner Network' },
      // { slug: null, text: 'Become a partner' },
      // { slug: null, text: 'Careers' },
    ],
  },
  {
    col: "legal",
    links: [
      // { slug: "/contact", text: "Contact" },
      { slug: "/terms", text: "Terms" },
      { slug: "/privacy-policy", text: "Privacy Policy" },
      { slug: "/acceptable-use", text: "Acceptable use policy" },
      { slug: "/cookie-policy", text: "Cookie Policy" },
      // { slug: null, text: 'Cookie Settings' },
    ],
  },
  {
    col: "social",
    links: [
      {
        href: "https://www.linkedin.com/company/greencarlane",
        text: "LinkedIn",
        icon: <Linkedin color="white" />,
      },
      {
        href: "https://www.facebook.com/greencarlane",
        text: "Facebook",
        icon: <Facebook color="white" />,
      },
      {
        href: "https://www.x.com/greencarlane",
        text: "X",
        icon: <X color="white" />,
      },
    ],
  },
];
