import { Link } from "react-router-dom";
import { footer as footerInfo } from "@/statics/footer";
import useMediaQuery from "@/hooks/useMediaquery";
import { Footer as FooterType } from "@/types/footer";

const order = {
  sp: ["site", "social", "legal"],
  pc: ["site", "legal", "social"],
};

export const Footer = () => {
  const date = new Date();
  const media = useMediaQuery("(min-width: 769px)");

  const orderedFooterInfo = footerInfo.reduce(
    (acc, cur) => {
      if (cur.links.length < 0) return acc;
      const result = media
        ? { ...cur, index: order.pc.indexOf(cur.col) }
        : { ...cur, index: order.sp.indexOf(cur.col) };
      return [...acc, result];
    },
    [] as Array<FooterType & { index: number }>,
  );

  return (
    <div className="h-fit bg-darkGreen px-4 pb-8 pt-16 font-poppins text-white lg:px-[200px]">
      <div className="container">
        <Link to="/">
          <img
            src="/logo.svg"
            className="mb-8 w-[200px] md:hidden"
            alt="GreenCarLane Logo"
          />
        </Link>
        <div className="text-base md:flex md:justify-between">
          {orderedFooterInfo
            .sort((a, b) => a.index - b.index)
            .map((list, index) => {
              const isLast = index === orderedFooterInfo.length - 1;
              return (
                <div
                  className={[
                    "flex flex-col gap-4",
                    isLast ? "mb-12" : "mb-8",
                  ].join(" ")}
                  key={index}
                >
                  {list.links.map((item, index) => {
                    if (item.slug)
                      return (
                        <Link
                          to={item.slug}
                          key={index}
                          className="flex items-center gap-3"
                        >
                          {item.icon}
                          <span>{item.text}</span>
                        </Link>
                      );
                    else {
                      return (
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={index}
                          className="flex items-center gap-3"
                        >
                          {item.icon}
                          <span>{item.text}</span>
                        </a>
                      );
                    }
                  })}
                </div>
              );
            })}
        </div>
        <div className="md:flex md:justify-between">
          <Link to="/">
            <img
              src="/logo.svg"
              className="hidden w-[220px] md:block"
              alt="GreenCarLane Logo"
            />
          </Link>

          <div className="text-sm md:text-base">
            <blockquote>©{date.getFullYear()} GreenCarLane</blockquote>
          </div>
        </div>
      </div>
    </div>
  );
};
