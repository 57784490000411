import { SetStateAction, useAtomValue } from "jotai";
import { FC, ReactNode } from "react";
import { currentSelectedOptionAtom } from "../atoms/quiz";
import { darkGreen } from "@/styles/colors";
import { InfoIcon } from "../svgs/InfoIcon";

type SetAtom<Args extends unknown[], Result> = <A extends Args>(
  ...args: A
) => Result;

type Props = {
  children: ReactNode;
  id: number;
  svgEl: FC;
  carType: string;
  answerKey: string;
  onOpen: SetAtom<[SetStateAction<string>], void>;
};

export const InnerButtonElCarType = ({
  children,
  id,
  svgEl,
  carType,
  answerKey,
  onOpen,
}: Props) => {
  const currentSelectedValue = useAtomValue(currentSelectedOptionAtom);
  const style = [
    currentSelectedValue[answerKey] === carType ? "brightness-0 invert" : "",
    "w-[60px] transition-all  md:w-[90px]",
  ].join(" ");

  return (
    <>
      <div className="relative flex h-full w-full flex-col items-center justify-center gap-2 py-1">
        {id !== 11 && (
          <div
            role="button"
            onClick={() => onOpen(carType)}
            className="group/inner absolute -right-4 -top-4 hidden h-fit w-fit p-5 md:block"
          >
            <InfoIcon
              color={darkGreen}
              className={[
                "-p-1 h-fit w-6 rounded-full transition-all group-hover/inner:bg-darkGreen/30 group-hover/inner:brightness-50",
                currentSelectedValue[answerKey] === carType
                  ? "brightness-200 group-hover/inner:brightness-150"
                  : "",
              ].join(" ")}
            />
          </div>
        )}
        {svgEl &&
          svgEl({
            className: style,
            color: darkGreen,
          })}
        <span
          className={[
            "font-quicksand text-sm font-thin transition-all md:text-base",
            currentSelectedValue[answerKey] === carType
              ? "text-white"
              : "text-darkGreen",
          ].join(" ")}
        >
          {children}
        </span>
      </div>
    </>
  );
};
