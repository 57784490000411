import { useAtomValue } from "jotai";
import { currentSelectedOptionAtom } from "../atoms/quiz";
import { TrunkIcon } from "../svgs/TrunkIcon";
import { darkGreen } from "@/styles/colors";
import { ButtonText } from "./ButtonText";

const buttonData = [
  {
    answerId: 0,
    amount: "X2",
  },
  {
    answerId: 1,
    amount: "X4",
  },
  {
    answerId: 2,
    amount: "X6",
  },
  {
    answerId: 3,
    amount: "X8",
  },
];

export const GroupButtonStorage = ({ answerKey }: { answerKey: string }) => {
  const currentSelectedValue = useAtomValue(currentSelectedOptionAtom);

  const InnerButtonEl = ({ size, id }: { size: string; id: number }) => (
    <div className="group flex h-full w-full flex-col items-center gap-2">
      <TrunkIcon
        color={darkGreen}
        className={[
          "w-[40px] transition-all md:w-[46px]",
          currentSelectedValue[answerKey] === size ? "brightness-0 invert" : "",
        ].join(" ")}
      />
      <span
        className={[
          "font-quicksand text-base font-thin transition-all md:text-xl",
          currentSelectedValue[answerKey] === size
            ? "text-white"
            : "text-darkGreen",
        ].join(" ")}
      >
        {size}
      </span>
    </div>
  );

  return (
    <div className="grid grid-cols-2 gap-1 md:grid-cols-4">
      {buttonData.map((i) => (
        <ButtonText
          key={i.amount}
          value={i.amount}
          answerKey={answerKey}
          // className={"border-2 border-darkGreen"}
        >
          {InnerButtonEl({ size: i.amount, id: i.answerId })}
        </ButtonText>
      ))}
    </div>
  );
};
