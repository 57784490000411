import { useAtom } from "jotai";
import { ReactNode, useEffect, useState } from "react";
import { userCountryAtom } from "../atoms/quiz";

// detect user country by detecting ip address

export const ProviderDetectUserCountry = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [ipAddress, setIpAddress] = useState("");
  const [userCountry, setUserCountry] = useAtom(userCountryAtom);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch("https://api.ipify.org?format=json");
        const data = await res.json();
        if (!data || !res.ok) return;

        setIpAddress(data.ip);
      } catch {
        (error: any) => {
          console.log("Error:", error);
        };
      }
    })();
  }, []);

  useEffect(() => {
    if (!ipAddress || userCountry !== "") return;
    (async () => {
      try {
        const res = await fetch("http://ip-api.com/json/" + ipAddress);
        const data = await res.json();
        if (!data || !res.ok || !data.country) return;

        setUserCountry(data.countryCode);
      } catch {
        (error: any) => {
          console.log("Error:", error);
        };
      }
    })();
  }, [ipAddress]);

  return <>{children}</>;
};
