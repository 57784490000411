import { TEAM_MEMBERS } from "@/statics/teamMembers";
import TeamCard from "./TeamCard";

export const OurTeam = () => {
  return (
    <section className="flex flex-col gap-8 px-6 py-12 lg:gap-14 lg:px-40 lg:py-20">
      <h3 className="text-left font-poppins text-lg font-semibold leading-[42px] tracking-tight text-darkGreen lg:text-left lg:text-[32px]">
        Our Team
      </h3>
      <div className="flex flex-wrap items-center justify-center gap-x-14 gap-y-5 md:gap-10 lg:justify-between">
        {TEAM_MEMBERS.map((member, index) => (
          <TeamCard key={index} member={member} />
        ))}
      </div>
    </section>
  );
};
