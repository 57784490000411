import { MutableRefObject, useEffect } from 'react';

export const useOutSideClick = (ref: MutableRefObject<HTMLElement>, onClick: () => void) => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) return;
      onClick();
    };

    document.addEventListener('click', (e) => handleOutsideClick(e));
    document.addEventListener('mousedown', (e) => handleOutsideClick(e));
    document.addEventListener('touchstart', (e) => handleOutsideClick(e));
    return () => {
      document.removeEventListener('click', (e) => handleOutsideClick(e));
      document.removeEventListener('mousedown', (e) => handleOutsideClick(e));
      document.removeEventListener('touchstart', (e) => handleOutsideClick(e));
    };
  }, []);
  return;
};
