import { ABOUT_US_MISSION } from "../../statics/aboutUs";
import { MissionCard } from "./MissionCard";

export const Mission = () => {
  return (
    <section className="flex flex-col gap-5 md:gap-0">
      {ABOUT_US_MISSION.map((mission, index) => (
        <MissionCard key={index} mission={mission} index={index} />
      ))}
    </section>
  );
};
