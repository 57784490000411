import { ButtonText } from "./ButtonText";
import { useAtom } from "jotai";
import { infoWindowIdAtom } from "../atoms/quiz";
import { CAR_LIST } from "../statics/car-list";
import { CarList } from "../types/car";
import { InnerButtonElCarType } from "./InnerButtonElCarType";

import { ModalInfo } from "./ModalInfo";
import {
  Dialog,
  DialogContent,
  DialogDescription,
} from "@/components/ui/dialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { darkGreen } from "@/styles/colors";
import { Fragment } from "react/jsx-runtime";
import { DialogTitle } from "@radix-ui/react-dialog";

export const GroupButtonCarType = ({ answerKey }: { answerKey: string }) => {
  const [isInfoOpen, setIsInfoOpen] = useAtom(infoWindowIdAtom);

  return (
    <div className="grid grid-cols-2 gap-1 md:grid-cols-3 lg:grid-cols-4">
      {CAR_LIST.map((i: CarList) => (
        <Fragment key={i.answerId}>
          <ButtonText
            value={i.type}
            answerKey={answerKey}
            // className={"border-2 border-darkGreen"}
          >
            <InnerButtonElCarType
              id={i.answerId}
              svgEl={i.svgEl}
              carType={i.type}
              answerKey={answerKey}
              onOpen={setIsInfoOpen}
            >
              {i.type}
            </InnerButtonElCarType>
          </ButtonText>
          {isInfoOpen === i.type && i.answerId !== 11 && (
            <ModalInfo
              closeFn={() => setIsInfoOpen("")}
              carType={i.type}
              src={i.image}
              desc={i.description}
              modalOpen={isInfoOpen === i.type}
            />
          )}
        </Fragment>
      ))}
      {isInfoOpen === "sp" && (
        <InfoSp open={isInfoOpen} onOpenChange={() => setIsInfoOpen("")} />
      )}
    </div>
  );
};

const InfoSp = ({ onOpenChange, open }) => {
  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <DialogContent className="max-h-[600px] overflow-y-scroll rounded-2xl">
        <DialogTitle className="hidden">Car type</DialogTitle>
        <DialogDescription asChild>
          <Accordion type="single" collapsible className="w-full pt-3">
            {CAR_LIST.map((i) => {
              if (i.answerId === 11) return null;
              return (
                <AccordionItem value={i.type} key={i.answerId} className="pt-4">
                  <AccordionTrigger>
                    <span className="flex gap-3">
                      {i.svgEl &&
                        i.svgEl({
                          className: "w-[60px]",
                          color: darkGreen,
                        })}
                      <span className="font-quicksand text-sm font-[400]">
                        {i.type}
                      </span>
                    </span>
                  </AccordionTrigger>
                  <AccordionContent>
                    <img
                      src={i.image}
                      className="mx-auto w-10/12"
                      alt="Car body style"
                    />
                    <div className="mb-7 mt-6 px-2">
                      <h6 className="mb-2 font-poppins font-[500]">{i.type}</h6>
                      <p className="font-quicksand text-sm font-[400] leading-[21px]">
                        {i.description}
                      </p>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};
