import { ButtonText } from "./ButtonText";
import { cn } from "@/lib/utils";

type Props = {
  buttonData: Array<{ answerId: number; amount: string }>;
  answerKey: string;
  className?: string;
  containerClassName?: string;
  isMultipleChoice?: boolean;
};

export const GroupButtonText = ({
  buttonData,
  answerKey,
  className,
  containerClassName = "",
  isMultipleChoice = false,
}: Props) => {
  return (
    <div className={cn("grid grid-cols-2 gap-3 md:gap-6", containerClassName)}>
      {buttonData.map((i) => (
        <ButtonText
          value={i.amount}
          answerKey={answerKey}
          className={className}
          key={i.answerId}
          isMultipleChoice={isMultipleChoice}
        >
          <p className="h-fit max-w-full whitespace-pre text-balance">
            {i.amount}
          </p>
        </ButtonText>
      ))}
    </div>
  );
};
