import Intro from "@/components/Intro";
import { ABOUT_US_HEADER } from "@/statics/aboutUs";
import { AboutUsBanner } from "@/components/AboutUs/AboutUsBanner";
import { Mission } from "@/components/AboutUs/Mission";
import { OurTeam } from "@/components/AboutUs/OurTeam";
import { WhyUs } from "@/components/AboutUs/WhyUs";

export const AboutPage = () => {
  return (
    <div className="mx-auto">
      <Intro intro={ABOUT_US_HEADER} />
      <Mission />
      <AboutUsBanner />
      <OurTeam />
      <WhyUs />
    </div>
  );
};
