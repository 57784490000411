import { Button } from "@/components/ui/button";
import { useAtom } from "jotai";
import React, { ReactNode } from "react";
import { currentQuizErrorAtom, currentSelectedOptionAtom } from "../atoms/quiz";
import { cn } from "@/lib/utils";

type Props = {
  children: ReactNode;
  value: string;
  props?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  className?: string;
  answerKey: string;
  isMultipleChoice?: boolean;
};

export const ButtonText = ({
  children,
  value,
  props,
  className,
  answerKey,
  isMultipleChoice = false,
}: Props) => {
  const [currentSelectedValue, setCurrentSelectedValue] = useAtom(
    currentSelectedOptionAtom,
  );
  const [_, setError] = useAtom(currentQuizErrorAtom);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const element = e.currentTarget as HTMLButtonElement;
    const value = element.value;

    setError({
      error: false,
      message: "",
    });

    if (isMultipleChoice) {
      return setCurrentSelectedValue((prev) => {
        if (prev[answerKey] && prev[answerKey]?.includes(value)) {
          const newArray = prev[answerKey].filter((i: string) => i !== value);
          return { [answerKey]: newArray };
        } else if (prev[answerKey] && prev[answerKey]?.length < 3) {
          return { [answerKey]: [...prev[answerKey], value] };
        } else if (!prev[answerKey]) {
          return { [answerKey]: [value] };
        } else {
          setError({
            error: true,
            message: "You have reached your limit.",
          });
          return prev;
        }
      });
    }

    setCurrentSelectedValue((prev) => {
      if (prev[answerKey] && prev[answerKey] === value) return {};
      return { [answerKey]: value };
    });
  };

  return (
    <Button
      variant="tertiary"
      size="lg"
      value={value}
      className={cn(
        "group w-full font-quicksand text-[15px] font-[400] hover:bg-darkGreen/10 hover:text-darkGreen md:text-lg",
        !isMultipleChoice && currentSelectedValue[answerKey] === value
          ? "bg-darkGreen text-white hover:bg-darkGreen hover:text-white"
          : "",
        isMultipleChoice &&
          currentSelectedValue[answerKey] &&
          currentSelectedValue[answerKey]?.includes(value)
          ? "bg-darkGreen text-white hover:bg-darkGreen hover:text-white"
          : "",
        className,
      )}
      {...props}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};
