import { multiLineText } from "@/lib/utils";
import { Button } from "../ui/button";
import { ABOUT_US_BANNER } from "@/statics/aboutUs";

export const AboutUsBanner = () => {
  const { title, subheading, image } = ABOUT_US_BANNER;
  return (
    <div
      className="bg-custom-bg-partners relative h-[700px] w-full md:h-[500px] lg:h-[700px]"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-60">
        <div className="flex h-full flex-col items-center justify-center gap-4 px-4 py-10 text-white md:px-12 lg:items-start lg:gap-6 lg:px-[160px] lg:py-32">
          <h3 className="text-left font-poppins text-lg font-semibold leading-7 lg:text-[34px] lg:font-bold">
            {title}
          </h3>
          <div className="flex flex-col gap-10 font-quicksand text-base font-bold leading-snug tracking-tight lg:text-[24px] lg:font-semibold lg:leading-10">
            {multiLineText(subheading)}
          </div>
          <div className="mt-6 flex w-full flex-col gap-6 md:flex-row md:content-start lg:gap-12">
            <Button
              size="lg"
              variant="primary"
              className="disabled cursor-not-allowed opacity-50"
              // onClick={() => window.open("https://greencarlane.com/", "_blank")} //TODO: ADD CORPORATE URL
            >
              GreenCarLane for Business
            </Button>
            <Button
              size="lg"
              variant="secondary"
              className="disabled cursor-not-allowed opacity-50"
              // onClick={() => window.open("https://greencarlane.com/", "_blank")} //TODO: ADD GOVERNMENT URL
            >
              GreenCarLane for Government
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
