import { ChevronArrowRight } from "@/components/svgs/ChevronArrowRight";
import { US_FINANCIAL_OPTION } from "../statics/financial-option";

const USFinanceOption = () => {
  return (
    <div className="mx-auto max-w-[1600px] rounded-3xl bg-lightGreen px-4 pb-4 pt-6 lg:px-12 lg:py-8">
      <ul className="flex flex-col gap-6 lg:flex-row lg:gap-16">
        {US_FINANCIAL_OPTION.map((i) => (
          <li
            key={i.detail}
            className="grid gap-2 text-[15px] text-black lg:gap-4"
          >
            <h6 className="font-poppins font-[500] lg:text-xl">{i.title}</h6>
            <p className="font-quicksand font-[400] lg:text-lg">{i.detail}</p>
          </li>
        ))}
      </ul>
      <button
        disabled
        className="mx-auto flex cursor-not-allowed items-center gap-2 py-5 font-poppins text-[15px] font-[400] opacity-60 transition-opacity hover:opacity-60 lg:ml-0 lg:mr-auto lg:pt-10 lg:text-lg"
      >
        <p className="border-b border-black">See Details </p>
        <ChevronArrowRight />
      </button>
    </div>
  );
};

export default USFinanceOption;
