import { SVGProps } from "@/features/quiz/types/svg";

export const Threads = ({ className, color }: SVGProps) => {
  return (
    <svg
      width="41"
      height="48"
      viewBox="0 0 41 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_402_2185)">
        <path
          d="M31.9039 22.247C31.6975 22.1474 31.4892 22.0521 31.2789 21.9612C30.9111 15.1345 27.2076 11.2262 20.989 11.1863C17.401 11.1622 14.1893 12.6358 12.1876 15.6951L15.6075 18.0582C17.0299 15.8846 19.2622 15.4212 20.9062 15.4212C20.9251 15.4212 20.9442 15.4212 20.9631 15.4214C23.0106 15.4345 24.5557 16.0341 25.5557 17.2035C26.2835 18.0548 26.7703 19.2313 27.0112 20.716C25.1959 20.4051 23.2325 20.3097 21.1336 20.4308C15.2213 20.7739 11.4203 24.247 11.6756 29.073C11.8051 31.521 13.0159 33.627 15.0848 35.0027C16.8341 36.1657 19.0869 36.7344 21.4284 36.6058C24.5205 36.435 26.9461 35.2467 28.6385 33.0739C29.9237 31.424 30.7366 29.2858 31.0955 26.5916C32.5691 27.4872 33.6613 28.666 34.2643 30.083C35.29 32.4917 35.3497 36.4501 32.1431 39.677C29.3337 42.504 25.9566 43.7269 20.8528 43.7647C15.1914 43.7225 10.9097 41.8938 8.12585 38.3292C5.51899 34.9915 4.17176 30.1704 4.12153 24C4.17176 17.8295 5.51899 13.0084 8.12585 9.67069C10.9097 6.10619 15.1913 4.27743 20.8527 4.23519C26.5552 4.2779 30.9116 6.11538 33.802 9.69706C35.2193 11.4534 36.2878 13.6622 36.9922 16.2376L41 15.1605C40.1462 11.9906 38.8027 9.25886 36.9743 6.99358C33.2689 2.40174 27.8495 0.0487106 20.8667 0H20.8388C13.8702 0.0486167 8.51153 2.41047 4.91153 7.01977C1.70812 11.1216 0.0557229 16.8289 0.000186364 23.9831L0 24L0.000186364 24.0168C0.0557229 31.171 1.70812 36.8784 4.91153 40.9802C8.51153 45.5894 13.8702 47.9515 20.8388 48H20.8667C27.0621 47.9566 31.4291 46.323 35.0267 42.7027C39.7334 37.9665 39.5917 32.0298 38.0404 28.3853C36.9274 25.7717 34.8054 23.649 31.9039 22.247ZM21.207 32.3768C18.6157 32.5237 15.9236 31.3522 15.7909 28.8429C15.6925 26.9825 17.1055 24.9065 21.366 24.6592C21.854 24.6308 22.3328 24.617 22.803 24.617C24.3507 24.617 25.7985 24.7683 27.1148 25.0583C26.6239 31.2337 23.7441 32.2365 21.207 32.3768Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_402_2185">
          <rect width="41" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
