import { SVGProps } from "@/features/quiz/types/svg";

export const ShareLink = ({ className, color }: SVGProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7752 1.75068C12.9069 0.657729 14.4225 0.0529626 15.9957 0.0666331C17.5688 0.0803036 19.0737 0.711317 20.1862 1.82377C21.2986 2.93621 21.9296 4.44109 21.9433 6.01427C21.957 7.58745 21.3522 9.10306 20.2592 10.2347L20.2471 10.2471L17.2472 13.2469C16.6389 13.8554 15.9069 14.326 15.1008 14.6267C14.2947 14.9274 13.4333 15.0512 12.5752 14.9897C11.717 14.9283 10.8821 14.683 10.1271 14.2705C9.37202 13.858 8.71453 13.2879 8.19919 12.5989C7.86838 12.1567 7.95873 11.53 8.40098 11.1992C8.84323 10.8684 9.46991 10.9587 9.80072 11.401C10.1443 11.8603 10.5826 12.2403 11.086 12.5153C11.5893 12.7903 12.1459 12.9539 12.718 12.9949C13.2902 13.0358 13.8644 12.9533 14.4018 12.7528C14.9392 12.5523 15.4272 12.2386 15.8327 11.833L18.8264 8.83934C19.5514 8.08557 19.9525 7.0777 19.9434 6.03165C19.9342 4.98286 19.5136 3.97961 18.7719 3.23798C18.0303 2.49635 17.0271 2.07567 15.9783 2.06656C14.9318 2.05746 13.9235 2.45887 13.1696 3.1845L11.455 4.88913C11.0633 5.27851 10.4302 5.27667 10.0408 4.88501C9.65141 4.49335 9.65325 3.86018 10.0449 3.4708L11.7649 1.7608L11.7752 1.75068Z"
        fill="#25282B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89911 7.37325C7.70522 7.07255 8.56657 6.94873 9.42474 7.01019C10.2829 7.07165 11.1178 7.31695 11.8729 7.72946C12.6279 8.14197 13.2854 8.71203 13.8007 9.40099C14.1315 9.84324 14.0412 10.4699 13.5989 10.8007C13.1567 11.1315 12.53 11.0412 12.1992 10.5989C11.8556 10.1396 11.4173 9.7596 10.9139 9.48459C10.4106 9.20959 9.85398 9.04605 9.28187 9.00508C8.70976 8.9641 8.13552 9.04665 7.59811 9.24712C7.06071 9.44759 6.5727 9.76129 6.16719 10.1669L3.17355 13.1606C2.44851 13.9144 2.04746 14.9222 2.05655 15.9683C2.06566 17.0171 2.48634 18.0203 3.22797 18.762C3.9696 19.5036 4.97285 19.9243 6.02164 19.9334C7.06768 19.9425 8.07555 19.5414 8.82932 18.8164L10.5328 17.1129C10.9234 16.7223 11.5565 16.7223 11.9471 17.1129C12.3376 17.5034 12.3376 18.1365 11.9471 18.5271L10.2371 20.2371L10.2247 20.2493C9.09305 21.3422 7.57744 21.947 6.00426 21.9333C4.43108 21.9196 2.9262 21.2886 1.81376 20.1762C0.701307 19.0637 0.0702938 17.5588 0.0566233 15.9857C0.0429528 14.4125 0.647719 12.8969 1.74067 11.7653L1.75285 11.7529L4.75272 8.75299C5.36096 8.14457 6.09305 7.67393 6.89911 7.37325Z"
        fill="#25282B"
      />
    </svg>
  );
};
