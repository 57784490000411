import { Input } from "@/components/ui/input";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import {
  currentQuizErrorAtom,
  currentSelectedOptionAtom,
  residenceCountryCurrencyAtom,
} from "../atoms/quiz";
import { formatNumberWithCurrency } from "../helpers";
import { ChangeEvent } from "react";

export const SelectPrice = ({ answerKey }: { answerKey: string }) => {
  const setError = useSetAtom(currentQuizErrorAtom);
  const [currentAnswer, setCurrentAnswer] = useAtom(currentSelectedOptionAtom);
  const residenceCountryCurrency = useAtomValue(residenceCountryCurrencyAtom);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCurrentAnswer((prevData) => ({
      [answerKey]: {
        ...prevData[answerKey],
        [name]: value,
      },
    }));
  };

  const handleInputFocus = () => {
    setError({ error: false, message: "" });
  };

  const formattedMinAmount = formatNumberWithCurrency(
    currentAnswer[answerKey]?.minAmount ?? "",
    residenceCountryCurrency,
  );

  const formattedMaxAmount = formatNumberWithCurrency(
    currentAnswer[answerKey]?.maxAmount ?? "",
    residenceCountryCurrency,
  );

  return (
    <div className="flex flex-col gap-2 md:mx-auto md:flex-row md:justify-center lg:gap-8">
      <div className="flex flex-col gap-1 md:w-full md:max-w-[350px]">
        <Input
          name="minAmount"
          placeholder="Minimum amount"
          type="number"
          min="0"
          max="5000000"
          step="10000"
          value={currentAnswer[answerKey]?.minAmount ?? ""}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          required
        />
        <span className="ml-4 mt-1 text-xs md:mt-2 md:text-sm">
          {formattedMinAmount}
        </span>
      </div>

      <img
        src="/icons/divider.svg"
        className="mx-auto my-auto w-8 md:mx-0 md:mt-4"
        alt="Divider"
      />

      <div className="mt-4 flex flex-col gap-1 md:mt-0 md:w-full md:max-w-[350px]">
        <Input
          name="maxAmount"
          placeholder="Maximum amount"
          type="number"
          min="0"
          max="5000000"
          step="10000"
          value={currentAnswer[answerKey]?.maxAmount ?? ""}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          required
        />
        <span className="ml-4 mt-1 text-xs md:mt-2 md:text-sm">
          {formattedMaxAmount}
        </span>
      </div>
    </div>
  );
};
