export const formatNumberWithCurrency = (amount, countryCode: string) => {
  const currencyCode = getCurrencyCode(countryCode);
  const formatter = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: currencyCode,
  });

  return formatter.format(amount);
};

export const getCurrencyCode = (currencyCode: string) => {
  const countryCurrencyArray = [
    "EUR",
    "GBP",
    "DKK",
    "SEK",
    "NOK",
    "AUD",
    "USD",
    // "CAD", // uncomment when we have the Canadian dollar currency on the recommendation engine
    // Add more countries and their corresponding currency codes as needed
  ];
  //this is just temporary, while we don't have the Canadian dollar currency on the recommendation engine, return in USD
  if (currencyCode === "CAD") return "USD";
  //
  return countryCurrencyArray.includes(currencyCode) ? currencyCode : "EUR"; // Default to EUR if not found
};

export const formateToShortDate = (date: string) => {
  const shortDate = new Date(
    date.split(" ").slice(1).join(" "),
  ).toLocaleDateString("en-US", {
    month: "short",
    year: "numeric",
  });

  return shortDate;
};

const checkIfTimeSingleDigit = (time: string) => {
  return parseInt(time) < 10 ? `0${time}` : time;
};

export const formatToShortTime = (time: string) => {
  let min = "0";
  let hour = "0";

  if (time.includes("min")) {
    min = time.split(" ")[0];
  } else if (time.includes("hours")) {
    hour = time.split(" ")[0];
  } else {
    const [h, m] = time.split("h");
    hour = h;
    min = m.replace("m", "");
  }
  return `${checkIfTimeSingleDigit(hour)}:${checkIfTimeSingleDigit(min)} (H:m)`;
};
