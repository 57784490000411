import { SVGProps } from "@/features/quiz/types/svg";

export const Facebook = ({ className, color }: SVGProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20 10.0625C20 4.53965 15.5229 0.0625 10 0.0625C4.47715 0.0625 0 4.53965 0 10.0625C0 15.0537 3.65684 19.1908 8.4375 19.941V12.9531H5.89844V10.0625H8.4375V7.85938C8.4375 5.35313 9.93047 3.96875 12.2146 3.96875C13.3084 3.96875 14.4531 4.16406 14.4531 4.16406V6.625H13.1922C11.95 6.625 11.5625 7.3959 11.5625 8.1875V10.0625H14.3359L13.8926 12.9531H11.5625V19.941C16.3432 19.1908 20 15.0537 20 10.0625Z"
        fill={color}
      />
    </svg>
  );
};
