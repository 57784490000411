

import { createSlice } from '@reduxjs/toolkit';

const  useFetchSlice = createSlice({
  name: 'DATA',
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  reducers: {
    useFetchRequest: (state) => {
      state.loading = true;
    },
    useFetchSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    useFetchFailure: (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});

export const {  useFetchRequest,  useFetchSuccess,  useFetchFailure } =  useFetchSlice.actions;

export default  useFetchSlice.reducer;