import { apiUrl } from "@/statics/api";

export const resetPassword = async (token: string, password: string) => {
  try {
    const response = await fetch(`${apiUrl}/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        newPassword: password,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      return { status: 200 };
    } else {
      return {
        status: 400,
        data: data.message || "Failed to reset password. Please try again.",
      };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return {
      status: 400,
      data: "An error occurred while trying to reset your password. Please try again.",
    };
  }
};
