import { cn } from "@/lib/utils";
import { AnchorHTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "./svgs/ArrowRight";

const linkStyle =
  "flex w-fit items-center gap-3 font-poppins text-darkGreen transition-opacity hover:opacity-70";
const baseTextStyle =
  "border-b-[0.8px] border-b-darkGreen text-[15px] lg:text-base";
const iconStyle = "text-2xl font-thin text-darkGreen";

export const LinkWithArrow = ({
  href,
  children,
  textClassName,
  to,
  props,
}: {
  href?: string;
  to?: string;
  children: ReactNode;
  textClassName?: string;
  props?: AnchorHTMLAttributes<HTMLAnchorElement>;
}) => {
  if (to) {
    return (
      <Link
        to={to}
        className={linkStyle}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        <p className={cn(baseTextStyle, textClassName)}>{children}</p>
        <ArrowRight className={iconStyle} />
      </Link>
    );
  }

  return (
    <a
      href={href}
      className={linkStyle}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      <p className={cn(baseTextStyle, textClassName)}>{children}</p>
      <ArrowRight className={iconStyle} />
    </a>
  );
};
