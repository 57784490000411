import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { AuthForm } from "./AuthForm";
import { Input } from "@/components/ui/input";
import { resetPassword } from "../services/resetPassword";
import { useToast } from "@/components/ui/use-toast";

const initialLoginState = {
  password: "",
  confirmPassword: "",
};

const initialError = {
  password: false,
  confirmPassword: false,
  general: false,
};

export const ResetPassword = () => {
  const { toast } = useToast();
  const [error, setError] = useState(initialError);
  const navigate = useNavigate();
  const [resetInputs, setInputs] = useState(initialLoginState);
  const handleInputs = (
    field: keyof typeof initialLoginState,
    value: string,
  ) => {
    setInputs((prev) => ({ ...prev, [field]: value }));
  };

  const handleError = (
    field: keyof typeof initialError,
    value: string | boolean,
  ) => {
    setError((prev) => ({ ...prev, [field]: value }));
  };

  const clearErrorMessage = (field: keyof typeof initialError) => {
    setError((prev) => ({ ...prev, [field]: false }));
  };

  useEffect(() => {
    document.title = "Green Car Lane | Reset password ";
  }, []);

  const [searchParams] = useSearchParams();

  const handleSubmit = async (e: SubmitEvent) => {
    e.preventDefault();

    setError(initialError);
    const token = searchParams.get("token");

    if (!token) {
      handleError(
        "general",
        "Invalid or missing token. Please ensure you have followed a valid password reset link.",
      );
      return;
    }

    const res = await resetPassword(token, resetInputs.password);

    if (res.status !== 200) return handleError("general", res.data as string);

    // show toast
    toast({
      title: "Password Reset",
      description: "Your password successfully updated",
    });
    return navigate("/login");
  };

  return (
    <AuthForm submitHandler={handleSubmit} title="Reset Password">
      <div>
        <Input
          className={`${error.password ? "border border-red-300 bg-red-100" : ""}`}
          type="password"
          id="password"
          value={resetInputs.password}
          onChange={(e) => handleInputs("password", e.target.value)}
          onFocus={() => clearErrorMessage("password")}
          placeholder="Password"
        />
        {error.password && (
          <p className="text-start text-xs text-red-500 md:text-sm">
            The password is too short.
          </p>
        )}
      </div>
      <div>
        <Input
          className={`${error.confirmPassword ? "border border-red-300 bg-red-100" : ""}`}
          type="password"
          id="confirmPassword"
          value={resetInputs.confirmPassword}
          onChange={(e) => handleInputs("confirmPassword", e.target.value)}
          onFocus={() => clearErrorMessage("confirmPassword")}
          placeholder="Confirm Password"
        />
        {error.confirmPassword && (
          <p className="text-start text-xs text-red-500 md:text-sm">
            The password is too short.
          </p>
        )}
      </div>
    </AuthForm>
  );
};
