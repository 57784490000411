import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "@/components/ui/dialog";

type Props = {
  src: string;
  desc: string;
  carType: string;
  closeFn: () => void;
  modalOpen: boolean;
};

export const ModalInfo = ({
  src,
  desc,
  carType,
  closeFn,
  modalOpen,
}: Props) => {
  return (
    <Dialog onOpenChange={closeFn} open={modalOpen}>
      <DialogContent>
        <div className="flex flex-col items-center gap-6 bg-white px-8 pb-8 pt-6">
          <img src={src} />
          <div>
            <DialogTitle className="font-poppins text-lg font-[500]">
              {carType}
            </DialogTitle>
            <DialogDescription className="font-quicksand text-base font-[400]">
              {desc}
            </DialogDescription>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
