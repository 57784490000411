import { useEffect, useState } from "react";
import { RecommendationTopEvs } from "../components/RecommendationTopEvs";
import { RecommendationMoreSection } from "../components/RecommendationMoreSection";
import { useAtomValue } from "jotai";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { ModalCreateAccount } from "@/features/auth/components/ModalCreateAccount";
import { ProviderStoreResultToDB } from "../components/ProviderStoreResultToDB";
import { ProviderDetectUserCountry } from "../components/ProviderDetectUserCountry";
import { quizResultAtom } from "../atoms/quiz";
import USFinanceOption from "../components/USFinanceOption";

export default function QuizResult() {
  const quizResult = useAtomValue(quizResultAtom);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector((state: RootState) => state.AUTH.user);

  const answers = JSON.parse(
    localStorage.getItem("greenCarLaneQuiz_CurrentOption"),
  );

  const isAmerica =
    answers?.["residence_country"]?.countryName === "United States (USA)";

  useEffect(() => {
    if (!user) {
      setIsModalOpen(true);
    }
  }, []);

  return (
    <ProviderDetectUserCountry>
      <ProviderStoreResultToDB>
        <div className="no-print min-h-screen">
          <section className="h-fit min-h-screen w-screen bg-quiz px-5 py-8 md:px-16 md:py-16">
            {!user && isModalOpen && (
              <ModalCreateAccount
                handleCloseModal={() => setIsModalOpen((prev) => !prev)}
              />
            )}
            <RecommendationTopEvs />
          </section>

          <section className="flex h-fit w-screen flex-col gap-12 px-5 py-8 md:px-16 md:py-16">
            {/* AD BANNER TODO: This is a placement. should show only in the US */}
            {/* <AdBanner /> */}
            {/* Finance option section Only visible for users from the US */}
            {isAmerica && <USFinanceOption />}
            {quizResult && <RecommendationMoreSection />}
          </section>
        </div>
      </ProviderStoreResultToDB>
    </ProviderDetectUserCountry>
  );
}
