import useMediaQuery from "@/hooks/useMediaquery";
import { EV } from "@/types/ev";
import CarDetail from "./CarDetail";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { ImageWithLoading } from "@/components/ImageWithLoading";

interface CarCardProps {
  car: EV;
}

export const CarCard = ({ car }: CarCardProps) => {
  const isPc = useMediaQuery("(min-width: 1280px)");

  return (
    <section className="grid w-full overflow-hidden rounded-2xl shadow-xl xl:max-w-[400px]">
      <h2 className="mx-auto px-3 pb-3 pt-4 text-center font-poppins text-lg font-[600] md:pb-4 md:pt-6 md:text-2xl">
        <span className="text-mainGreen">{car.matching_score}% </span>match
      </h2>
      <div className="h-[250px]">
        <ImageWithLoading imagePath={car.picture} />
      </div>
      <h3 className="px-2 pt-4 text-center font-poppins text-lg font-[600] leading-10 text-black md:mb-8 md:px-6 md:pt-8 md:text-2xl">
        {car.fullName}
      </h3>
      {isPc ? (
        <CarDetail car={car} withShareBtn />
      ) : (
        <Accordion type="single" collapsible>
          <AccordionItem value={car._id}>
            {/* Hide upper show detail btn when it opens */}
            <AccordionTrigger className="gap-4 first:justify-center [&[data-state=open]>p]:hidden [&[data-state=open]>svg]:hidden">
              <p className="font-poppins text-base font-[400]">See details</p>
            </AccordionTrigger>
            <AccordionContent className="pt-0">
              <CarDetail car={car} withShareBtn />
              <AccordionTrigger className="flex flex-col justify-center" />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </section>
  );
};
