import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap ring-offset-background font-poppins transition-all duration-300 disabled:pointer-events-none disabled:opacity-50 outline-none focus:ring-2",
  {
    variants: {
      variant: {
        primary:
          "bg-lightGreen text-darkGreen hover:bg-[#B0E27E] ring-darkGreen",
        secondary: "bg-darkGreen text-white hover:bg-[#0B6366] ring-lightGreen",
        tertiary:
          "border-2 border-solid border-darkGreen bg-transparent text-darkGreen hover:bg-darkGreen hover:text-white ring-lightGreen focus:border-transparent",
        link: "text-darkGreen underline underline-offset-8 hover:font-semibold hover:decoration-[3px] focus:ring-0",
      },
      size: {
        sm: "px-4 py-[7.50px] gap-2 rounded-lg tex-sm font-medium",
        md: "px-5 py-[13px] gap-2.5 rounded-lg text-base font-medium leading-[18px] tracking-tight",
        lg: "px-6 py-[17px] gap-3 rounded-lg text-xl font-medium leading-[18px] tracking-tight",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Button.displayName = "Button";

export { Button, buttonVariants };
