import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Markdown from "react-markdown";
import privacyPolicyMD from "../statics/md/privacy-policy.md";
import acceptableUseMD from "../statics/md/acceptable-use-policy.md";
import cookiePolicyMD from "../statics/md/cookie-policy.md";
import termsAndPolicyMD from "../statics/md/terms-and-policy.md";
import "../styles/md.css";

const chooseMarkdown = (path: string) => {
  switch (path) {
    case "/privacy-policy":
      return privacyPolicyMD;
    case "/terms":
      return termsAndPolicyMD;
    case "/acceptable-use":
      return acceptableUseMD;
    case "/cookie-policy":
      return cookiePolicyMD;
    default:
      return "";
  }
};

export const MainPolicyPage = () => {
  const location = useLocation();
  const md = useMemo(
    () => chooseMarkdown(location.pathname),
    [location.pathname],
  );
  return (
    <div className="container">
      <section className="mx-auto max-w-[500px] px-4 py-6 md:max-w-[900px] md:px-8 md:py-16 md:pb-32">
        <Markdown className="markdown">{md}</Markdown>
      </section>
    </div>
  );
};
