import { CarList } from "../types/car";
import { Sedan } from "../svgs/Sedan";
import { SVGProps } from "../types/svg";
import { SUV } from "../svgs/SUV";
import { Cuv } from "../svgs/Cuv";
import { PickUp } from "../svgs/PickUp";
import { SuperCar } from "../svgs/SuperCar";
import { PasserngerVan } from "../svgs/PasserngerVan";
import { HatchBack } from "../svgs/HatchBack";
import { Van } from "../svgs/Van";
import { StationWagon } from "../svgs/StationWagon";
import { Carbliolet } from "../svgs/ Carbliolet";
import { Coupe } from "../svgs/Coupe";

export const CAR_LIST: CarList[] = [
  {
    answerId: 0,
    type: "Sedan",
    image: "/quiz/car_type/car9.svg",
    svgEl: ({ className, color }: SVGProps) => (
      <Sedan className={className} color={color} />
    ),
    description:
      "Sedans are the quintessential family-friendly vehicles, characterized by their enclosed passenger compartment, four doors, and separate compartments for cargo and passengers. They typically offer comfortable seating for four to five people, with ample legroom and a spacious trunk for luggage. Sedans come in various sizes, from compact to full-size, catering to diverse needs, whether it's commuting in urban areas or embarking on long road trips.",
  },
  {
    answerId: 1,
    type: "SUV",
    image: "/quiz/car_type/car.svg",
    svgEl: ({ className, color }: SVGProps) => (
      <SUV className={className} color={color} />
    ),
    description:
      "SUVs, or Sport Utility Vehicles, combine the ruggedness of a truck with the versatility of a family car. With their commanding presence on the road and spacious interiors, SUVs offer ample room for passengers and cargo alike. Characterized by their raised ground clearance and robust build, SUVs excel both on and off the beaten path, making them a popular choice for adventurers and urbanites alike. Whether navigating city streets or exploring the great outdoors, SUVs provide comfort, capability, and style for any journey.",
  },
  {
    answerId: 2,
    type: "CUV",
    image: "/quiz/car_type/car2.svg",
    svgEl: ({ className, color }: SVGProps) => (
      <Cuv className={className} color={color} />
    ),
    description:
      "A CUV, or Crossover Utility Vehicle, blends the versatility of an SUV with the comfort and agility of a sedan. Characterized by its elevated seating position, ample cargo space, and sleek design, the CUV offers a perfect balance of practicality and style. With its smooth handling and efficient use of space, it's the ideal choice for those seeking the versatility to tackle both urban streets and off-road adventures with ease.",
  },
  {
    answerId: 9,
    type: "Pick-up",
    image: "/quiz/car_type/car3.svg",
    svgEl: ({ className, color }: SVGProps) => (
      <PickUp className={className} color={color} />
    ),
    description:
      "A pickup truck is a versatile vehicle characterized by its open cargo bed at the rear, separated from the passenger compartment. It typically features a spacious cabin for passengers and a robust chassis capable of carrying heavy loads. Pickup trucks are renowned for their ruggedness, making them ideal for hauling cargo, towing trailers, and navigating diverse terrains with ease. With a blend of functionality and style, pickup trucks are a popular choice for both work and everyday use.",
  },
  {
    answerId: 3,
    type: "Supercar",
    image: "/quiz/car_type/car11.svg",
    svgEl: ({ className, color }: SVGProps) => (
      <SuperCar className={className} color={color} />
    ),
    description:
      "A supercar is the epitome of automotive excellence, embodying sheer power, precision engineering, and breathtaking design. With sleek aerodynamics, low ground clearance, and a menacing stance, it exudes an aura of speed and sophistication. Its high-performance engine roars with authority, propelling it to mind-bending speeds in the blink of an eye. Every curve and contour is meticulously crafted for both aesthetics and function, commanding attention on both the road and the racetrack.",
  },
  {
    answerId: 4,
    type: "Passenger van",
    image: "/quiz/car_type/car6.svg",
    svgEl: ({ className, color }: SVGProps) => (
      <PasserngerVan className={className} color={color} />
    ),
    description:
      "A passenger van is a versatile vehicle designed to transport multiple occupants comfortably. Characterized by a boxy silhouette and spacious interior, passenger vans typically feature multiple rows of seating, accommodating anywhere from seven to fifteen passengers. With sliding doors for easy access and ample cargo space, these vans are ideal for families, group outings, and commercial transportation needs. Efficiently blending functionality with comfort, passenger vans offer a practical solution for transporting people and belongings with ease.",
  },
  {
    answerId: 5,
    type: "Hatchback",
    image: "/quiz/car_type/car5.svg",
    svgEl: ({ className, color }: SVGProps) => (
      <HatchBack className={className} color={color} />
    ),
    description:
      "A hatchback is a compact car body style characterized by a rear door that swings upward to provide access to a cargo area, which is typically integrated with the passenger compartment. Unlike traditional sedans, hatchbacks have a shorter rear overhang and a more vertical rear end, maximizing interior space and versatility. They often offer folding rear seats to expand cargo capacity, making them practical for carrying various loads while maintaining a smaller footprint on the road.",
  },
  {
    answerId: 10,
    type: "Van",
    image: "/quiz/car_type/car4.svg",
    svgEl: ({ className, color }: SVGProps) => (
      <Van className={className} color={color} />
    ),
    description:
      "A van is a versatile vehicle characterized by its spacious, boxy design, offering ample cargo space and often seating for passengers. With a focus on functionality and utility, vans are commonly used for transporting goods, equipment, or people, making them ideal for businesses, families, and recreational activities alike. Their straightforward design provides ease of access and loading, while their diverse configurations cater to various needs, from delivery services to camper conversions. Whether for work or play, vans offer practicality and adaptability on the road.",
  },
  {
    answerId: 6,
    type: "Station wagon",
    image: "/quiz/car_type/car8.svg",
    svgEl: ({ className, color }: SVGProps) => (
      <StationWagon className={className} color={color} />
    ),
    description:
      "Station wagons, also known as estate cars or simply wagons, combine the practicality of a sedan with the versatility of an SUV. Characterized by a longer roofline and extended cargo space, station wagons offer ample room for passengers and luggage alike. Their sleek yet spacious design makes them ideal for families, adventurers, and anyone seeking a blend of comfort and utility on the road.",
  },
  {
    answerId: 7,
    type: "Cabriolet/Roadster",
    image: "/quiz/car_type/car12.svg",
    svgEl: ({ className, color }: SVGProps) => (
      <Carbliolet className={className} color={color} />
    ),
    description:
      "A Cabriolet or Roadster is a sleek and sporty convertible car body style, characterized by its open-top design, typically with two doors and seating for two or sometimes four occupants. These cars are designed to provide an exhilarating driving experience, allowing occupants to feel the wind in their hair as they cruise along scenic roads. With their low-slung profile and attention to aerodynamics, Cabriolets and Roadsters often exude a sense of elegance and performance, making them a favorite among driving enthusiasts who seek both style and thrill on the open road.",
  },
  {
    answerId: 8,
    type: "Coupe",
    image: "/quiz/car_type/car7.svg",
    svgEl: ({ className, color }: SVGProps) => (
      <Coupe className={className} color={color} />
    ),
    description:
      "A coupe is a sleek and stylish car body style characterized by its two doors, compact size, and a roofline that typically slopes down towards the rear. With a focus on performance and elegance, coupes often feature sporty designs, emphasizing a dynamic driving experience. These vehicles typically seat two to four passengers, offering a perfect blend of performance and luxury for those who prioritize style and agility on the road.",
  },
  {
    answerId: 11,
    type: "I don't know",
    image: null,
    description: null,
  },
];
