/*
 *
 * take result from quiz and convert values with answerKeyNumberInput to number
 * 
 */

export const formatQuizInput = (result: Record<string, any>) => {
  let newResult: Record<string, any>;
  Object.keys(result).map((i) => {
    if (i === "year_of_birth")
      return (newResult = {
        ...newResult,
        year_of_birth: Number(result["year_of_birth"]),
      });
    else if (i === "budget")
      return (newResult = {
        ...newResult,
        budget: {
          minAmount: Number(result["budget"]["minAmount"]),
          maxAmount: Number(result["budget"]["maxAmount"]),
        },
      });
    else return (newResult = { ...newResult, [i]: result[i] });
  });
  return newResult;
};
