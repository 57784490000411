export const textHome: Record<string, { title: string; description: string }> =
  {
    hero: {
      title: "Ready to discover which EV model fits you?",
      description:
        "GreenCarLane - the go-to digital platform about EVs and charging infrastructure.",
    },
    steps: {
      title: "You don't need tons of research",
      description:
        "Our platform takes the guesswork out of finding an EV match for you. We know that everyone's needs are unique, and that's why we've developed a personalized recommendation engine.",
    },
    aboutQuiz: {
      title: "No EV car yet?",
      description:
        "Answer the Quiz and the platform will do the work for you and rank the best car matches for you.",
    },
    aboutSurvey: {
      title: "Or maybe you already own one?",
      description:
        "If yes, we would like to hear from you! Fill out our survey to help us improve and connect people with their new EV.",
    },
    news: {
      title: "Keep yourself updated",
      description:
        "Stay up-to-date with the latest news and articles about EVs.",
    },
    aboutUs: {
      title: "We are GreenCarLane",
      description:
        "We help people to find electric vehicle that suits their needs best. We understand that finding an EV can be an overwhelming process. By getting to know you and your driving habits, our platform will offer you up to 3 electric vehicles that best match your profile. We take away your stress and save your time.",
    },
    contact: {
      title: "Newsletter",
      description:
        "Rest assured, we prioritize your privacy. You won't receive spam, and any of your personal information is safe with us.",
    },
  };

export const textSteps: Array<{ index: number; text: string }> = [
  { index: 1, text: "Start the Quiz" },
  {
    index: 2,
    text: "Answer a few questions about your driving habits, lifestyle and budget",
  },
  {
    index: 3,
    text: "Get 3 best EV matches and up to 9 recommendations at a time ",
  },
];

export const surveyLinks: Array<{ name: string; link: string }> = [
  {
    name: "English",
    link: "https://au4m8wkmsai.typeform.com/to/ZOnCL17A?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx",
  },
  {
    name: "Danish",
    link: "https://au4m8wkmsai.typeform.com/to/kj5taUs5?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx",
  },
  { name: "French", link: "https://au4m8wkmsai.typeform.com/to/RvklxnBY" },
  {
    name: "Norwegian",
    link: "https://au4m8wkmsai.typeform.com/to/lneRRJGs?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx",
  },
  { name: "Portuguese", link: "https://au4m8wkmsai.typeform.com/to/DMz8DXWm" },
  { name: "Spanish", link: "https://au4m8wkmsai.typeform.com/to/hZj33rqQ" },
];
