import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

type Props = {
  path?: string;
  locationTo?: string;
};

const initialState = { status: null, message: "" };

export const ErrorPage = ({ path = "/", locationTo = "Home page" }: Props) => {
  const error = useRouteError();

  const [message, setMessage] = useState(initialState);

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        return setMessage({ status: 404, message: "This page doesn't exist!" });
      }

      if (error.status === 401) {
        return setMessage({
          status: 404,
          message: "You aren't authorized to see this",
        });
      }

      if (error.status === 503) {
        return setMessage({
          status: 404,
          message: "Looks like our API is down",
        });
      }

      if (error.status === 418) {
        return setMessage({ status: 404, message: "🫖" });
      } else {
        return setMessage({ status: 404, message: "Something went wrong" });
      }
    } else {
      return setMessage(initialState);
    }
  }, [error]);

  return (
    <div className="min-w-screen grid min-h-screen grid-rows-[auto_1fr_auto] text-black">
      <Header />
      <div className="flex items-center justify-center">
        <div className="mx-auto flex w-fit max-w-[800px] flex-col items-center justify-center gap-8 py-6 md:pb-28 md:pt-20">
          <img
            src="/icons/not_found.svg"
            alt="not_found"
            className="w-[200px] md:w-[240px]"
          />
          <div className="text-center">
            <p className="py-4 text-3xl font-bold text-darkGreen md:text-[42px]">
              Oops!
            </p>
            <h3 className="py-2 text-2xl font-bold text-darkGreen md:text-3xl">
              {message.status}
            </h3>
            <p className="pt-6 text-lg md:text-xl">{message.message}</p>{" "}
          </div>
          <Button
            variant="secondary"
            size="lg"
            onClick={() => (window.location.href = path)}
            className="md:text-xl"
          >
            Go to {locationTo}
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};
