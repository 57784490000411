import { Button } from "@/components/ui/button";

export const ContactPage = () => {
  return (
    <div
      className="bg-contactBg flex min-h-[70vh] items-center justify-center"
      style={{
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), url(/images/contact_bg.webp) lightgray center  no-repeat",
      }}
    >
      <div className="flex flex-col items-center gap-4 md:gap-8">
        <h2 className="font-poppins text-3xl font-bold text-white md:text-[50px] lg:text-[60px]">
          More market visibility.
        </h2>
        <p className="xl font-poppins font-bold text-white md:text-3xl">
          For a green targeted audience.
        </p>
        <Button
          asChild
          variant="primary"
          size="lg"
          className="mt-8 px-20 text-lg md:text-xl"
        >
          <a href="mailto:contact@greencarlane.com">Contact Us</a>
        </Button>
      </div>
    </div>
  );
};
