import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "@/components/ui/button";
import { RootState } from "@/store/store";
import ReactMarkdown from "react-markdown";

export const QuizStart = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.AUTH.isAuthenticated,
  );

  const navigate = useNavigate();

  return (
    <div className="mx-auto flex h-fit max-w-[450px] flex-col items-center gap-6 rounded-[24px] bg-white px-4 pb-6 pt-8 text-gray26 lg:mx-6 lg:grid lg:max-w-full lg:grid-cols-[2fr_1fr] lg:pb-8 lg:pt-12 xl:gap-24">
      {/* LEFT SIDE */}
      <div className="mx-auto flex max-w-[560px] flex-col items-center gap-7 lg:items-start lg:gap-12">
        <h1 className="text-center font-poppins text-xl font-[600] leading-[115%] lg:text-[32px]">
          Find Your Ideal EV Match With Our Quiz
        </h1>
        <div className="grid gap-5 font-quicksand text-[15px] lg:text-base">
          <ReactMarkdown>
            Discover top **3** cars + **6** close fits, personalized for you .
          </ReactMarkdown>
          <div>
            <p className="mb-2 font-[600]">About the quiz:</p>
            <ul className="px-6 *:list-disc">
              <li>21 easy questions</li>
              <li>8-10 minutes</li>
            </ul>
          </div>
          <p>
            <Link
              to="/signup"
              className="underline underline-offset-2 hover:decoration-lightGreen"
            >
              Create an account
            </Link>{" "}
            to save your results for easy access later.
          </p>
        </div>
        <div className="flex w-full flex-col items-center gap-4 lg:flex-row lg:items-center">
          {!isAuthenticated && (
            <Button
              onClick={() => navigate("/signup")}
              className="w-[240px] flex-1"
              variant="primary"
              size="lg"
            >
              Login/Sign up free
            </Button>
          )}

          <Button
            onClick={() => navigate("/quiz")}
            className="w-[240px] flex-1"
            variant="secondary"
            size="lg"
          >
            Go to Quiz
          </Button>
        </div>
      </div>

      {/* RIGHT SIDE/ CAR IMAGE  */}
      <div className="h-[302px] w-full min-w-[320px] rounded-2xl bg-lightGray bg-[url('/quiz/quizStart-sp.webp')] bg-cover bg-center bg-no-repeat lg:h-[465px] lg:min-h-full lg:w-[347px]" />
    </div>
  );
};
