import { QuizStart } from "@/features/quiz/pages/QuizStart";
import { useEffect } from "react";

export const QuizStartPage = () => {
  useEffect(() => {
    document.title = "GreenCarLane - Your Top EV matches";
  }, []);

  return <QuizStart />;
};
