import { apiUrl } from "@/statics/api";

export const getEvs = async (token: string) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const res = await fetch(`${apiUrl}/evs`, {
      method: "GET",
      headers,
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("Request failed");
    }
    return { status: 200, data };
  } catch (err) {
    console.error(err);
    return { status: 400 };
  }
};
