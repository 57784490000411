import { RootState } from "@/store/store";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuizResult } from "../services/quiz";
import {
  currentQuizErrorAtom,
  initialErrorState,
  quizRecommendationsAtom,
  quizResultAtom,
  selectedQuizAnswerAtom,
} from "../atoms/quiz";
import { useAtom, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { formatQuizInput } from "../helpers/formatQuizInput";
import { resetQuizState } from "@/store/quizSlice";

export const ProviderGetResult = ({ children }: { children: ReactNode }) => {
  const token = useSelector((state: RootState) => state.AUTH.token);
  const [answers, setAnswers] = useAtom(selectedQuizAnswerAtom);
  const [error, setError] = useAtom(currentQuizErrorAtom);
  const setQuizResult = useSetAtom(quizResultAtom);
  const setRecommendations = useSetAtom(quizRecommendationsAtom);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const formatAnswer = formatQuizInput(answers.answer);
      const data = await getQuizResult(token, formatAnswer);

      if (data.status !== 200) {
        const timer = setTimeout(
          () => setError({ error: true, message: error.message }),
          1000,
        );
        return () => clearTimeout(timer);
      } else {
        const sortedResult = data.data.data?.result?.sort(
          (
            a,
            b, //TODO: define type
          ) => (a.matching_score < b.matching_score ? 1 : -1),
        );
        setRecommendations(data.data.data?.recommendations);
        setError(initialErrorState);

        setQuizResult(sortedResult);
        navigate("/quiz-result");
        dispatch(resetQuizState());
      }
    })();
  }, []);
  return <>{children}</>;
};
