import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { resetQuizState } from "@/store/quizSlice";
import { useDispatch } from "react-redux";
import { useAtom } from "jotai";
import {
  currentQuizErrorAtom,
  initialErrorState,
  initialSelectedQuizAnswerState,
  selectedQuizAnswerAtom,
} from "../atoms/quiz";

const NoRecommendationsCard = () => {
  const resetQuiz = () => {
    const [, setAnswers] = useAtom(selectedQuizAnswerAtom);
    const [, setError] = useAtom(currentQuizErrorAtom);
    const dispatch = useDispatch();

    setAnswers(initialSelectedQuizAnswerState);
    setError(initialErrorState);
    dispatch(resetQuizState());
  };
  return (
    <div className="flex w-full flex-col justify-between gap-6 rounded-[24px] bg-white px-8 pb-6 pt-8 text-center shadow-2xl md:mx-auto md:max-w-[600px] md:px-12 md:pb-8 md:pt-12">
      <h1>
        There are currently no recommendations based on the criteria you have
        selected. You can try again choosing a different option for the
        questions of <strong>body style, cargo space.</strong>
      </h1>
      <p>
        We constantly update our car collection to have options that cover your
        needs.
      </p>
      <Button variant="secondary" size="lg" asChild onClick={resetQuiz}>
        <Link to="/quiz-start">Go Back to the Quiz</Link>
      </Button>
    </div>
  );
};

export default NoRecommendationsCard;
