import { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { postsQuery } from "../apollo/query";
import validator from "validator";
import { surveyLinks, textHome, textSteps } from "@/statics/text-home";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { LinkWithArrow } from "@/components/LinkWithArrow";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { registerEmail } from "../services/registerEmail";

type MessageStatus = " " | "error" | "success" | "completed";

const textContainerStyle = "flex flex-col gap-4 text-center lg:text-start";
const titleBaseStyle =
  "text-lg md:text-2xl lg:text-3xl font-[600] font-poppins text-start";
const descriptionBaseStyle = "text-[15px] lg:text-lg font-thin font-quicksand";

const initialMessage: { message: string; error: MessageStatus } = {
  message: "",
  error: " ",
};

export const HomeMain = () => {
  const [message, setMessage] = useState<{
    message: string;
    error: MessageStatus;
  }>(initialMessage);
  const [mail, setMail] = useState("");
  const [termChecked, setTermChecked] = useState(false);

  const { data, loading, error } = useQuery(postsQuery);

  const handleMessage = (message: string, error: MessageStatus) => {
    setMessage({ message, error });
  };

  const handleNewsLetter = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!termChecked)
      return handleMessage("Please accept our terms and conditions", "error");

    const isValidEmail = validator.isEmail(mail);

    if (isValidEmail) {
      const res = await registerEmail(mail);
      if (res.status === 200) {
        handleMessage("Thank you for subscribing!", "completed");
      } else {
        handleMessage(res.message, "error");
      }
    } else {
      handleMessage("Please enter valid Email!", "error");
    }

    setMail("");
  };

  return (
    <>
      {/* HERO SECTION */}
      <section
        className="pb-28 pt-36 lg:px-10 lg:pb-36 lg:pt-[300px]"
        style={{
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(images/match-section-bg.webp) lightgray 50% / cover no-repeat",
        }}
      >
        <div className="container flex items-center justify-center px-4 text-center lg:justify-start lg:text-start">
          <div className="flex max-w-[440px] flex-col items-center justify-center gap-4 md:max-w-[560px] lg:max-w-[600px]">
            <h1 className="font-poppins text-2xl font-[600] !leading-[115%] text-white md:text-4xl lg:text-[48px]">
              {textHome.hero.title}
            </h1>
            <h3 className="font-quicksand text-lg font-thin leading-[150%] text-white md:text-xl lg:text-2xl">
              {textHome.hero.description}
            </h3>
            <Button
              asChild
              variant="primary"
              size="lg"
              className="mt-8 w-full px-12 py-3 text-[15px] md:w-fit md:text-base lg:mr-auto"
            >
              <Link to="/quiz-start">Find my EV match</Link>
            </Button>
          </div>
        </div>
      </section>
      {/* STEPS SECTION */}
      <section className="bg-white px-4 py-16 lg:py-20">
        <div className="flex flex-col justify-center gap-12">
          <div
            className={[
              "mx-auto w-full max-w-[500px] items-center",
              textContainerStyle,
            ].join(" ")}
          >
            <h2 className={cn(titleBaseStyle, "text-center")}>
              {textHome.steps.title}
            </h2>
            <h3 className={[descriptionBaseStyle, "text-center"].join("")}>
              {textHome.steps.description}
            </h3>
          </div>
          <ul className="flex flex-col items-center gap-8 px-4 lg:flex-row lg:items-start lg:justify-center lg:gap-12">
            {textSteps.map((i) => (
              <li
                key={i.index}
                className="flex w-[300px] flex-col items-center gap-4"
              >
                <span className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-mainGreen p-4 text-2xl lg:text-3xl">
                  {i.index}
                </span>
                <p
                  className={cn(
                    descriptionBaseStyle,
                    "text-center font-poppins",
                  )}
                >
                  {i.text}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* ABOUT QUIZ SECTION */}
      <section className="bg-lightGrayf8">
        <div className="container flex flex-col lg:grid lg:grid-cols-2">
          <div
            style={{
              background:
                'url("/images/home_1.webp") lightgray 50% / cover no-repeat',
            }}
            className="h-[240px] w-full sm:h-[340px] md:h-[400px] lg:h-[460px]"
          />
          <div
            className={[
              "m-auto w-full max-w-[400px] items-center px-4 pb-12 pt-9 lg:max-w-full lg:items-start lg:justify-center lg:pl-20",
              textContainerStyle,
            ].join(" ")}
          >
            <h2 className={[titleBaseStyle].join("")}>
              {textHome.aboutQuiz.title}
            </h2>
            <h3 className={[descriptionBaseStyle].join("")}>
              {textHome.aboutQuiz.description}
            </h3>
            <Button
              asChild
              variant="primary"
              size="lg"
              className="mt-6 w-full px-12 py-3 text-[15px] md:w-fit md:text-base lg:mr-auto lg:mt-8"
            >
              <Link to="/quiz-start">Find my EV match</Link>
            </Button>
          </div>
        </div>
      </section>
      {/* ABOUT SURVEY SECTION */}
      <section className="bg-lightGrayf8">
        <div className="container flex flex-col-reverse lg:grid lg:grid-cols-2">
          <div
            className={[
              "mx-auto w-full max-w-[400px] items-center px-4 pb-12 pt-9 lg:max-w-full lg:items-start lg:justify-center lg:pl-20",
              textContainerStyle,
            ].join(" ")}
          >
            <h2 className={[titleBaseStyle, "max-w-[500px]"].join(" ")}>
              {textHome.aboutSurvey.title}
            </h2>
            <h3 className={[descriptionBaseStyle, "max-w-[500px]"].join(" ")}>
              {textHome.aboutSurvey.description}
            </h3>
            <Button
              asChild
              variant="secondary"
              size="lg"
              className="mt-6 w-full px-12 py-3 text-[15px] md:w-fit md:text-base lg:mr-auto lg:mt-8"
            >
              <a
                href="https://au4m8wkmsai.typeform.com/to/ZOnCL17A?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx"
                target="_blank"
                rel="noreferrer noopener"
              >
                Fill out the survey
              </a>
            </Button>
            <div className="mt-6 max-w-[530px] lg:mt-8">
              <div className={cn(descriptionBaseStyle, "text-sm lg:text-base")}>
                The survey is also available in{" "}
                {surveyLinks.map((i, index) => (
                  <span key={i.name}>
                    {index !== 0
                      ? index === surveyLinks.length - 1
                        ? " and "
                        : ", "
                      : ""}
                    <a
                      className="underline"
                      href={i.link}
                      target="_blank"
                      rel="noreferrer nooseanner"
                    >
                      {i.name}
                    </a>
                    {surveyLinks.length === index - 1 && "."}
                  </span>
                ))}
                .
              </div>
            </div>
          </div>
          <div
            style={{
              background:
                'url("/images/home_2.webp") lightgray 50% / cover no-repeat',
            }}
            className="h-[240px] w-full sm:h-[340px] md:h-[400px] lg:h-[460px]"
          />
        </div>
      </section>
      {/* NEWS SECTION */}
      <section>
        <div className="container pb-12 pl-4 pt-16 lg:py-32 min-[1400px]:grid min-[1400px]:grid-cols-[auto_1fr] min-[1400px]:gap-16">
          <div
            className={[
              "hidden w-[370px] pl-10 min-[1400px]:flex",
              textContainerStyle,
            ].join(" ")}
          >
            <h2 className={[titleBaseStyle].join("")}>{textHome.news.title}</h2>
            <h3 className={[descriptionBaseStyle].join("")}>
              {textHome.news.description}
            </h3>
          </div>

          <div
            className={cn(
              textContainerStyle,
              "mb-12 w-full max-w-[400px] flex-row gap-8 pl-10 pr-6 min-[1400px]:hidden",
            )}
          >
            <h2 className={[titleBaseStyle].join("")}>Latest news</h2>
            <LinkWithArrow href="https://cli.greencarlane.com/news">
              Read all
            </LinkWithArrow>
          </div>

          <div>
            {loading ? (
              <p>Loading news...</p>
            ) : error ? (
              <p>Error loading news.</p>
            ) : (
              <div className="w-[calc(100vw_-_16px)] overflow-scroll min-[1400px]:w-[970px]">
                <ul className="flex w-fit gap-8">
                  {data.posts.edges.map((post, index: number) => (
                    <li
                      key={post.node.id}
                      className={[
                        index - 1 === post.length
                          ? "pr-10"
                          : "border-r border-r-gray-200 pr-6",
                        "h-fit",
                      ].join(" ")}
                    >
                      <div className="min-h-[350px] w-[300px] lg:h-fit">
                        <img
                          className="mb-5 h-[180px] object-cover lg:h-[260px]"
                          src={post.node.featuredImage?.node.sourceUrl}
                          alt={post.node.featuredImage?.node.altText}
                        />

                        <div>
                          <p className="mb-2.5 font-quicksand text-sm font-thin text-gray-400">
                            {post.node.date.split("T")[0].replace(/-/g, "/")}
                          </p>
                          <h3
                            className={[descriptionBaseStyle, "mb-4"].join(" ")}
                          >
                            {post.node.title}
                          </h3>
                          <LinkWithArrow
                            href={`https://cli.greencarlane.com/news/${post.node.databaseId.toString()}`}
                          >
                            Read more
                          </LinkWithArrow>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* About section */}
      <section className="flex bg-lightGrayf8 px-10 py-12 lg:py-[120px]">
        <div className="container flex flex-col gap-12 lg:grid lg:grid-cols-2">
          <div
            className={["mx-auto max-w-[500px]", textContainerStyle].join(" ")}
          >
            <h2 className={[titleBaseStyle].join("")}>
              {textHome.aboutUs.title}
            </h2>
            <h3 className={[descriptionBaseStyle].join("")}>
              {textHome.aboutUs.description}
            </h3>
            <LinkWithArrow href="/about">Learn more about us</LinkWithArrow>
          </div>
          <img
            src="/images/home_3.webp"
            className="h-[300px] w-full object-cover lg:w-[600px]"
            alt="GreenCarLane logo"
          />
        </div>
      </section>
      {/* Newsletter Section */}
      <section
        className="bg-quiz px-10 pb-16 pt-12"
        style={{
          background:
            "url(/images/newsletter_bg.webp) lightgray 50% / cover no-repeat",
        }}
      >
        <div>
          <div className="mx-auto flex w-full max-w-[500px] flex-col items-center rounded-xl bg-white px-4 py-12 lg:max-w-[860px] lg:items-start lg:px-28">
            {message.error !== "completed" ? (
              <>
                <div
                  className={[
                    "max-w-[280px] lg:max-w-[500px]",
                    textContainerStyle,
                  ].join(" ")}
                >
                  <h2 className={[titleBaseStyle].join("")}>
                    {textHome.contact.title}
                  </h2>
                  <h3 className={[descriptionBaseStyle].join("")}>
                    {textHome.contact.description}
                  </h3>
                </div>
                <form
                  onSubmit={(e) => handleNewsLetter(e)}
                  className="mt-8 w-full max-w-[400px] lg:max-w-full"
                >
                  <Input
                    type="email"
                    value={mail}
                    placeholder="Email Address"
                    className="w-full border border-gray-400 px-6 py-3 font-quicksand text-[15px] text-gray-700 placeholder:text-gray-400"
                    onChange={(e) => {
                      setMail(e.target.value);
                    }}
                    onFocusCapture={() => setMessage(initialMessage)}
                  />

                  <div className="my-6 grid grid-cols-[auto_1fr] items-center gap-2 font-quicksand text-xs text-textGray md:gap-4 md:text-[15px]">
                    <Checkbox
                      id="terms"
                      className="m-0 h-4 w-4 cursor-pointer checked:bg-darkGreen md:h-5 md:w-5"
                      onClick={(e) => setTermChecked((prev) => !prev)}
                      onFocusCapture={() => setMessage(initialMessage)}
                    />
                    <label htmlFor="terms" className="leading-5">
                      I confirm to have read the{" "}
                      <Link to="/terms" className="font-bold underline">
                        terms & conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="/privacy-policy"
                        className="font-bold underline"
                      >
                        privacy policy{" "}
                      </Link>
                      and agree to receive the newsletter, which may include
                      promotional content, updates, and offers.{" "}
                    </label>
                  </div>

                  <p className="mb-2 text-center text-sm font-[400] text-red-700 md:text-base lg:text-start">
                    {message.error && message.message}
                  </p>

                  <Button
                    type="submit"
                    size="lg"
                    variant="secondary"
                    className="mt-3 w-full px-12 py-3 text-[15px] md:text-base lg:w-fit"
                  >
                    Submit
                  </Button>
                </form>
              </>
            ) : (
              <div className="flex min-h-[200px] w-full flex-col items-center justify-center text-center">
                <h3 className="pb-4 font-poppins text-xl font-[600] text-mainGreen md:pb-6 md:text-2xl md:text-3xl">
                  Thank you{message.message}
                </h3>
                <p className="pb-6 font-quicksand text-lg text-[400] text-black md:pb-8 md:text-xl">
                  You will hear from GreeCarLane soon.{" "}
                </p>
                <img
                  src="/icons/success.svg"
                  className="h-16 w-16 md:h-20 md:w-20"
                  alt="Success"
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
