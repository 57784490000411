import { useAtom } from "jotai";
import { ReactNode } from "react";
import {
  currentQuizErrorAtom,
  initialErrorState,
  initialSelectedQuizAnswerState,
  selectedQuizAnswerAtom,
} from "../atoms/quiz";

import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useDispatch } from "react-redux";
import { resetQuizState } from "@/store/quizSlice";
import { ProviderGetResult } from "./ProviderGetResult";
import { Loader } from "@/components/svgs/Loader";

type Props = {
  text: ReactNode;
  token?: string;
};
export const LoaderFinishQuiz = ({ text, token }: Props) => {
  const [_, setAnswers] = useAtom(selectedQuizAnswerAtom);
  const [error, setError] = useAtom(currentQuizErrorAtom);
  const dispatch = useDispatch();

  const resetQuiz = () => {
    setAnswers(initialSelectedQuizAnswerState);
    setError(initialErrorState);
    dispatch(resetQuizState());
  };

  return (
    <ProviderGetResult>
      <div className="flex min-h-full items-center justify-center">
        <div className="flex w-full flex-col justify-between rounded-[24px] bg-white px-8 pb-6 pt-8 shadow-2xl md:mx-auto md:max-w-[600px] md:px-12 md:pb-8 md:pt-12">
          <div className="flex flex-col gap-8">
            {error.error ? (
              <>
                <p className="text-center">
                  {error.message === "" || !error.message
                    ? "Sorry! Something went wrong 💥"
                    : error.message}
                </p>
                <Button
                  variant="secondary"
                  size="lg"
                  asChild
                  onClick={resetQuiz}
                >
                  <Link to="/quiz-start">Go Back to the Quiz</Link>
                </Button>
              </>
            ) : (
              <>
                {text}
                <Loader className="mx-auto animate-spin" />
              </>
            )}
          </div>
        </div>
      </div>
    </ProviderGetResult>
  );
};
