import { apiUrl } from "@/statics/api";

export const registerEmail = async (email: string) => {
  try {
    const res = await fetch(`${apiUrl}/newsLetter/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });
    const data = await res.json();
    if (res.status !== 200) throw new Error("Something went wrong");
    return { status: 200, message: data?.message };
  } catch (err) {
    console.error(err);
    return { status: 400, message: err.message ?? "Something went wrong" };
  }
};
