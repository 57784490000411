export const convertKilometerToMile = (kilometer: number) => {
  return kilometer * 0.6214;
};

export const formatNumberWithKilometerOrMile = (
  userCountry: string,
  range: string,
) => {
  const numberRange = +range.split(" ")[0];
  return ["USD", "AUD"].includes(userCountry)
    ? convertKilometerToMile(numberRange).toFixed(2) + " miles"
    : numberRange + " km";
};
