import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "@/store/authSlice";
import { useOutSideClick } from "@/hooks/useOutsideClick";

import { navList } from "@/statics/header";
import { NavMenuSp } from "./NavMenuSp";
import { Button } from "./ui/button";
import { RootState } from "@/store/store";
import { useToast } from "./ui/use-toast";
import { ChevronArrowDown } from "./svgs/ChevronArrowDown";
import { Profile } from "./svgs/Profile";
// import { ShoppingCart } from "./svgs/ShoppingCart";

export const Header = () => {
  const [navOpen, setNavOpen] = useState(false);
  const popupRef = useRef<HTMLLIElement>(null);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const handleCloseOpoUp = () => setIsOpenPopup(false);

  // Close popup by clicking outside of popup
  useOutSideClick(popupRef, () => handleCloseOpoUp());

  // const [isOpenLangPopup, setIsOpenLangPopup] = useState(false); This is for Lang setting.
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const isAuthenticated = useSelector(
    (state: RootState) => state.AUTH.isAuthenticated,
  );
  const handleLogOut = () => {
    if (isAuthenticated) {
      dispatch(logout());
      navigate("/");
      toast({
        title: "Successfully logged out",
      });
    } else {
      navigate("/login");
    }
    handleCloseOpoUp();
  };

  return (
    <header className="relative z-[2px] px-4 font-poppins min-[1450px]:px-0">
      <div className="container relative flex items-center justify-center py-5 lg:justify-between">
        <button
          className={[
            "top-50% absolute left-10 z-50 -translate-y-1/2 lg:hidden",
            navOpen ? "left-[220px]" : "",
          ].join(" ")}
          onClick={() => setNavOpen((prev) => !prev)}
        >
          <div
            className={[
              "h-1.5 w-7 border-b-[1.5px] border-black transition-transform duration-300",
              navOpen
                ? "translate-x-[3px] translate-y-[5px] rotate-[48deg]"
                : "",
            ].join(" ")}
          />
          <div
            className={[
              "transition-color h-1.5 w-7 border-b-[1.5px] border-black duration-300",
              navOpen ? "border-transparent" : "",
            ].join(" ")}
          />
          <div
            className={[
              "h-1.5 w-7 border-b-[1.5px] border-black transition-transform duration-300",
              navOpen
                ? "-translate-x-[1.8px] -translate-y-[6px] -rotate-[48deg]"
                : "",
            ].join(" ")}
          />
        </button>
        {navOpen && (
          <NavMenuSp
            handleLogout={handleLogOut}
            closeSheet={() => setNavOpen(false)}
          />
        )}
        <span className="flex items-center gap-1">
          <Link to="/">
            <img
              src="/logo.svg"
              className="w-[138px] md:w-[200px]"
              alt="GreenCarLane Logo"
            />
          </Link>
        </span>

        {/* PC NAV */}
        <ul className="hidden items-center gap-4 lg:flex">
          {navList.map((item) => {
            const listStyle =
              "mx-2.5 text-base border-b-transparent transition-color duration-300 border-b hover:border-b-black";
            if (item.href) {
              return (
                <li className={listStyle} key={item.text}>
                  <a
                    href={item.href}
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    {item.text}
                  </a>
                </li>
              );
            } else if (item.link) {
              return (
                <li className={listStyle} key={item.text}>
                  <NavLink to={item.link}>{item.text}</NavLink>
                </li>
              );
            }
          })}

          <>
            {/* ICONS */}
            <li ref={popupRef} className="relative">
              <button
                onClick={() => setIsOpenPopup((prev) => !prev)}
                className="flex items-center gap-1.5 transition-opacity hover:opacity-70"
              >
                <Profile className="w-[15px]" />
                <ChevronArrowDown
                  className={`-translate-x-0.5 text-[15px] transition-transform duration-300 ${isOpenPopup ? "rotate-180" : "none"}`}
                />
              </button>
              {isOpenPopup && (
                <div className="absolute right-2 top-10 z-10 w-48 flex-col overflow-hidden rounded-2xl bg-white shadow-2xl">
                  <ul className="*:transition-colors *:duration-300">
                    {isAuthenticated ? (
                      <>
                        <li>
                          <button
                            disabled
                            className="w-full px-8 py-3.5 text-start opacity-70 hover:bg-gray-200"
                          >
                            My Profile
                          </button>
                          {/* <Link to="/profile">My Profile</Link> */}
                        </li>
                        <li>
                          <button
                            disabled
                            className="block w-full px-8 py-3.5 text-start opacity-70 hover:bg-gray-200"
                          >
                            History
                          </button>
                          {/* <Link to="profile/history">History</Link> */}
                        </li>
                        <li>
                          <button
                            disabled
                            className="block w-full px-8 py-3.5 text-start opacity-70 hover:bg-gray-200"
                          >
                            Profile Settings
                          </button>
                          {/* <Link to="/profile/setting">Profile Settings</Link> */}
                        </li>
                        <li>
                          <button
                            onClick={handleLogOut}
                            className="block w-full px-8 py-3.5 text-start hover:bg-gray-200"
                          >
                            Log out
                          </button>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <button
                            onClick={() => {
                              handleCloseOpoUp();
                              navigate("/login");
                            }}
                            className="block w-full px-8 py-3.5 opacity-70 hover:bg-gray-200"
                          >
                            Log in
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => {
                              handleCloseOpoUp();
                              navigate("/signup");
                            }}
                            className="block w-full px-8 py-3.5 opacity-70 hover:bg-gray-200"
                          >
                            Sign up
                          </button>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              )}
            </li>

            {/* Cart Icon BTN //TODO: implement function */}
            {/* <li>
              <button className="flex items-center transition-opacity hover:opacity-70">
                <ShoppingCart className="w-[22px]" />
              </button>
            </li> */}
            {/* LANGUAGE OPTION //TODO: implement function */}
            {/* <li className="relative">
              <button
                onClick={() => setIsOpenLangPopup((prev) => !prev)}
                className="flex gap-2 items-center  *:transition-opacity group"
              >
                <img src="/icons/england.svg" className="w-5 ml-1 group-hover:opacity-80" />
                 <ChevronArrowDown
                  className={`text-[15px] -translate-x-0.5 group-hover:opacity-70 ${isOpenLangPopup ? 'rotate(180deg)' : 'none'}`}
                />
              </button>
              {isOpenLangPopup && (
                <div className="absolute top-10 bg-white overflow-hidden  rounded-2xl flex flex-col shadow-2xl w-48">
                  <ul className="*:py-3.5 *:px-8 *:transition-colors *:duration-300">
                    <li className="hover:bg-gray-200">
                      <button>English</button>
                    </li>
                    <li className="hover:bg-gray-200">
                      <button>Danish</button>
                    </li>
                  </ul>
                </div>
              )}
            </li> */}
          </>

          {location.pathname !== "/quiz" && (
            <Button
              onClick={() => navigate("/quiz-start")}
              variant="primary"
              size="md"
              className="ml-6"
            >
              Find my EV match
            </Button>
          )}
        </ul>
      </div>
    </header>
  );
};
