import { EV } from "@/types/ev";

export const getCarPrices = (car: EV) => {
  const price = car.price;
  if (!price) return null;

  switch (car.country_code) {
    case "EUR":
      return price.theNetherlands;
    case "GBP":
      return price.unitedKingdom;
    case "DKK":
      return price.denmark;
    case "SEK":
      return price.sweden;
    case "NOK":
      return price.norway;
    case "USD":
      return price.usa;
    case "AUD":
      return price.australia;
    //this is just temporary, while we don't have the Canadian dollar currency on the recommendation engine, return in USD
    case "CAD":
      return price.usa;
    default:
      return price.germany;
  }
};
