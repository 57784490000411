import { SVGProps } from "@/features/quiz/types/svg";

export const ArrowRight = ({ className, color }: SVGProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.66406 10.5H16.3307"
        stroke="#024034"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 4.66406L16.3333 10.4974L10.5 16.3307"
        stroke="#024034"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
