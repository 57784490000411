import { createSlice } from '@reduxjs/toolkit';

import { jwtDecode } from 'jwt-decode';

const initialState = {
  isAuthenticated: false,
  registrationSuccess: null,
  registrationError: null,
  user: null,
  token: null,
};

const authSlice = createSlice({
  name: 'AUTH',
  initialState: {
    isAuthenticated: false,
    registrationSuccess: null,
    registrationError: null,
    user: null,
    token: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
    },

    setRegistrationSuccess: (state, action) => {
      state.registrationSuccess = action.payload;
    },
    setRegistrationError: (state, action) => {
      state.registrationError = action.payload;
    },
  },
});

export const { loginSuccess, logout, setRegistrationSuccess, setRegistrationError } =
  authSlice.actions;
export const selectRegistrationSuccess = (state) => state.AUTH.registrationSuccess;
export const selectRegistrationError = (state) => state.AUTH.registrationError;

// Middleware to check token expiration
export const checkTokenExpiration = () => (dispatch) => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      // Token is expired; dispatch the logout action
      dispatch(logout());
    }
  }
};
export default authSlice.reducer;
