import { ChevronArrowDown } from "@/components/svgs/ChevronArrowDown";
import { Profile } from "@/components/svgs/Profile";
import { ShoppingCart } from "@/components/svgs/ShoppingCart";
import { Nav } from "@/types/nav";

export const navList: Array<Nav> = [
  { text: "Home", link: "/" },
  { text: "Charging", href: "https://map.greencarlane.com/" },
  { text: "EV news", href: "https://cli.greencarlane.com/news" },
  { text: "About us", link: "/about" },
  { text: "Contact us", link: "/contact" },
  {
    icon: (
      <>
        <Profile className="w-[15px]" />
        <ChevronArrowDown className="-translate-x-0.5 text-[15px]" />
      </>
    ),
    onClick: (action: any) => action(),
  },
  {
    icon: <ShoppingCart className="w-[22px]" />,
    onClick: (action: any) => action(),
  },
  {
    icon: (
      <>
        <img src="/icons/england.svg" className="ml-1 w-5" alt="England flag" />
        <ChevronArrowDown className="-translate-x-0.5 text-[15px]" />
      </>
    ),
    onClick: (action: any) => action(),
  },
];
