import { FormEvent, useEffect, useState } from "react";

import { checkIsFormValid } from "../helpers/checkInput";

import {
  handleCreateUser,
  handleResendConfirmationEmail,
} from "../services/user";
import { CreateUserInputErrorType, NewUser } from "../types/user";
import { Input } from "@/components/ui/input";

import { ModalResendEmail } from "./ModalResendEmail";
import { AuthForm } from "./AuthForm";

const initialInputs = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const initialError: CreateUserInputErrorType = {
  general: "",
  firstName: false,
  lastName: false,
  email: false,
  password: false,
};

export const RegisterForm = () => {
  useEffect(() => {
    document.title = "Green Car Lane | Registration";
  }, []);

  const [error, setError] = useState(initialError);
  const [successMessage, setSuccessMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInputs, setInputs] = useState<NewUser>(initialInputs);

  const handleInputs = (field: keyof NewUser, value: string) => {
    setInputs((prev) => ({ ...prev, [field]: value }));
  };

  const handleError = (
    field: keyof CreateUserInputErrorType,
    value: string | boolean,
  ) => {
    setError((prev) => ({ ...prev, [field]: value }));
  };

  const submitHandler = async (event: FormEvent) => {
    event.preventDefault();
    const isValidInputs = checkIsFormValid(userInputs);

    if (!isValidInputs.isValid) {
      const {
        firstName: isFirstNameValid,
        lastName: isLastNameValid,
        email: isEmailValid,
        password: isPasswordValid,
      } = isValidInputs;

      return setError((prev) => ({
        ...prev,
        firstName: !isFirstNameValid,
        lastName: !isLastNameValid,
        email: !isEmailValid,
        password: !isPasswordValid,
      }));
    }

    const data = await handleCreateUser(userInputs);

    if (data.status === 200) return setIsModalOpen(true);
    else return handleError("general", data.data);
  };

  const resendConfirmationEmail = async () => {
    if (!userInputs.email) return;
    const data = await handleResendConfirmationEmail(userInputs.email);
    if (data.status !== 200)
      return handleError("general", "Could not resend confirmation email");
    else setSuccessMessage(data.data.message);
  };

  const clearErrorMessage = () => {
    setError(initialError);
  };

  return (
    <>
      <AuthForm submitHandler={submitHandler} title="Sign up">
        <div>
          <Input
            className={`${error.firstName ? "border border-red-300 bg-red-100" : ""} `}
            id="firstName"
            minLength={3}
            maxLength={10}
            value={userInputs.firstName}
            onChange={(e) => handleInputs("firstName", e.target.value)}
            placeholder="Name"
          />
          {error.firstName && (
            <p className="text-start text-xs text-red-500 md:text-sm">
              At list 3 characters.
            </p>
          )}
        </div>
        <div>
          <Input
            className={`${error.lastName ? "border border-red-300 bg-red-100" : ""} `}
            id="lastName"
            minLength={3}
            maxLength={10}
            value={userInputs.lastName}
            onChange={(e) => handleInputs("lastName", e.target.value)}
            placeholder="Name"
          />
          {error.lastName && (
            <p className="text-start text-xs text-red-500 md:text-sm">
              At list 3 characters.
            </p>
          )}
        </div>
        <div>
          <Input
            className={`${error.email ? "border border-red-300 bg-red-100" : ""}`}
            type="email"
            id="email"
            value={userInputs.email}
            onChange={(e) => handleInputs("email", e.target.value)}
            onFocus={clearErrorMessage}
            placeholder="Email address"
          />

          {error.email && (
            <p className="text-start text-xs text-red-500 md:text-sm">
              Please enter a valid email address.
            </p>
          )}
        </div>
        <div>
          <Input
            className={`${error.password ? "border border-red-300 bg-red-100" : ""}`}
            type="password"
            id="password"
            value={userInputs.password}
            onChange={(e) => handleInputs("password", e.target.value)}
            placeholder="Password"
          />
          {error.password && (
            <p className="text-start text-xs text-red-500 md:text-sm">
              The password is too short.
            </p>
          )}
        </div>
        {error.general !== "" && (
          <p className="text-sm text-red-700">{error.general}</p>
        )}
      </AuthForm>

      {isModalOpen && (
        <ModalResendEmail
          email={userInputs.email}
          handleSendEmail={resendConfirmationEmail}
          messages={{ error: error.general, success: successMessage }}
          handleCloseModal={setIsModalOpen}
          onOpen={isModalOpen}
        />
      )}
    </>
  );
};
