import { useSetAtom } from "jotai";
import { infoWindowIdAtom } from "../atoms/quiz";

export const ButtonInfoTrigger = () => {
  const setInfoOpen = useSetAtom(infoWindowIdAtom);
  return (
    <button onClick={() => setInfoOpen("sp")} className="font-bold underline">
      Click here
    </button>
  );
};
