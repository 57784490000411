import { SVGProps } from "@/features/quiz/types/svg";

export const Whatsapp = ({ className, color }: SVGProps) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M35.5176 27.5814C35.4241 27.5365 31.9248 25.8133 31.303 25.5895C31.0491 25.4984 30.7771 25.4094 30.4879 25.4094C30.0154 25.4094 29.6184 25.6449 29.3092 26.1074C28.9597 26.627 27.9015 27.8639 27.5745 28.2334C27.5318 28.2822 27.4736 28.3405 27.4386 28.3405C27.4073 28.3405 26.8658 28.1175 26.7018 28.0463C22.948 26.4157 20.0987 22.4945 19.708 21.8332C19.6522 21.7382 19.6499 21.695 19.6494 21.695C19.6631 21.6447 19.7893 21.5181 19.8545 21.4528C20.0451 21.2642 20.2516 21.0157 20.4514 20.7752C20.546 20.6613 20.6407 20.5472 20.7338 20.4397C21.0236 20.1024 21.1527 19.8406 21.3023 19.5373L21.3807 19.3798C21.746 18.654 21.434 18.0415 21.3331 17.8438C21.2504 17.6783 19.7728 14.1122 19.6157 13.7376C19.238 12.8335 18.7388 12.4126 18.0451 12.4126C17.9808 12.4126 18.0451 12.4126 17.7752 12.424C17.4465 12.4378 15.6567 12.6735 14.8653 13.1723C14.0261 13.7014 12.6063 15.388 12.6063 18.354C12.6063 21.0235 14.3004 23.5439 15.0277 24.5025C15.0458 24.5267 15.0789 24.5758 15.1271 24.6462C17.9125 28.7141 21.3849 31.7287 24.9049 33.1349C28.2938 34.4886 29.8985 34.645 30.8108 34.645H30.811C31.1943 34.645 31.5012 34.615 31.7719 34.5883L31.9436 34.5719C33.1143 34.4681 35.687 33.1351 36.2722 31.5088C36.7332 30.2279 36.8548 28.8285 36.5481 28.3206C36.338 27.9752 35.976 27.8014 35.5176 27.5814Z"
        fill="black"
      />
      <path
        d="M24.4261 0C11.4262 0 0.849939 10.4967 0.849939 23.3989C0.849939 27.572 1.96672 31.6568 4.0823 35.2319L0.0330028 47.1766C-0.0424258 47.3993 0.0136781 47.6455 0.178406 47.8132C0.297315 47.9346 0.458614 48 0.623341 48C0.686458 48 0.750042 47.9905 0.812068 47.9707L13.2671 44.0129C16.6755 45.834 20.5276 46.7952 24.4262 46.7952C37.4249 46.7954 48 36.2997 48 23.3989C48 10.4967 37.4249 0 24.4261 0ZM24.4261 41.9212C20.7576 41.9212 17.2045 40.8619 14.1503 38.8577C14.0476 38.7903 13.9284 38.7557 13.8084 38.7557C13.745 38.7557 13.6814 38.7653 13.6195 38.785L7.38028 40.7682L9.39441 34.8261C9.45956 34.6337 9.42698 34.4216 9.30698 34.2577C6.98116 31.0797 5.75171 27.325 5.75171 23.3989C5.75171 13.1844 14.129 4.87419 24.4259 4.87419C34.7216 4.87419 43.0979 13.1844 43.0979 23.3989C43.0981 33.6122 34.7219 41.9212 24.4261 41.9212Z"
        fill="black"
      />
    </svg>
  );
};
