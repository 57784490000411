import { SVGProps } from "@/features/quiz/types/svg";

export const ChevronArrowRight = ({ className, color }: SVGProps) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.94766 6.27394L0.347656 1.67394L1.40148 0.620117L7.05531 6.27394L1.40148 11.9278L0.347656 10.8739L4.94766 6.27394Z"
        fill="#1C1B1F"
      />
    </svg>
  );
};
