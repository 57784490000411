import { CarCard } from "./carCard/CarCard";
import { Button } from "@/components/ui/button";
import { useAtomValue } from "jotai";
import { quizResultAtom } from "../atoms/quiz";
import { EV } from "@/types/ev";
import { useMemo } from "react";
import { generateURL } from "../helpers/generateURLWithEVids";
import { ButtonShareLink } from "@/components/ButtonShareLink";
import NoRecommendationsCard from "./NoRecommendationsCard";

export const RecommendationTopEvs = () => {
  const quizResult: EV[] = useAtomValue(quizResultAtom);
  //If the quizResult contains recommendations, show maximum 3. The other quizResult/recommendations goes to RecommendationMoreSection
  const recommendedEVs = quizResult?.slice(0, 3);

  const EVidsWithScore = useMemo(() => {
    return recommendedEVs?.map((car: EV) => ({
      id: car.EV_id,
      score: car.matching_score,
    }));
  }, [recommendedEVs]);

  return (
    <div className="mx-auto md:max-w-[1500px]">
      {/* If any recommendations found */}
      {recommendedEVs?.length > 0 ? (
        <div className="relative mx-auto max-w-[600px] rounded-2xl bg-white px-5 py-8 shadow-2xl lg:px-6 lg:pb-16 lg:pt-8 xl:w-fit xl:max-w-fit 2xl:px-12">
          <h2 className="mb-8 text-center font-poppins text-xl font-[600] text-black md:text-3xl">
            TOP PICKS FOR YOU
          </h2>

          <ButtonShareLink
            url={EVidsWithScore && generateURL(EVidsWithScore)}
            btnClassName="absolute right-6 top-3 lg:top-4"
            imgClassName="w-6 md:w-9"
          />
          <div className="grid gap-8 xl:grid-cols-3">
            {recommendedEVs.map((car, index) => (
              <CarCard key={index} car={car} />
            ))}
          </div>
          <div className="flex flex-col items-center gap-8 pt-16 xl:flex-row xl:justify-between">
            <p className="font-poppins text-lg font-[400] xl:flex xl:items-center xl:gap-4 xl:text-2xl">
              <span>
                Comparison<span className="hidden xl:inline">:</span>
              </span>
              <span className="hidden text-base text-black/70 xl:inline-block">
                Add at least two cars
              </span>
            </p>
            <Button
              className="bg-darkGreen/70 px-12 py-4 text-base hover:bg-darkGreen/60"
              variant="secondary"
              disabled
            >
              Compare
            </Button>
          </div>
        </div>
      ) : (
        // If the quizResult does NOT contain any recommendations
        <NoRecommendationsCard />
      )}
    </div>
  );
};
