import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAtom, useSetAtom } from "jotai";
import { RootState } from "@/store/store";
import { incrementIndex, resetQuizState } from "@/store/quizSlice";
import {
  currentQuizErrorAtom,
  currentSelectedOptionAtom,
  initialSelectedQuizAnswerState,
  residenceCountryCurrencyAtom,
  selectedQuizAnswerAtom,
  userCountryAtom,
} from "../atoms/quiz";
import { QUESTIONS } from "@/features/quiz/statics/quiz-question";
import { ControllerQuiz } from "../components/ControllerQuiz";
import { ButtonsQuizBreak } from "../components/ButtonsQuizBreak";
import { LoaderFinishQuiz } from "../components/LoaderFinishQuiz";
import { useBeforeUnload } from "react-router-dom";

const breakIndex = [1, 13, 18];
const loadingIndex = QUESTIONS.length - 1;

export const QuizMain = () => {
  const dispatch = useDispatch();
  const index = useSelector((state: RootState) => state.quiz.index);
  const [error, setError] = useAtom(currentQuizErrorAtom);
  const [currentSelectedOption, setCurrentSelectedOption] = useAtom(
    currentSelectedOptionAtom,
  );
  const [answers, setAnswers] = useAtom(selectedQuizAnswerAtom);
  const setResidenceCurrency = useSetAtom(residenceCountryCurrencyAtom);
  const setUserCountry = useSetAtom(userCountryAtom);

  useEffect(() => {
    document.title = "Green Car Lane | Quiz";
  }, []);

  // reset local storage when a user leaves the page

  useBeforeUnload(
    useCallback(() => {
      setAnswers(initialSelectedQuizAnswerState);
      setCurrentSelectedOption({});
      setResidenceCurrency("");
      setUserCountry("");
      dispatch(resetQuizState());
    }, []),
  );

  // check if the quiz has default value
  useEffect(() => {
    if (answers.answer[QUESTIONS[index].answerKey]) {
      const key = QUESTIONS[index].answerKey;
      setCurrentSelectedOption({
        [key]: answers.answer[key],
      });
    }
  }, [index]);

  const handleNext = () => {
    if (index === 2) {
      const min = parseInt(currentSelectedOption["budget"]?.minAmount ?? "");
      const max = parseInt(currentSelectedOption["budget"]?.maxAmount ?? "");
      if (!currentSelectedOption["budget"]?.minAmount) {
        return setError({
          error: true,
          message: "Please provide a minimum amount.",
        });
      } else if (!currentSelectedOption["budget"]?.maxAmount) {
        return setError({
          error: true,
          message: "Please provide a maximum amount.",
        });
      } else if (isNaN(min) || isNaN(max)) {
        return setError({
          error: true,
          message: "Please provide valid numeric values.",
        });
      } else if (min >= max) {
        setError({
          error: true,
          message:
            "The maximum amount must be greater than the minimum amount.",
        });
      }
    } else if (
      Object.keys(currentSelectedOption).length === 0 &&
      currentSelectedOption.constructor === Object
    ) {
      return setError({ error: true, message: "Please select an answer" });
    }

    setAnswers((prev) => {
      return {
        answer: {
          ...prev["answer"],
          [QUESTIONS[index].answerKey]:
            currentSelectedOption[QUESTIONS[index].answerKey],
        },
      };
    });
    setCurrentSelectedOption({});
    setError({ error: false, message: "" });
    dispatch(incrementIndex());
  };

  const options = {
    loading: <LoaderFinishQuiz text={QUESTIONS[index].question} />,
    break: (
      <div className="flex min-h-full items-center justify-center md:block md:pt-32">
        <div className="flex w-full flex-col justify-between gap-28 rounded-[24px] bg-white px-6 pb-8 pt-10 shadow-2xl md:mx-auto md:min-h-fit md:max-w-[800px] md:px-24 md:pb-16 md:pt-12">
          <div className="flex flex-col gap-6">
            {QUESTIONS[index].question}
            <ButtonsQuizBreak />
          </div>
        </div>
      </div>
    ),
    quizPanel: (
      <div className="flex min-h-full w-full flex-col justify-between gap-32 rounded-[24px] bg-white px-6 pb-8 pt-10 shadow-2xl md:min-h-fit md:px-24 md:pb-16 md:pt-12">
        <div className="flex flex-col gap-6">
          <div>{QUESTIONS[index].question}</div>
          <div>
            <div className="mb-8 h-4 text-center text-red-900">
              {error.error && (
                <p className="text-sm">
                  {error.message ?? "Something went wrong"}
                </p>
              )}
            </div>
            {QUESTIONS[index].answer(QUESTIONS[index].answerKey)}
          </div>
        </div>
        <ControllerQuiz
          handleNext={handleNext}
          isLastQuiz={index === QUESTIONS.length - 2}
        />
      </div>
    ),
  };

  const key: keyof typeof options =
    index === loadingIndex
      ? "loading"
      : breakIndex.includes(index)
        ? "break"
        : "quizPanel";

  return <>{options[key]}</>;
};
