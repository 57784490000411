import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "./ui/dialog";
import { LINK_SHARE } from "@/features/quiz/statics/links-share";
import { useToast } from "./ui/use-toast";
import { DialogDescription } from "@radix-ui/react-dialog";

type Props = {
  btnClassName?: string;
  imgClassName?: string;
  spanClassName?: string;
  url: string;
};

export const ButtonShareLink = ({
  btnClassName,
  imgClassName,
  spanClassName,
  url,
}: Props) => {
  const { toast } = useToast();
  return (
    <Dialog>
      <DialogTrigger asChild>
        <button
          className={`flex flex-col items-center gap-4 transition-opacity hover:opacity-70 lg:top-4 ${btnClassName}`}
        >
          <img
            src="/quiz/icon/share.svg"
            className={`w-5 md:w-6 ${imgClassName}`}
          />
          <span
            className={`-mt-3 font-poppins text-sm font-[400] text-black/60 ${spanClassName}`}
          >
            Share
          </span>
        </button>
      </DialogTrigger>
      <DialogContent className="fixed bottom-0 left-auto top-auto w-full max-w-full translate-x-0 translate-y-0 px-0 sm:bottom-auto sm:left-[50%] sm:top-[50%] sm:w-[90%] sm:max-w-lg sm:translate-x-[-50%] sm:translate-y-[-50%] sm:px-0 md:px-0">
        <DialogDescription className="hidden">share content</DialogDescription>
        <DialogTitle className="border-b border-black/30 pb-4 pt-2 text-center sm:pb-6">
          Share to...
        </DialogTitle>
        <div className="px-10 pb-8 pt-4">
          <ul className="mx-auto flex w-full max-w-[400px] flex-col gap-6 sm:max-w-full sm:gap-8">
            {LINK_SHARE.map(({ text, icon }, index) => (
              <li className="w-full" key={index}>
                {text === "Link" ? icon(url, toast) : icon(url)}
              </li>
            ))}
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};
