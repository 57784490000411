export const generateURL = (
  evIdWithScore: Array<{ id: number; score: number }>,
) => {
  const siteURL = window.location.href;

  const params = evIdWithScore.reduce(
    (acc, cur, index) =>
      acc + `${index !== 0 ? "&" : "?"}id=${cur.id}&score=${cur.score}`,
    "",
  );
  return siteURL + "/share" + params;
};
