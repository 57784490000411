import { RadioGroup } from "@/components/ui/radio-group";
import { RadioQuiz } from "./RadioQuiz";
import { useAtom, useSetAtom } from "jotai";
import { currentQuizErrorAtom, currentSelectedOptionAtom } from "../atoms/quiz";

type Props = {
  radioData: Array<{ answerId: number; amount: string }>;
  answerKey: string;
};

export const GroupRadio = ({ radioData, answerKey }: Props) => {
  const [currentSelectedOption, setCurrentSelectedValue] = useAtom(
    currentSelectedOptionAtom,
  );
  const setError = useSetAtom(currentQuizErrorAtom);

  const handleChange = (value: string) => {
    setCurrentSelectedValue((prev) => {
      if (prev[answerKey] && prev[answerKey] === value) return {};
      return { [answerKey]: value };
    });
    setError({ error: false, message: "" });
  };
  return (
    <RadioGroup
      key={answerKey}
      className="grid gap-6 lg:grid-cols-5"
      onValueChange={handleChange}
      defaultValue={currentSelectedOption[answerKey]}
    >
      {radioData.map((i) => (
        <RadioQuiz
          label={i.amount}
          key={i.answerId}
          id={i.answerId}
          defaultChecked={currentSelectedOption[answerKey] === i.amount}
        />
      ))}
    </RadioGroup>
  );
};
