import { QuetionOption } from "../types/question";

//car related questions
export const SEAT_OPTIONS: QuetionOption[] = [
  { answerId: 0, amount: "2 seats" },
  { answerId: 1, amount: "4 seats" },
  { answerId: 2, amount: "5 seats" },
  { answerId: 3, amount: "7 seats" },
];

export const RANGE_OPTIONS: QuetionOption[] = [
  { answerId: 0, amount: "50 km" },
  { answerId: 1, amount: "51-100 km" },
  { answerId: 2, amount: "101-200 km" },
  { answerId: 3, amount: "201-300 km" },
  { answerId: 4, amount: "301+ km" },
  { answerId: 5, amount: "I'm not sure" },
];

export const CHARGING_OPTIONS: QuetionOption[] = [
  { answerId: 0, amount: "I have a charger at my garage/entryway" },
  { answerId: 1, amount: "I can only use a public charger" },
  { answerId: 2, amount: "I have a charger at my apartment building" },
  { answerId: 3, amount: "I can charge at work" },
  { answerId: 4, amount: "I have a charger nearby" },
  { answerId: 5, amount: "I'm not sure" },
];

export const TOWING_OPTIONS: QuetionOption[] = [
  { answerId: 0, amount: "Boat trailer" },
  { answerId: 1, amount: "Trailer" },
  { answerId: 2, amount: "Camping trailer" },
  { answerId: 3, amount: "All of them" },
  { answerId: 4, amount: "Horse trailer" },
  { answerId: 5, amount: "I don't need a tow hook" },
];

export const ACCESSORIES_OPTIONS: QuetionOption[] = [
  { answerId: 0, amount: "Roof bars for kayak, surf board, etc." },
  { answerId: 1, amount: "Roof box for extra storage" },
  { answerId: 2, amount: "A bike rack on the roof" },
  { answerId: 3, amount: "A tow hook" },
  { answerId: 4, amount: "Child/baby seats" },
  { answerId: 5, amount: "I don't need accessories" },
];

export const DELIVERY_TIME_OPTIONS: QuetionOption[] = [
  { answerId: 0, amount: "1-3 months" },
  { answerId: 1, amount: "4-6 months" },
  { answerId: 2, amount: "7-9 months" },
  { answerId: 3, amount: "10-12 months" },
  { answerId: 4, amount: "No preference" },
];

export const WARRANTY_TIME_OPTIONS: QuetionOption[] = [
  { answerId: 0, amount: "3 years" },
  { answerId: 1, amount: "5 years" },
  { answerId: 2, amount: "7 years" },
  { answerId: 3, amount: "10 years" },
  { answerId: 4, amount: "I don't know yet" },
];

//personality questions
export const IN_A_GROUP_OPTIONS: QuetionOption[] = [
  { answerId: 0, amount: "Be the center of attention" },
  { answerId: 1, amount: "Enjoy it and talk with a couple of people" },
  { answerId: 2, amount: "Nod and smile, sometimes talk" },
  { answerId: 3, amount: "Nod and smile without talking" },
  { answerId: 4, amount: "Zone out" },
  { answerId: 5, amount: "I avoid being in groups" },
];

export const LIKERT_SCALE: QuetionOption[] = [
  { answerId: 0, amount: "Strongly disagree" },
  { answerId: 1, amount: "Disagree" },
  { answerId: 2, amount: "Neutral" },
  { answerId: 3, amount: "Agree" },
  { answerId: 4, amount: "Strongly agree" },
];

export const GENDER_OPTIONS: QuetionOption[] = [
  { answerId: 0, amount: "Male" },
  { answerId: 1, amount: "Female" },
  { answerId: 2, amount: "Transgender" },
  { answerId: 3, amount: "Non-binary" },
  { answerId: 5, amount: "Prefer not to say" },
  { answerId: 4, amount: "Gender not listed" },
];

export const EDUCATION_OPTIONS: QuetionOption[] = [
  { answerId: 0, amount: "High School Diploma or Equivalent" },
  { answerId: 1, amount: "Associate's Degree" },
  { answerId: 2, amount: "Apprenticeship" },
  { answerId: 3, amount: "Bachelor's degree" },
  { answerId: 4, amount: "Master's degree" },
  { answerId: 5, amount: "PhD" },
  { answerId: 6, amount: "Other" },
  { answerId: 7, amount: "Prefer not to say" },
];

export const RESIDENCE_TYPE_OPTIONS: QuetionOption[] = [
  { answerId: 1, amount: "House" },
  { answerId: 2, amount: "Apartment / Condo" },
  { answerId: 3, amount: "Farm" },
  { answerId: 4, amount: "Other" },
];
