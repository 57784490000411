import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";
import { currentSelectedOptionAtom } from "../atoms/quiz";
import { useAtom } from "jotai";

type Props = {
  options: Array<{ answerId: number; amount: string }>;
  answerKey: string;
  placeholder: string;
};

export const SelectQuiz = ({ options, answerKey, placeholder = "" }: Props) => {
  const [selectedItem, setSelectedItem] = useAtom(currentSelectedOptionAtom);

  const handleSelect = (v: string) => {

    setSelectedItem((prev) => {
      if (prev[answerKey] === v) return {};
      return { [answerKey]: v };
    });
  };

  return (
    <Select
      key={answerKey}
      onValueChange={handleSelect}
      value={selectedItem[answerKey]}
      defaultValue={selectedItem[answerKey]}
    >
      <SelectTrigger className="mx-auto w-full max-w-[360px]">
        {selectedItem[answerKey] ? selectedItem[answerKey] : placeholder}
      </SelectTrigger>
      <SelectContent>
        {options.map((i) => (
          <SelectItem value={i.amount} key={i.answerId}>
            {i.amount}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
