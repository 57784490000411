import QuizResult from "@/features/quiz/pages/QuizResult";
import { useEffect } from "react";

export const QuizResultPage = () => {
  useEffect(() => {
    document.title = "Green Car Lane | Quiz result";
  }, []);

  return <QuizResult />;
};
