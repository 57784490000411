import { cn } from "@/lib/utils";
import { ReactNode, useState } from "react";
import { NoPicture } from "./svgs/NoPicture";

export const ImageWithLoading = ({
  imagePath,
  className,
  icon,
}: {
  imagePath: string;
  className?: string;
  icon?: ReactNode;
}) => {
  const [isImageLoading, setImageLoading] = useState(true);

  return (
    <>
      {imagePath !== "" &&
      imagePath &&
      imagePath.toLowerCase() !== "initial value" ? (
        <>
          {isImageLoading && (
            <div className="relative z-10 h-full w-full animate-pulse bg-gray-300" />
          )}
          {!isImageLoading && icon}
          <img
            src={imagePath}
            className={cn(
              "h-full w-full object-cover",
              isImageLoading ? "hidden" : "block",
              className,
            )}
            onLoad={() => {
              setImageLoading(false);
            }}
            alt="Loading icon"
          />
        </>
      ) : (
        <div className="flex h-full w-full items-center justify-center bg-gray-300">
          <NoPicture color="#4b5563" />
          {icon}
        </div>
      )}
    </>
  );
};
