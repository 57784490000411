import { ABOUT_US_WHY } from "../../statics/aboutUs";

export const WhyUs = () => {
  return (
    <section className="flex flex-col gap-8 px-6 py-12 lg:px-40 lg:py-20">
      <h3 className="text-left font-poppins text-lg font-semibold leading-[42px] tracking-tight text-darkGreen lg:text-left lg:text-[32px]">
        Why us?
      </h3>
      {ABOUT_US_WHY.map((item, index) => (
        <div
          key={index}
          className={`flex gap-3 text-gray26 ${
            index === 1 ? "flex-row-reverse" : ""
          }`}
        >
          <img
            src={item.image}
            alt={item.alt}
            className="hidden h-auto w-[400px] object-cover md:flex"
          />
          <div className="flex flex-col justify-center gap-3 bg-lightGrayf8 p-5 lg:p-12">
            <h4 className="font-poppins text-lg font-medium lg:text-[26px]">
              {item.title}
            </h4>
            <p className="font-quicksand text-base font-normal lg:text-[16px]">
              {item.description}
            </p>
          </div>
        </div>
      ))}
    </section>
  );
};
