import { Email } from "@/components/svgs/Email";
import { ShareLink } from "@/components/svgs/ShareLink";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Link } from "../types/link";
import { Messenger } from "@/components/svgs/Messenger";
import { Whatsapp } from "@/components/svgs/Whatsapp";
import { Threads } from "@/components/svgs/Threads";
import { Linkedin } from "@/components/svgs/Linkedin";
import { Facebook } from "@/components/svgs/Facebook";
import { X } from "@/components/svgs/X";

const spanStyle = "flex gap-6 sm:justify-between min-w-full group";
const pStyle =
  "font-poppins sm:text-lg text-black group-hover:opacity-70 transition-opacity";
const iconStyle =
  "w-[25px] h-[25px] md:w-[30px] md:h-[30px] group-hover:opacity-70 transition-opacity";

export const LINK_SHARE: Link[] = [
  {
    text: "Email",
    icon: (url: string) => (
      <a
        href={`mailto:?subject=GreenCarLane&body=${url}%20Checkout%20this%20site`}
        target="_blank"
        rel="noreferrer"
      >
        <span className={spanStyle}>
          <Email className={iconStyle} />
          <p className={pStyle}> Email</p>
        </span>
      </a>
    ),
  },
  {
    text: "Link",
    icon: (url: string, toast: any) => (
      <button
        className={spanStyle}
        onClick={() => {
          navigator.clipboard.writeText(url);
          toast({
            variant: "green",
            title: "Link Copied!",
          });
        }}
      >
        <ShareLink className={iconStyle} />
        <p className={pStyle}>Link</p>
      </button>
    ),
  },
  {
    text: "Messenger",
    icon: (url: string) => (
      <a
        target="_blank"
        rel="noreferrer noopener"
        href={`https://www.facebook.com/dialog/send?app_id=1439067846779267&display=popup&link=${encodeURIComponent(url)}&redirect_uri=${encodeURIComponent(url)}`}
      >
        <span className={spanStyle}>
          <Messenger className={iconStyle} />
          <p className={pStyle}>Messenger</p>
        </span>
      </a>
    ),
  },
  {
    text: "Whatsapp",
    icon: (url: string) => (
      <WhatsappShareButton url={url} className="w-full">
        <span className={spanStyle}>
          <Whatsapp className={iconStyle} />
          <p className={pStyle}> Whatsapp</p>
        </span>
      </WhatsappShareButton>
    ),
  },
  {
    text: "Threads",
    icon: (url: string) => (
      <a
        href={`https://www.threads.net/intent/post?text=${url}`}
        target="_blank"
        rel="noreferrer"
      >
        <span className={spanStyle}>
          <Threads className={iconStyle} />
          <p className={pStyle}> Threads</p>
        </span>
      </a>
    ),
  },
  {
    text: "Linkedin",
    icon: (url: string) => (
      <LinkedinShareButton url={url} className="w-full">
        <span className={spanStyle}>
          <Linkedin color="black" className={iconStyle} />
          <p className={pStyle}> Linkedin</p>
        </span>
      </LinkedinShareButton>
    ),
  },
  {
    text: "Facebook",
    icon: (url: string) => (
      <FacebookShareButton url={url} className="w-full">
        <span className={spanStyle}>
          <Facebook color="black" className={iconStyle} />
          <p className={pStyle}>Facebook</p>
        </span>
      </FacebookShareButton>
    ),
  },
  {
    text: "X",
    icon: (url: string) => (
      <TwitterShareButton url={url} className="w-full">
        <span className={spanStyle}>
          <X color="black" className={iconStyle} />
          <p className={pStyle}>X</p>
        </span>
      </TwitterShareButton>
    ),
  },
];
