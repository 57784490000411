import { Header } from "@/components/Header";
import { ScrollToTop } from "@/components/ScrollToTop";

import { Outlet } from "react-router-dom";

export const LayoutQuiz = () => {
  return (
    <div className="min-w-screen grid h-screen min-h-screen grid-rows-[auto_1fr]">
      <Header />
      <div className="h-full bg-quiz px-5 py-8 md:px-16 md:py-16">
        <div className="h-full w-full md:mx-auto md:max-w-[1200px]">
          <Outlet />
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};
