import { ReactNode } from "react";
import {
  DialogContent,
  Dialog,
  DialogOverlay,
  DialogTitle,
  DialogDescription,
} from "./ui/dialog";

type Props = {
  children: ReactNode;
  handleCloseModal?: any;
  onOpen: boolean;
};

export const ModalDarkOverly = ({
  children,
  handleCloseModal,
  onOpen,
}: Props) => {
  return (
    <Dialog onOpenChange={handleCloseModal} open={onOpen}>
      <DialogTitle />
      <DialogDescription />
      <DialogOverlay className="bg-black/60" />
      <DialogContent className="flex flex-col items-center gap-6 rounded-[24px] px-4 py-8 md:px-12 md:py-10">
        {children}
      </DialogContent>
    </Dialog>
  );
};
