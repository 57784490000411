import { Mission } from "../../types/mission";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface MissionCardProps {
  mission: Mission;
  index: number;
}

export const MissionCard = ({ mission, index }: MissionCardProps) => {
  const { title, description, image, alt } = mission;
  return (
    <div
      className={`flex flex-col-reverse items-start justify-between lg:h-fit ${index == 1 ? "md:flex-row-reverse" : "md:flex-row"}`}
    >
      <div className="flex flex-col items-center justify-center gap-3 px-4 py-8 lg:gap-12 lg:px-20 lg:py-10">
        <h2 className="font-poppins text-lg font-semibold leading-tight text-darkGreen lg:text-[34px] lg:font-semibold lg:leading-[50px]">
          {title}
        </h2>
        <div className="font-quicksand text-base font-normal leading-5 tracking-tight text-gray26 lg:text-lg lg:leading-10">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {description}
          </ReactMarkdown>
        </div>
      </div>
      <img
        src={image}
        alt={alt}
        width={989}
        height={654}
        className="aspect-auto w-full md:w-1/2"
      />
    </div>
  );
};
