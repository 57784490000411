import { apiUrl } from "@/statics/api";
import { NewUser } from "../types/user";
import { ApiRes } from "@/types/api";

export const handleCreateUser = async (newUser: NewUser) => {
  try {
    const response = await fetch(`${apiUrl}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstNameValue: newUser.firstName,
        lastNameValue: newUser.lastName ?? "",
        emailValue: newUser.email,
        passwordValue: newUser.password,
      }),
    });
    const data: ApiRes<{ data: any }> = await response.json();

    if (response.status === 400) return { status: 400, data: data.error };

    if (response.status === 409) return { status: 409, data: data.error };
    if (response.ok) return { status: 200, data };
  } catch (err) {
    console.log(err);
    return { status: 400, data: err };
  }
};

export const handleResendConfirmationEmail = async (email: string) => {
  try {
    const response = await fetch(`${apiUrl}/resend-confirmation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();

    if (response.ok) return { status: 200, data };
    return { status: 400, data: data.error };
  } catch (error) {
    return { status: 400, data: error };
  }
};

type LoginInputs = {
  email: string;
  password: string;
};

export const handleLogin = async (loginInputs: LoginInputs) => {
  try {
    const response = await fetch(`${apiUrl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        emailValue: loginInputs.email,
        passwordValue: loginInputs.password,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      return { status: 200, data };
    } else if (
      response.status === 404 ||
      response.status === 401 ||
      response.status === 403
    ) {
      if (data.verificationLink) {
        return {
          status: 400,
          data: data.verificationLink,
          withVerificationLink: true,
        };
      }
      return { status: 400, data: data.error };
    }
  } catch (err) {
    return { status: 400, data: "Authentication failed" };
  }
};

export const handleResetPassword = async (resetEmail: string) => {
  try {
    const response = await fetch(`${apiUrl}/forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: resetEmail,
      }),
    });

    const data = await response.json();

    if (response.status === 404) return { status: 404, data: data.error };
    else if (response.ok) return { status: 200, data };
    else return { status: 400, data: "Failed to send reset password email" };
  } catch (err) {
    return { status: 400, data: "Failed to send reset password email" };
  }
};
