// import ErrorPage from '@/components/_pages/error';
import { LayoutMain } from "@/layouts/LayoutMain";
import { LayoutQuiz } from "@/layouts/LayoutQuiz";

import { QuizStartPage } from "@/pages/QuizStart";
import { LoginPage } from "@/pages/Login";

import { QuizPage } from "@/pages/Quiz";
import { QuizResultPage } from "@/pages/QuizResult";
import { RegisterPage } from "@/pages/Register";

import { createBrowserRouter } from "react-router-dom";
import { LayoutAuth } from "@/layouts/LayoutAuth";
import { PolicyPage } from "@/pages/Policy";
import { ContactPage } from "@/pages/Contact";
import { AboutPage } from "@/pages/About";
import { HomePage } from "@/pages/Home";
import { ResetPasswordPage } from "@/pages/ResetPassword";
import { ShareResultPage } from "@/pages/ShareResult";
import { ErrorPage } from "@/pages/NotFound";

const router = createBrowserRouter([
  {
    element: <LayoutQuiz />, //Without footer layout
    errorElement: <ErrorPage path="/quiz-start" locationTo="Quiz" />,
    children: [
      {
        path: "/quiz-start",
        element: <QuizStartPage />,
      },
      {
        path: "/quiz",
        element: <QuizPage />,
      },
      {
        element: <LayoutAuth />,
        children: [
          {
            path: "/signup",
            element: <RegisterPage />,
          },
          {
            path: "/login",
            element: <LoginPage />,
          },
          {
            path: "/reset-password",
            element: <ResetPasswordPage />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <LayoutMain />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "/quiz-result",
        element: <QuizResultPage />,
      },
      {
        path: "/quiz-result/share",
        element: <ShareResultPage />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/privacy-policy",
        element: <PolicyPage />,
      },
      {
        path: "/terms",
        element: <PolicyPage />,
      },
      {
        path: "/acceptable-use",
        element: <PolicyPage />,
      },
      {
        path: "/cookie-policy",
        element: <PolicyPage />,
      },
      // {
      //   path: '/profile',
      //   element: <QuizResultPage />,
      // },
      // {
      //   element: <LayoutProtected />,
      //   children: [
      //     {
      //       path: '/profile',
      //       element: <ProfilePage />,
      //     },
      //     {
      //       path: '/profile/setting',
      //       element: <ProfileSettingPage />,
      //     },
      //     {
      //       path: '/profile/history',
      //       element: <HistoryPage />,
      //     },
      //   ],
      // },
    ],
  },
]);

export { router };
