import { Button } from "@/components/ui/button";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

type Props = {
  submitHandler: any;
  children: ReactNode;
  title: "Sign up" | "Log in" | "Reset Password";
};

export const AuthForm = ({ submitHandler, children, title }: Props) => {
  return (
    <div className="mx-auto flex w-full max-w-[350px] flex-col items-center gap-8 rounded-[24px] bg-white px-6 py-8 text-center md:max-w-[540px] md:px-12 md:py-10">
      <div className="w-full">
        <form
          className="flex flex-col items-center gap-8"
          onSubmit={submitHandler}
        >
          <h1 className="font-poppins text-xl font-[600] md:text-2xl">
            {title}
          </h1>
          {title === "Sign up" && (
            <h3 className="font-quicksand text-[15px] text-base font-[500] md:text-lg">
              Let’s get started with finding your dream electric car
            </h3>
          )}
          <div className="flex w-full flex-col gap-2">{children}</div>
          <Button
            variant="secondary"
            size="lg"
            type="submit"
            className="w-full"
          >
            {title}
          </Button>
        </form>

        {title === "Sign up" ? (
          <p className="mt-4 font-quicksand text-sm font-[500] text-gray26 md:text-base">
            Already have an account?{" "}
            <Link to="/login" className="font-bold text-mainGreen">
              Log in
            </Link>
          </p>
        ) : (
          <p className="mt-4 font-quicksand text-sm font-[500] text-gray26 md:text-base">
            Don’t have an account yet?{" "}
            <Link to="/signup" className="font-bold text-mainGreen">
              Sign up
            </Link>
          </p>
        )}
      </div>
      {title === "Sign up" && (
        <p className="font-quicksand text-xs font-[500] text-gray26 md:text-[15px]">
          By signing up to create an account I accept Company’s{" "}
          <Link to="/privacy-policy" className="text-mainGreen">
            Terms of Use and Privacy Policy.
          </Link>
        </p>
      )}
    </div>
  );
};
