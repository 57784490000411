import { Country } from "../types/country";

export const COUNTRY_LIST: Country[] = [
  { country: "Afghanistan", name: "Afghanistan Afghani", code: "AFN" },
  { country: "Albania", name: "Albanian Lek", code: "ALL" },
  { country: "Algeria", name: "Algerian Dinar", code: "DZD" },
  { country: "American Samoa", name: "US Dollar", code: "USD" },
  { country: "Andorra", name: "Euro", code: "EUR" },
  { country: "Angola", name: "Angolan Kwanza", code: "AOA" },
  { country: "Anguilla", name: "East Caribbean Dollar", code: "XCD" },
  { country: "Antarctica", name: "East Caribbean Dollar", code: "XCD" },
  {
    country: "Antigua and Barbuda",
    name: "East Caribbean Dollar",
    code: "XCD",
  },
  { country: "Argentina", name: "Argentine Peso", code: "ARS" },
  { country: "Armenia", name: "Armenian Dram", code: "AMD" },
  { country: "Aruba", name: "Aruban Guilder", code: "AWG" },
  { country: "Australia", name: "Australian Dollar", code: "AUD" },
  { country: "Austria", name: "Euro", code: "EUR" },
  { country: "Azerbaijan", name: "Azerbaijan New Manat", code: "AZN" },
  { country: "Bahamas", name: "Bahamian Dollar", code: "BSD" },
  { country: "Bahrain", name: "Bahraini Dinar", code: "BHD" },
  { country: "Bangladesh", name: "Bangladeshi Taka", code: "BDT" },
  { country: "Barbados", name: "Barbados Dollar", code: "BBD" },
  { country: "Belarus", name: "Belarussian Ruble", code: "BYR" },
  { country: "Belgium", name: "Euro", code: "EUR" },
  { country: "Belize", name: "Belize Dollar", code: "BZD" },
  { country: "Benin", name: "CFA Franc BCEAO", code: "XOF" },
  { country: "Bermuda", name: "Bermudian Dollar", code: "BMD" },
  { country: "Bhutan", name: "Bhutan Ngultrum", code: "BTN" },
  { country: "Bolivia", name: "Boliviano", code: "BOB" },
  { country: "Bosnia-Herzegovina", name: "Marka", code: "BAM" },
  { country: "Botswana", name: "Botswana Pula", code: "BWP" },
  { country: "Bouvet Island", name: "Norwegian Krone", code: "NOK" },
  { country: "Brazil", name: "Brazilian Real", code: "BRL" },
  {
    country: "British Indian Ocean Territory",
    name: "US Dollar",
    code: "USD",
  },
  { country: "Brunei Darussalam", name: "Brunei Dollar", code: "BND" },
  { country: "Bulgaria", name: "Bulgarian Lev", code: "BGN" },
  { country: "Burkina Faso", name: "CFA Franc BCEAO", code: "XOF" },
  { country: "Burundi", name: "Burundi Franc", code: "BIF" },
  { country: "Cambodia", name: "Kampuchean Riel", code: "KHR" },
  { country: "Cameroon", name: "CFA Franc BEAC", code: "XAF" },
  { country: "Canada", name: "Canadian Dollar", code: "CAD" },
  { country: "Cape Verde", name: "Cape Verde Escudo", code: "CVE" },
  {
    country: "Cayman Islands",
    name: "Cayman Islands Dollar",
    code: "KYD",
  },
  {
    country: "Central African Republic",
    name: "CFA Franc BEAC",
    code: "XAF",
  },
  { country: "Chile", name: "Chilean Peso", code: "CLP" },
  { country: "China", name: "Yuan Renminbi", code: "CNY" },
  { country: "Christmas Island", name: "Australian Dollar", code: "AUD" },
  {
    country: "Cocos (Keeling) Islands",
    name: " Australian Dollar",
    code: "AUD",
  },
  { country: "Colombia", name: "Colombian Peso", code: "COP" },
  { country: "Comoros", name: "Comoros Franc", code: "KMF" },
  { country: "Congo", name: "CFA Franc BEAC", code: "XAF" },
  { country: "Congo, Dem. Republic", name: " Francs", code: "CDF" },
  { country: "Cook Islands", name: "New Zealand Dollar", code: "NZD" },
  { country: "Costa Rica", name: "Costa Rican Colon", code: "CRC" },
  { country: "Croatia", name: "Croatian Kuna", code: "HRK" },
  { country: "Cuba", name: "Cuban Peso", code: "CUP" },
  { country: "Cyprus", name: "Euro", code: "EUR" },
  { country: "Czech Rep. ", name: "Czech Koruna", code: "CZK" },
  { country: "Denmark", name: "Danish Krone", code: "DKK" },
  { country: "Djibouti", name: "Djibouti Franc", code: "DJF" },
  { country: "Dominica", name: "East Caribbean Dollar", code: "XCD" },
  { country: "Dominican Republic", name: "Dominican Peso", code: "DOP" },
  { country: "Ecuador", name: "Ecuador Sucre", code: "ECS" },
  { country: "Egypt", name: "Egyptian Pound", code: "EGP" },
  { country: "El Salvador", name: "El Salvador Colon", code: "SVC" },
  { country: "Equatorial Guinea", name: "CFA Franc BEAC", code: "XAF" },
  { country: "Eritrea", name: "Eritrean Nakfa", code: "ERN" },
  { country: "Estonia", name: "Euro", code: "EUR" },
  { country: "Ethiopia", name: "Ethiopian Birr", code: "ETB" },
  {
    country: "Falkland Islands (Malvinas) ",
    name: "Falkland Islands Pound",
    code: "FKP",
  },
  {
    country: "Faroe Islands",
    name: "Danish Krone",
    code: "DKK",
  },
  { country: "Fiji", name: "Fiji Dollar", code: "FJD" },
  { country: "Finland", name: "Euro", code: "EUR" },
  { country: "France", name: "Euro", code: "EUR" },
  { country: "French Guiana", name: "Euro", code: "EUR" },
  { country: "French Southern Territories", name: "Euro", code: "EUR" },
  { country: "Gabon", name: "CFA Franc BEAC", code: "XAF" },
  { country: "Gambia", name: "Gambian Dalasi", code: "GMD" },
  { country: "Georgia", name: "Georgian Lari", code: "GEL" },
  { country: "Germany", name: "Euro", code: "EUR" },
  { country: "Ghana", name: "Ghanaian Cedi", code: "GHS" },
  { country: "Gibraltar", name: "Gibraltar Pound", code: "GIP" },
  { country: "Great Britain", name: "Pound Sterling", code: "GBP" },
  { country: "Greece", name: "Euro", code: "EUR" },
  { country: "Greenland", name: "Danish Krone", code: "DKK" },
  { country: "Grenada", name: "East Carribean Dollar", code: "XCD" },
  { country: "Guadeloupe (French) ", name: "Euro", code: "EUR" },
  { country: "Guam (USA) ", name: "US Dollar", code: "USD" },
  { country: "Guatemala", name: "Guatemalan Quetzal", code: "QTQ" },
  { country: "Guernsey", name: "Pound Sterling", code: "GGP" },
  { country: "Guinea", name: "Guinea Franc", code: "GNF" },
  { country: "Guinea Bissau", name: "Guinea-Bissau Peso", code: "GWP" },
  { country: "Guyana", name: "Guyana Dollar", code: "GYD" },
  { country: "Haiti", name: "Haitian Gourde", code: "HTG" },
  {
    country: "Heard Island and McDonald Islands",
    name: "Australian Dollar",
    code: "AUD",
  },
  { country: "Honduras", name: "Honduran Lempira", code: "HNL" },
  { country: "Hong Kong", name: "Hong Kong Dollar", code: "HKD" },
  { country: "Hungary", name: "Hungarian Forint", code: "HUF" },
  { country: "Iceland", name: "Iceland Krona", code: "ISK" },
  { country: "India", name: "Indian Rupee", code: "INR" },
  { country: "Indonesia", name: "Indonesian Rupiah", code: "IDR" },
  { country: "Iran", name: "Iranian Rial", code: "IRR" },
  { country: "Iraq", name: "Iraqi Dinar", code: "IQD" },
  { country: "Ireland", name: "Euro", code: "EUR" },
  { country: "Isle of Man", name: "Pound Sterling", code: "GBP" },
  { country: "Italy", name: "Euro", code: "EUR" },
  { country: "Ivory Coast", name: "CFA Franc BCEAO", code: "XOF" },
  { country: "Jamaica", name: "Jamaican Dollar", code: "JMD" },
  { country: "Japan", name: "Japanese Yen", code: "JPY" },
  { country: "Jersey", name: "Pound Sterling", code: "GBP" },
  { country: "Jordan", name: "Jordanian Dinar", code: "JOD" },
  { country: "Kazakhstan", name: "Kazakhstan Tenge", code: "KZT" },
  { country: "Kenya", name: "Kenyan Shilling", code: "KES" },
  { country: "Kiribati", name: "Australian Dollar", code: "AUD" },
  { country: "Kuwait", name: "Kuwaiti Dinar", code: "KWD" },
  { country: "Kyrgyzstan", name: "Som", code: "KGS" },
  { country: "Laos", name: "Lao Kip", code: "LAK" },
  { country: "Latvia", name: "Latvian Lats", code: "LVL" },
  { country: "Lebanon", name: "Lebanese Pound", code: "LBP" },
  { country: "Lesotho", name: "Lesotho Loti", code: "LSL" },
  { country: "Liberia", name: "Liberian Dollar", code: "LRD" },
  { country: "Libya", name: "Libyan Dinar", code: "LYD" },
  { country: "Liechtenstein", name: "Swiss Franc", code: "CHF" },
  { country: "Lithuania", name: "Lithuanian Litas", code: "LTL" },
  { country: "Luxembourg", name: "Euro", code: "EUR" },
  { country: "Macau", name: "Macau Pataca", code: "MOP" },
  { country: "North Macedonia", name: "Denar", code: "MKD" },
  { country: "Madagascar", name: "Malagasy Franc", code: "MGF" },
  { country: "Malawi", name: "Malawi Kwacha", code: "MWK" },
  { country: "Malaysia", name: "Malaysian Ringgit", code: "MYR" },
  { country: "Maldives", name: "Maldive Rufiyaa", code: "MVR" },
  { country: "Mali", name: "CFA Franc BCEAO", code: "XOF" },
  { country: "Malta", name: "Euro", code: "EUR" },
  { country: "Marshall Islands", name: "US Dollar", code: "USD" },
  { country: "Martinique (French)", name: " Euro", code: "EUR" },
  { country: "Mauritania", name: "Mauritanian Ouguiya", code: "MRO" },
  { country: "Mauritius", name: "Mauritius Rupee", code: "MUR" },
  { country: "Mayotte", name: "Euro", code: "EUR" },
  { country: "Mexico", name: "Mexican Nuevo Peso", code: "MXN" },
  { country: "Micronesia", name: "US Dollar", code: "USD" },
  { country: "Moldova", name: "Moldovan Leu", code: "MDL" },
  { country: "Monaco", code: "EUR" },
  { country: "Mongolia", name: "Mongolian Tugrik", code: "MNT" },
  { country: "Montenegro", name: "Euro", code: "EUR" },
  { country: "Montserrat", name: "East Caribbean Dollar", code: "XCD" },
  { country: "Morocco", name: "Moroccan Dirham", code: "MAD" },
  { country: "Mozambique", name: "Mozambique Metical", code: "MZN" },
  { country: "Myanmar", name: "Myanmar Kyat", code: "MMK" },
  { country: "Namibia", name: "Namibian Dollar", code: "NAD" },
  { country: "Nauru", name: "Australian Dollar", code: "AUD" },
  { country: "Nepal", name: "Nepalese Rupee", code: "NPR" },
  { country: "Netherlands", name: "Euro", code: "EUR" },
  {
    country: "Netherlands Antilles",
    name: "Netherlands Antillean Guilder",
    code: "ANG",
  },
  { country: "New Caledonia (French) ", name: "CFP Franc", code: "XPF" },
  { country: "New Zealand", name: "New Zealand Dollar", code: "NZD" },
  { country: "Nicaragua", name: "Nicaraguan Cordoba Oro", code: "NIO" },
  { country: "Niger", name: "CFA Franc BCEAO", code: "XOF" },
  { country: "Nigeria", name: "Nigerian Naira", code: "NGN" },
  { country: "Niue", name: "New Zealand Dollar", code: "NZD" },
  { country: "Norfolk Island", name: "Australian Dollar", code: "AUD" },
  { country: "North Korea", name: "North Korean Won", code: "KPW" },
  { country: "Northern Mariana Islands US Dollar", code: "USD" },
  { country: "Norway", name: "Norwegian Krone", code: "NOK" },
  { country: "Oman", name: "Omani Rial", code: "OMR" },
  { country: "Pakistan", name: "Pakistan Rupee", code: "PKR" },
  { country: "Palau", name: "US Dollar", code: "USD" },
  { country: "Palestine", name: "Palestine", code: "ILS" },
  { country: "Panama", name: "Panamanian Balboa", code: "PAB" },
  {
    country: "Papua New Guinea",
    name: "Papua New Guinea Kina",
    code: "PGK",
  },
  { country: "Paraguay", name: "Paraguay Guarani", code: "PYG" },
  { country: "Peru", name: "Peruvian Nuevo Sol", code: "PEN" },
  { country: "Philippines", name: "Philippine Peso", code: "PHP" },
  { country: "Pitcairn Island", name: "New Zealand Dollar", code: "NZD" },
  { country: "Poland", name: "Polish Zloty", code: "PLN" },
  { country: "Polynesia (French) ", name: "CFP Franc", code: "XPF" },
  { country: "Portugal", name: "Euro", code: "EUR" },
  { country: "Puerto Rico", name: "US Dollar", code: "USD" },
  { country: "Qatar", name: "Qatari Rial", code: "QAR" },
  { country: "Reunion (French) ", name: "Euro", code: "EUR" },
  { country: "Romania", name: "Romanian New Leu", code: "RON" },
  { country: "Russia", name: "Russian Ruble", code: "RUB" },
  { country: "Rwanda", name: "Rwanda Franc", code: "RWF" },
  { country: "Saint Helena", name: "St. Helena Pound", code: "SHP" },
  {
    country: "Saint Kitts &amp; Nevis Anguilla",
    name: "East Caribbean Dollar",
    code: "XCD",
  },
  { country: "Saint Lucia", name: "East Caribbean Dollar", code: "XCD" },
  {
    country: "Saint Pierre and Miquelon",
    name: "Euro",
    code: "EUR",
  },
  {
    country: "Saint Vincent &amp; Grenadines",
    name: "East Caribbean Dollar",
    code: "XCD",
  },
  { country: "Samoa", name: "Samoan Tala", code: "WST" },
  { country: "San Marino", name: "Euro", code: "EUR" },
  { country: "Sao Tome and Principe", name: " Dobra", code: "STD" },
  { country: "Saudi Arabia", name: "Saudi Riyal", code: "SAR" },
  { country: "Senegal", name: "CFA Franc BCEAO", code: "XOF" },
  { country: "Serbia", name: "Dinar", code: "RSD" },
  { country: "Seychelles", name: "Seychelles Rupee", code: "SCR" },
  { country: "Sierra Leone", name: "Sierra Leone Leone", code: "SLL" },
  { country: "Singapore", name: "Singapore Dollar", code: "SGD" },
  { country: "Slovakia", name: "Euro", code: "EUR" },
  { country: "Slovenia", name: "Euro", code: "EUR" },
  {
    country: "Solomon Islands",
    name: "Solomon Islands Dollar",
    code: "SBD",
  },
  { country: "Somalia", name: "Somali Shilling", code: "SOS" },
  { country: "South Africa", name: "South African Rand", code: "ZAR" },
  { country: "South Korea", name: "Korean Won", code: "KRW" },
  {
    country: "South Georgia &amp; South Sandwich Islands",
    name: "Pound Sterling",
    code: "GBP",
  },
  { country: "South Sudan", name: "South Sudan Pound", code: "SSP" },
  { country: "Spain", name: "Euro", code: "EUR" },
  { country: "Sri Lanka", name: "Sri Lanka Rupee", code: "LKR" },
  { country: "Sudan", name: "Sudanese Pound", code: "SDG" },
  { country: "Suriname", name: "Surinam Dollar", code: "SRD" },
  {
    country: "Svalbard and Jan Mayen Islands",
    name: "Norwegian Krone",
    code: "NOK",
  },
  { country: "Swaziland", name: "Swaziland Lilangeni", code: "SZL" },
  { country: "Sweden", name: "Swedish Krona", code: "SEK" },
  { country: "Switzerland", name: "Swiss Franc", code: "CHF" },
  { country: "Syria", name: "Syrian Pound", code: "SYP" },
  { country: "Taiwan", name: "Taiwan Dollar", code: "TWD" },
  { country: "Tajikistan", name: "Tajik Somoni", code: "TJS" },
  { country: "Tanzania", name: "Tanzanian Shilling", code: "TZS" },
  { country: "Thailand", name: "Thai Baht", code: "THB" },
  { country: "Togo", name: "CFA Franc BCEAO", code: "XOF" },
  { country: "Tokelau", name: "New Zealand Dollar", code: "NZD" },
  { country: "Tonga", name: "Tongan Pa'anga", code: "TOP" },
  {
    country: "Trinidad and Tobago",
    name: "Trinidad and Tobago Dollar",
    code: "TTD",
  },
  { country: "Tunisia", name: "Tunisian Dollar", code: "TND" },
  { country: "Turkey", name: "Turkish Lira", code: "TRY" },
  { country: "Turkmenistan", name: "Manat", code: "TMT" },
  { country: "Turks and Caicos Island", name: " US Dollar", code: "USD" },
  { country: "Tuvalu", name: "Australian Dollar", code: "AUD" },
  { country: "Uganda", name: "Uganda Shilling", code: "UGX" },
  { country: "Ukraine", name: "Ukraine Hryvnia", code: "UAH" },
  {
    country: "United Arab Emirates",
    name: "Arab Emirates Dirham",
    code: "AED",
  },
  { country: "Uruguay", name: "Uruguayan Peso", code: "UYU" },
  {
    country: "United Kingdom (U.K.)",
    name: "British Pound",
    code: "GBP",
  },
  { country: "United States (USA)", name: "US Dollar", code: "USD" },
  {
    country: "USA Minor Outlying Islands",
    name: "US Dollar",
    code: "USD",
  },
  { country: "Uzbekistan", name: "Uzbekistan Sum", code: "UZS" },
  { country: "Vanuatu", name: "Vanuatu Vatu", code: "VUV" },
  { country: "Vatican", name: "Euro", code: "EUR" },
  { country: "Venezuela", name: "Venezuelan Bolivar", code: "VEF" },
  { country: "Vietnam", name: "Vietnamese Dong", code: "VND" },
  {
    country: "Virgin Islands (British) ",
    name: "US Dollar",
    code: "USD",
  },
  { country: "Virgin Islands (USA) ", name: "US Dollar", code: "USD" },
  {
    country: "Wallis and Futuna Islands",
    name: "CFP Franc",
    code: "XPF",
  },
  { country: "Western Sahara", name: "Moroccan Dirham", code: "MAD" },
  { country: "Yemen", name: "Yemeni Rial", code: "YER" },
  { country: "Zambia", name: "Zambian Kwacha", code: "ZMW" },
  { country: "Zimbabwe", name: "Zimbabwe Dollar", code: "ZWD" },
];
