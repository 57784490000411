import { ProgressbarQuiz } from "./ProgressbarQuiz";
import { Button } from "@/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { decrementIndex } from "@/store/quizSlice";
import { RootState } from "@/store/store";
import { useSetAtom } from "jotai";
import { currentQuizErrorAtom } from "../atoms/quiz";
import { useNavigate } from "react-router-dom";

type Props = {
  handleNext: () => void;
  isLastQuiz: boolean;
};

export const ControllerQuiz = ({ handleNext, isLastQuiz }: Props) => {
  const index = useSelector((state: RootState) => state.quiz.index);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setError = useSetAtom(currentQuizErrorAtom);
  return (
    <div className="grid grid-cols-[auto_1fr_auto] items-end gap-2">
      <Button
        variant="tertiary"
        className="h-10 w-[80px] border-darkGreen text-sm text-darkGreen hover:bg-darkGreen/10 hover:text-darkGreen md:h-12 md:w-[100px] md:text-xl"
        onClick={() => {
          setError({ error: false, message: "" });
          index === 0 ? navigate(-1) : dispatch(decrementIndex());
        }}
      >
        Back
      </Button>
      <ProgressbarQuiz />
      <Button
        onClick={handleNext}
        variant="secondary"
        size="md"
        className="h-10 w-[80px] text-sm md:h-12 md:w-[100px] md:text-xl"
      >
        {isLastQuiz ? "Submit" : "Next"}
      </Button>
    </div>
  );
};
