import { EV } from "@/types/ev";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const initialErrorState = {
  error: false,
  message: null,
};

export const currentQuizErrorAtom = atom(initialErrorState);

export const currentSelectedOptionAtom = atomWithStorage<any>(
  "greenCarLaneQuiz_CurrentOption",
  {},
  {
    getItem(key, initialValue) {
      const storedValue = localStorage.getItem(key);
      try {
        return JSON.parse(storedValue ?? "");
      } catch {
        return initialValue;
      }
    },
    setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
  },
);

export const initialSelectedQuizAnswerState = { answer: {} };

export const selectedQuizAnswerAtom = atomWithStorage<any>(
  "greenCarLaneQuiz_answers",
  initialSelectedQuizAnswerState,
  {
    getItem(key, initialValue) {
      const storedValue = localStorage.getItem(key);
      try {
        return JSON.parse(storedValue ?? "");
      } catch {
        return initialValue;
      }
    },
    setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
  },
);

export const residenceCountryCurrencyAtom = atomWithStorage(
  "greenCarLaneQuiz_residenceCountryCurrency",
  "",
  {
    getItem(key, initialValue) {
      const storedValue = localStorage.getItem(key);
      try {
        return JSON.parse(storedValue ?? "");
      } catch {
        return initialValue;
      }
    },
    setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
  },
);

// Open modal for Car body type info when it has value
export const infoWindowIdAtom = atom("");

export const quizResultAtom = atomWithStorage<EV[]>(
  "GreenCarLane_QuizResult",
  null,
  {
    getItem(key, initialValue) {
      const storedValue = localStorage.getItem(key);
      try {
        return JSON.parse(storedValue ?? "");
      } catch {
        return initialValue;
      }
    },
    setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
  },
);

export const quizRecommendationsAtom = atomWithStorage<any>(
  "GreenCarLane_QuizRecommendations",
  null,
  {
    getItem(key, initialValue) {
      const storedValue = localStorage.getItem(key);

      try {
        return JSON.parse(storedValue ?? "");
      } catch {
        return initialValue;
      }
    },
    setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
  },
);

export const carBrandOptionsAtom = atom<{
  isLoading: boolean;
  options: Array<any>;
}>({ isLoading: false, options: [] });

export const userCountryAtom = atom("");
