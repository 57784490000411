import React from "react";
import ReactDOM from "react-dom/client";
import "@/styles/index.css";
import "@/styles/reset.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { store, persistor } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "./components/ui/toaster";
import ErrorBoundary from "./components/ErrorBoundary";
import { ApolloProvider } from "@apollo/client";
import { client } from "@/apollo/client";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <RouterProvider router={router} />
          </ErrorBoundary>
          <Toaster />
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
);
