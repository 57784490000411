import { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { loginSuccess } from "@/store/authSlice";
import { useNavigate } from "react-router-dom";
import { AuthForm } from "./AuthForm";
import { Input } from "@/components/ui/input";
import { handleLogin, handleResetPassword } from "../services/user";
import { checkIsLoginFormValid, isEmail } from "../helpers/checkInput";
import { ModalDarkOverly } from "@/components/ModalDarkOverly";
import { Button } from "@/components/ui/button";
import { useAtom } from "jotai";
import { redirectPathAtom } from "@/atoms";
import { Spinner } from "@/components/svgs/Spinner";

const initialLoginState = {
  password: "",
  email: "",
  resetEmail: null,
};

const initialError = {
  general: false,
  email: false,
  password: false,
  resetEmail: false,
  resetGeneral: false,
};

export const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [error, setError] = useState(initialError);
  const [loginInputs, setInputs] = useState(initialLoginState);
  const [redirectPath, setRedirectPath] = useAtom(redirectPathAtom);
  //   const [verificationLink, setVerificationLink] = useState(''); //TODO: check where this is needed
  useEffect(() => {
    document.title = "Green Car Lane | Login ";
  }, []);

  const handleInputs = (
    field: keyof typeof initialLoginState,
    value: string,
  ) => {
    setInputs((prev) => ({ ...prev, [field]: value }));
  };

  const handleError = (
    field: keyof typeof initialError,
    value: string | boolean,
  ) => {
    setError((prev) => ({ ...prev, [field]: value }));
  };

  const clearErrorMessage = (field: keyof typeof initialError) => {
    setError((prev) => ({ ...prev, [field]: false }));
  };

  const submitHandler = async (event: FormEvent) => {
    event.preventDefault();
    clearErrorMessage("general");
    const loginInfo = {
      email: loginInputs.email,
      password: loginInputs.password,
    };
    const isValidInput = checkIsLoginFormValid(loginInfo);
    if (!isValidInput.isValid) {
      return setError((prev) => ({
        ...prev,
        email: !isValidInput.email,
        password: !isValidInput.password,
      }));
    }
    const res = await handleLogin(loginInfo);
    // if (res.withVerificationLink) return setVerificationLink(res.data);
    if (res.status === 400) return handleError("general", res.data);
    const { token, user } = res.data;
    dispatch(loginSuccess({ token, user }));
    navigate(redirectPath !== "" ? redirectPath : "/");
    setRedirectPath("");
  };

  const resetPasswordHandler = async (event: FormEvent) => {
    event.preventDefault();

    clearErrorMessage("resetGeneral");
    const isValid = isEmail(loginInputs.resetEmail);

    if (!isValid) return handleError("resetEmail", true);
    setIsLoading(true);
    const res = await handleResetPassword(loginInputs.resetEmail);

    if (res.status !== 200) {
      setIsLoading(false);
      return handleError("resetGeneral", res.data);
    }
    setNotification(true);
    setIsLoading(false);
    // Optionally, clear the notification after a few seconds
    //  setTimeout(() => setNotification(''), 7000);
  };

  return (
    <>
      <AuthForm submitHandler={submitHandler} title="Log in">
        <div>
          <Input
            className={`${error.email ? "border border-red-300 bg-red-100" : ""}`}
            type="email"
            id="email"
            value={loginInputs.email}
            onChange={(e) => handleInputs("email", e.target.value)}
            onFocus={() => clearErrorMessage("email")}
            placeholder="Email address"
          />

          {error.email && (
            <p className="text-start text-xs text-red-500 md:text-sm">
              Please enter a valid email address.
            </p>
          )}
        </div>
        <div>
          <Input
            className={`${error.password ? "border border-red-300 bg-red-100" : ""}`}
            type="password"
            id="password"
            value={loginInputs.password}
            onChange={(e) => handleInputs("password", e.target.value)}
            onFocus={() => clearErrorMessage("password")}
            placeholder="Password"
          />
          {error.password && (
            <p className="text-start text-xs text-red-500 md:text-sm">
              The password is too short.
            </p>
          )}
        </div>
        <button
          className="text-end text-xs font-[500] text-gray-400 md:text-sm"
          onClick={() => setIsModalOpen(true)}
          type="button"
        >
          <span className="border-b border-b-transparent pb-0.5 transition-colors duration-200 hover:border-gray-300">
            Forgot Password?
          </span>
        </button>
        {error.general && (
          <div className="text-start text-xs text-red-500 md:text-sm">
            {error.general}
          </div>
        )}
      </AuthForm>

      {/* Reset Password Modal */}
      <ModalDarkOverly
        onOpen={isModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
      >
        <form
          className="flex flex-col items-center gap-4 md:gap-6"
          onSubmit={resetPasswordHandler}
        >
          <h3 className="font-poppins text-xl font-[600] md:text-2xl">
            Reset Password
          </h3>
          <p className="font-quicksand text-[15px] text-base font-[500] md:text-lg">
            Enter your email and your new password
          </p>
          <div className="flex w-full flex-col gap-2">
            <div>
              <Input
                className={`${error.resetEmail ? "border border-red-300 bg-red-100" : ""}`}
                type="email"
                id="reset-email"
                value={loginInputs.resetEmail}
                onChange={(e) => handleInputs("resetEmail", e.target.value)}
                onFocus={() => clearErrorMessage("resetEmail")}
                placeholder="Email address"
              />

              {error.resetEmail && (
                <p className="mt-1 text-start text-xs text-red-500 md:text-sm">
                  Please enter a valid email address.
                </p>
              )}
            </div>
          </div>
          {error.resetGeneral && (
            <div className="text-start text-xs text-red-500 md:text-sm">
              {error.resetGeneral}
            </div>
          )}
          <Button
            variant="secondary"
            size="lg"
            type="submit"
            className="w-full px-0"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner className="animate-spin" />
            ) : (
              "Send a link to reset password"
            )}
          </Button>

          {notification && (
            <p className="text-center font-quicksand text-base font-[500] text-gray26 md:text-lg">
              An email has been sent to <br />{" "}
              <strong>{loginInputs.resetEmail}</strong>
              <br /> Please check your inbox to continue.
            </p>
          )}
        </form>
      </ModalDarkOverly>
    </>
  );
};
