import { ImageWithLoading } from "@/components/ImageWithLoading";
import { Button } from "@/components/ui/button";
import { formateToShortDate } from "../../helpers";
import { EV } from "@/types/ev";
import { getCarPrices } from "../../helpers/getCarPrice";
import { formatNumberWithKilometerOrMile } from "../../helpers/convertKilometerToMile";

interface CardCarSmallProps {
  car: EV;
}

export const CardCarSmall = ({ car }: CardCarSmallProps) => {
  const price = getCarPrices(car);
  const range = formatNumberWithKilometerOrMile(
    car.country_code,
    car.energyConsumption?.WLTPRatings?.range,
  );

  const details = [
    { key: "Base price:", value: price || "-" },
    { key: "Base range:", value: range || "-" },
    {
      key: "Available:",
      value: formateToShortDate(car.availableToOrder?.germany),
    },
  ];

  const MatchingScore = ({ className }: { className: string }) => {
    return (
      <p className={`md:text-center md:text-lg md:font-bold ${className}`}>
        <span className="text-mainGreen">
          {car.matching_score ? car.matching_score : 80}%
        </span>{" "}
        match
      </p>
    );
  };

  return (
    <section className="grid w-full grid-cols-1 gap-4 overflow-hidden rounded-2xl shadow-lg xl:h-[200px] xl:grid-cols-[auto_1fr] xl:rounded-none">
      <ImageWithLoading
        imagePath={car.picture ?? "/"}
        className="relative h-[135px] w-full xl:h-[200px] xl:w-[200px] xl:min-w-[200px] 2xl:w-[300px]"
      />
      <div className="grid grid-cols-1 px-4 py-3 xl:grid-cols-2 xl:py-6 xl:pr-6">
        <div className="flex flex-col gap-4 xl:gap-6">
          <div className="flex flex-row justify-between gap-4 font-bold xl:font-normal">
            <h5 className="line-clamp-1 xl:text-xl">{car.fullName}</h5>
            <MatchingScore className="inline-block xl:hidden" />
          </div>

          <ul className="grid gap-2 font-quicksand text-sm font-[400] 2xl:text-base">
            {details.map(({ key, value }, index) => (
              <li className="flex gap-2" key={index}>
                <span>{key}</span>
                <span>{value}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col gap-6">
          <MatchingScore className="hidden xl:inline-block" />
          <div className="mt-6 flex flex-row justify-center gap-2 xl:mt-0 xl:flex xl:flex-col xl:gap-4">
            <Button variant="secondary" disabled>
              View details
            </Button>
            <Button variant="tertiary" disabled>
              Add to compare
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
