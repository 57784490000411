import { getSharedEVDetails } from "@/features/quiz/services/quiz";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { EV } from "@/types/ev";
import { CarCard } from "../components/carCard/CarCard";

export const QuizShare = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const evIds = search.getAll("id");
  const evScores = search.getAll("score");
  const [evs, setEvs] = useState<EV[]>([]);

  useEffect(() => {
    if (!evIds) return;
    (async () => {
      const data = await getSharedEVDetails(evIds);
      if (data.status !== 200) return <p>Sorry! Something went wrong</p>;
      data.data.map((car: EV, index: number) => {
        car.matching_score = Number(evScores[index]);
      });
      setEvs(data.data);
    })();
  }, []);

  return (
    <div className="h-fit min-h-screen w-screen bg-quiz px-5 py-8 md:px-16 md:py-16">
      <div className="no-print mx-auto md:max-w-[1500px]">
        <div className="mx-auto max-w-[600px] rounded-2xl bg-white px-5 py-8 shadow-2xl lg:px-6 lg:pb-16 lg:pt-8 xl:w-fit xl:max-w-fit 2xl:px-12">
          <h2 className="mb-8 text-center font-poppins text-xl font-[600] text-black md:text-3xl">
            Highest ranked {evs.length >= 2 ? "matches" : "match"}
          </h2>

          {evs && evs.length > 0 ? (
            <div className="grid justify-center gap-8 xl:grid-cols-3">
              {evs.map((car: EV, index: number) => (
                <CarCard car={car} key={index} />
              ))}
            </div>
          ) : (
            <div className="mt-2 flex flex-col gap-2 md:mt-8 md:gap-4">
              <p>Sorry, something went wrong. Try again.</p>
              <Button
                onClick={() => navigate("/")}
                variant="secondary"
                size="lg"
              >
                Home
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
