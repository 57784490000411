import { RootState } from "@/store/store";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  initialSelectedQuizAnswerState,
  quizRecommendationsAtom,
  residenceCountryCurrencyAtom,
  selectedQuizAnswerAtom,
} from "../atoms/quiz";
import { storeQuizResult } from "../services/quiz";

export const ProviderStoreResultToDB = ({
  children,
}: {
  children: ReactNode;
}) => {
  const token = useSelector((state: RootState) => state.AUTH.token);

  const quizRecommendations = useAtomValue(quizRecommendationsAtom);
  const [quizAnswer, setAnswers] = useAtom(selectedQuizAnswerAtom);
  const setResidenceCountryCurrency = useSetAtom(residenceCountryCurrencyAtom);
  useEffect(() => {
    // If there is no quiz answer result || recommendation from the engine, return
    if (!quizRecommendations || !quizAnswer) return;

    (async () => {
      try {
        // store data in mongoDB
        await storeQuizResult(token ?? null, quizRecommendations, quizAnswer);
        // initialize answer state and residence country
        setAnswers(initialSelectedQuizAnswerState);
        setResidenceCountryCurrency("");
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return <>{children}</>;
};
