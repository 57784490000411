import { Member } from "@/types/teamMembers";
import Potrait from "./Potrait";

interface TeamCardProps {
  member: Member;
}

const TeamCard = ({ member }: TeamCardProps) => {
  const { name, role, image } = member;
  const defaultImage = "/images/Team_members/Team-member-GreenCarLane.webp";
  const memberImage = image === "" ? defaultImage : image;
  return (
    <div className="flex w-[142px] flex-col items-center gap-6 lg:w-[250px]">
      <Potrait image={memberImage} alt={name} />
      <div className="flex flex-col gap-2">
        <h4 className="text-graphite font-poppins text-sm font-normal tracking-tight">
          {name}
        </h4>
        <p className="text-graphite text-center font-quicksand text-xs font-normal">
          {role}
        </p>
      </div>
    </div>
  );
};

export default TeamCard;
