import { ButtonShareLink } from "@/components/ButtonShareLink";
import { Button } from "@/components/ui/button";
import { generateURL } from "../../helpers/generateURLWithEVids";
import { getCarPrices } from "../../helpers/getCarPrice";
import { formateToShortDate, formatToShortTime } from "../../helpers";
import { formatNumberWithKilometerOrMile } from "../../helpers/convertKilometerToMile";
import { EV } from "@/types/ev";

interface CarDetailProps {
  car: EV;
  withShareBtn?: boolean;
}

const CarDetail = ({ car, withShareBtn = false }: CarDetailProps) => {
  const price = getCarPrices(car);
  const date = formateToShortDate(car.availableToOrder.germany);
  const range = formatNumberWithKilometerOrMile(
    car.country_code,
    car.energyConsumption?.WLTPRatings?.range,
  );
  const normalCharge = formatToShortTime(
    car.charging.homeDestination.chargeTime,
  );
  const fastCharge = formatToShortTime(
    car.charging.fastCharging.fastchargeTime,
  );

  const details = [
    { key: "Base price", value: price || "-" },
    { key: "Base Range", value: range || "-" },
    { key: "Battery", value: car.battery?.nominalCapacity || "-" },
    { key: "Normal Charge", value: normalCharge || "-" },
    { key: "Fast charge", value: fastCharge || "-" },
    { key: "Available", value: date || "-" },
  ];

  const actionButtons: { text: string; variant: "primary" | "tertiary" }[] = [
    { text: "View details", variant: "primary" },
    { text: "Add to compare", variant: "tertiary" },
    { text: "Book Test Drive", variant: "tertiary" },
    { text: "Customize & Order", variant: "tertiary" },
  ];
  return (
    <>
      <div className="mb-4 mt-4 px-4 md:mb-6 md:px-8">
        <ul className="grid gap-4">
          {details.map(({ key, value }, index) => (
            <li
              key={index}
              className="grid grid-cols-2 font-poppins text-[15px] text-black md:text-base"
            >
              <span className="font-[500]">{key}</span>
              <span>{value}</span>
            </li>
          ))}
        </ul>
        <div className="flex items-center justify-center gap-[80px] pt-4 md:pt-6">
          {/* uncomment for ButtonNotes */}
          {/* <ButtonNotes
            url={generateURL([{ id: car.EV_id, score: car.matching_score }])}
          btnClassName="static"
          imgClassName="w-8 md:w-8 cursor-not-allowed"
          spanClassName="text-black/50 "
        /> */}
          {withShareBtn && (
            <ButtonShareLink
              url={generateURL([{ id: car.EV_id, score: car.matching_score }])}
              imgClassName="w-8"
              spanClassName="text-black/50 "
            />
          )}
        </div>
      </div>
      <div className="grid items-end gap-3 px-4 pb-6 md:px-8 md:pb-12 xl:px-4 2xl:px-8">
        {actionButtons.map(({ text, variant }, index) => (
          <Button key={index} variant={variant} size="lg" disabled>
            {text}
          </Button>
        ))}
      </div>
    </>
  );
};

export default CarDetail;
