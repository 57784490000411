import { ModalDarkOverly } from "@/components/ModalDarkOverly";

type Props = {
  email: string;
  handleSendEmail: any;
  messages?: Record<"error" | "success", string>;
  handleCloseModal?: any;
  onOpen: boolean;
};

export const ModalResendEmail = ({
  email,
  handleSendEmail,
  messages,
  handleCloseModal,
  onOpen,
}: Props) => {
  return (
    <ModalDarkOverly onOpen={onOpen} handleCloseModal={handleCloseModal}>
      <img src="/register/email.svg" alt="envelope" className="w-[80px]" />
      <h2 className="font-poppins text-lg font-bold md:text-2xl">
        Email Confirmation
      </h2>
      <p className="text-center text-base md:text-lg">
        We sent a confirmation email to: <br /> <strong>{email}</strong> <br />{" "}
        Check your email and click on the link to continue.
      </p>
      <div className="h-2 w-full border-b border-solid border-gray-300" />
      <p>
        If you not get any mail{" "}
        <button onClick={handleSendEmail}>
          <p className="border-b border-solid border-b-transparent font-bold transition-all duration-200 hover:border-b-black/60 hover:opacity-90">
            Resend confirmation mail
          </p>
        </button>
      </p>
      {messages.error && <p>{messages.error}</p>}
      {messages.success && <p>{messages.success}</p>}
    </ModalDarkOverly>
  );
};
