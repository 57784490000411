import { Close } from "@/components/svgs/Close";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

type Props = {
  handleCloseModal: () => void;
};

export const ModalCreateAccount = ({ handleCloseModal }: Props) => {
  return (
    <div className="relative mx-auto mb-10 flex w-full flex-col-reverse items-center gap-4 rounded-lg bg-white p-5 lg:w-fit lg:flex-row lg:gap-16">
      <div className="flex gap-4 lg:flex-col">
        <Button
          variant="primary"
          size="lg"
          className="px-12 py-3 lg:px-20 lg:py-3"
          asChild
        >
          <Link to="/login">Log in</Link>
        </Button>
        <Button
          variant="secondary"
          size="lg"
          className="px-12 py-3 lg:px-20 lg:py-3"
          asChild
        >
          <Link to="/signup"> Sign Up</Link>
        </Button>
      </div>
      <div className="flex w-10/12 flex-col items-center gap-3 text-center lg:w-full lg:items-start lg:text-start min-[1150px]:w-[630px]">
        <h3 className="font-poppins text-lg lg:text-xl">
          Do you have an account?
        </h3>
        <p className="font-quicksand text-base text-black">
          Create an account to save your results and pick up where you left off
          next time.
        </p>
      </div>
      <div>
        <button
          onClick={handleCloseModal}
          className="absolute right-5 lg:static"
        >
          <Close />
        </button>
      </div>
    </div>
  );
};
