import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";
import { ScrollToTop } from "@/components/ScrollToTop";
import { Outlet } from "react-router-dom";

export const LayoutMain = () => {
  return (
    <div className="min-w-screen grid min-h-screen grid-rows-[auto_1fr_auto]">
      <Header />
      <Outlet />
      <Footer />
      <ScrollToTop />
    </div>
  );
};
