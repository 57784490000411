import { apiUrl } from "@/statics/api";

export const getQuizResult = async (
  token: string,
  answer: Record<string, any>,
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(`${apiUrl}/answers`, {
      method: "POST",
      body: JSON.stringify(answer),
      headers,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    return { status: 200, data };
  } catch (error) {
    return { status: 400, message: error.message ?? "Something went wrong 💥" };
  }
};

export const storeQuizResult = async (
  token: string | null,
  recommendations: Array<Record<string, any>>,
  answer: Record<string, any>,
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(`${apiUrl}/user/recommendations`, {
      method: "POST",
      body: JSON.stringify({ answer, recommendations }),
      headers,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return { status: 200, data };
  } catch (error) {
    return { status: 400, message: error.message ?? "Something went wrong 💥" };
  }
};

export const getSharedEVDetails = async (ids: Array<string>) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await fetch(`${apiUrl}/answers/share`, {
      method: "POST",
      body: JSON.stringify(ids),
      headers,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    return { status: 200, data };
  } catch (error) {
    return { status: 400, message: error.message ?? "Something went wrong 💥" };
  }
};
