import { NewUser } from '../types/user';
import { tldRegex } from './regex';

export const isNotEmpty = (value: string) => value.trim() !== '';
export const isName = (value: string) => /^[A-Za-z]{3,}$/.test(value);
export const isEmail = (value: string) => value.includes('@') && tldRegex.test(value);
export const isPassword = (value: string) => value.trim().length > 6;

export const checkIsFormValid = (newUser: NewUser) => {
  const isNameValid = isName(newUser.firstName);
  const isLastNameValid = isNotEmpty(newUser.lastName);
  const isEmailValid = isEmail(newUser.email);
  const isPasswordValid = isPassword(newUser.password);
  return {
    isValid: newUser.lastName
      ? isLastNameValid && isNameValid && isEmailValid && isPasswordValid
      : isNameValid && isEmailValid && isPasswordValid,
    firstName: isNameValid,
    lastName: isLastNameValid,
    email: isEmailValid,
    password: isPasswordValid,
  };
};

export const checkIsLoginFormValid = (loginInfo: { email: string; password: string }) => {
  const isEmailValid = isEmail(loginInfo.email);
  const isPasswordValid = isPassword(loginInfo.password);
  return {
    isValid: isEmailValid && isPasswordValid,
    email: isEmailValid,
    password: isPasswordValid,
  };
};
