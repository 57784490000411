import { createSlice } from "@reduxjs/toolkit";

const initialState: { index: number; progress: number } = {
  index: 0,
  progress: 0,
};

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    incrementIndex: (state) => {
      state.index += 1;
      if (state.index < 23 && state.progress < 99) {
        state.progress += 5;
      } else if (state.index === 23 && state.progress === 95) {
        state.progress += 4;
      } else if (
        state.index > 19 &&
        state.progress > 98 &&
        state.progress < 100
      ) {
        state.progress += 1;
      }
    },
    decrementIndex: (state) => {
      if (state.index > 0) {
        state.index -= 1;
        if (state.index === 1 || state.index === 13 || state.index === 18) {
          state.progress -= 0;
        } else if (state.index < 23 && state.progress > 0) {
          state.progress -= 5;
        } else if (state.progress > 99 && state.progress < 101) {
          state.progress -= 1;
        }
      }
    },
    breakIncrementIndex: (state) => {
      if (state.index === 1 || state.index === 13 || state.index === 18) {
        state.index += 1;
      }
    },
    breakDecrementIndex: (state) => {
      if (state.index === 1 || state.index === 13 || state.index === 18) {
        state.index -= 1;
        state.progress -= 5;
      } else if (state.index === 23 && state.progress === 95) {
        state.index -= 1;
        state.progress -= 5;
      } else if (state.index === 23 && state.progress === 99) {
        state.index -= 1;
        state.progress -= 4;
      }
    },
    resetQuizState: () => {
      return initialState; // Reset the state to its initial values
    },
  },
});

export const {
  incrementIndex,
  decrementIndex,
  breakDecrementIndex,
  breakIncrementIndex,
  resetQuizState,
} = quizSlice.actions;

export default quizSlice.reducer;
