import { SVGProps } from "../types/svg";

export const InfoIcon = ({ className }: SVGProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask
        id="mask0_6881_27583"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6881_27583)">
        <path
          d="M14.6641 22.668H17.3307V14.668H14.6641V22.668ZM15.9974 12.0013C16.3752 12.0013 16.6918 11.8735 16.9474 11.618C17.203 11.3624 17.3307 11.0457 17.3307 10.668C17.3307 10.2902 17.203 9.97352 16.9474 9.71797C16.6918 9.46241 16.3752 9.33463 15.9974 9.33463C15.6196 9.33463 15.303 9.46241 15.0474 9.71797C14.7918 9.97352 14.6641 10.2902 14.6641 10.668C14.6641 11.0457 14.7918 11.3624 15.0474 11.618C15.303 11.8735 15.6196 12.0013 15.9974 12.0013ZM15.9974 29.3346C14.153 29.3346 12.4196 28.9846 10.7974 28.2846C9.17517 27.5846 7.76406 26.6346 6.56406 25.4346C5.36406 24.2346 4.41406 22.8235 3.71406 21.2013C3.01406 19.5791 2.66406 17.8457 2.66406 16.0013C2.66406 14.1569 3.01406 12.4235 3.71406 10.8013C4.41406 9.17908 5.36406 7.76797 6.56406 6.56797C7.76406 5.36797 9.17517 4.41797 10.7974 3.71797C12.4196 3.01797 14.153 2.66797 15.9974 2.66797C17.8418 2.66797 19.5752 3.01797 21.1974 3.71797C22.8196 4.41797 24.2307 5.36797 25.4307 6.56797C26.6307 7.76797 27.5807 9.17908 28.2807 10.8013C28.9807 12.4235 29.3307 14.1569 29.3307 16.0013C29.3307 17.8457 28.9807 19.5791 28.2807 21.2013C27.5807 22.8235 26.6307 24.2346 25.4307 25.4346C24.2307 26.6346 22.8196 27.5846 21.1974 28.2846C19.5752 28.9846 17.8418 29.3346 15.9974 29.3346ZM15.9974 26.668C18.9752 26.668 21.4974 25.6346 23.5641 23.568C25.6307 21.5013 26.6641 18.9791 26.6641 16.0013C26.6641 13.0235 25.6307 10.5013 23.5641 8.43464C21.4974 6.36797 18.9752 5.33464 15.9974 5.33464C13.0196 5.33464 10.4974 6.36797 8.43073 8.43464C6.36406 10.5013 5.33073 13.0235 5.33073 16.0013C5.33073 18.9791 6.36406 21.5013 8.43073 23.568C10.4974 25.6346 13.0196 26.668 15.9974 26.668Z"
          fill="#838584"
        />
      </g>
    </svg>
  );
};
