import { SVGProps } from "@/features/quiz/types/svg";

export const X = ({ className, color }: SVGProps) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_12024_18873)">
        <g clipPath="url(#clip1_12024_18873)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.4608 4.17156C16.5329 4.08916 16.588 3.99337 16.6231 3.88966C16.6581 3.78595 16.6724 3.67635 16.6651 3.56713C16.6578 3.4579 16.629 3.35119 16.5804 3.25308C16.5319 3.15496 16.4645 3.06738 16.3821 2.99531C16.2997 2.92325 16.2039 2.86812 16.1002 2.83308C15.9965 2.79804 15.8869 2.78377 15.7776 2.79108C15.6684 2.79839 15.5617 2.82715 15.4636 2.8757C15.3655 2.92425 15.2779 2.99166 15.2058 3.07406L10.9475 7.94073L7.33333 3.1224C7.25571 3.0189 7.15506 2.9349 7.03934 2.87704C6.92363 2.81918 6.79604 2.78906 6.66667 2.78906H3.33333C3.17857 2.78906 3.02687 2.83216 2.89522 2.91352C2.76358 2.99488 2.65719 3.1113 2.58798 3.24972C2.51877 3.38814 2.48947 3.5431 2.50337 3.69723C2.51727 3.85137 2.57381 3.99859 2.66667 4.1224L8.03083 11.2741L3.53917 16.4074C3.4671 16.4898 3.41198 16.5856 3.37693 16.6893C3.34189 16.793 3.32762 16.9026 3.33493 17.0118C3.34225 17.1211 3.371 17.2278 3.41955 17.3259C3.46811 17.424 3.53551 17.5116 3.61792 17.5836C3.70032 17.6557 3.79611 17.7108 3.89982 17.7459C4.00353 17.7809 4.11313 17.7952 4.22235 17.7879C4.33158 17.7806 4.43829 17.7518 4.5364 17.7033C4.63452 17.6547 4.7221 17.5873 4.79417 17.5049L9.0525 12.6374L12.6667 17.4557C12.7443 17.5592 12.8449 17.6432 12.9607 17.7011C13.0764 17.7589 13.204 17.7891 13.3333 17.7891H16.6667C16.8214 17.7891 16.9731 17.746 17.1048 17.6646C17.2364 17.5832 17.3428 17.4668 17.412 17.3284C17.4812 17.19 17.5105 17.035 17.4966 16.8809C17.4827 16.7268 17.4262 16.5795 17.3333 16.4557L11.9692 9.30406L16.4608 4.17156ZM13.75 16.1224L5 4.45573H6.25L15 16.1224H13.75Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_12024_18873">
          <rect
            width="20"
            height="20"
            fill={color}
            transform="translate(0 0.289062)"
          />
        </clipPath>
        <clipPath id="clip1_12024_18873">
          <rect
            width="20"
            height="20"
            fill={color}
            transform="translate(0 0.289062)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
