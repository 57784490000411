import { Button } from "@/components/ui/button";
import { breakDecrementIndex, breakIncrementIndex } from "@/store/quizSlice";
import { useDispatch } from "react-redux";

export const ButtonsQuizBreak = () => {
  const dispatch = useDispatch();

  const onNext = () => {
    dispatch(breakIncrementIndex());
  };

  const onBack = () => {
    dispatch(breakDecrementIndex());
  };

  return (
    <div className="mx-auto flex w-full flex-col gap-2 sm:grid sm:grid-cols-2">
      <Button
        variant="tertiary"
        className="border-darkGreen font-[400] text-darkGreen hover:bg-darkGreen/10 hover:text-darkGreen md:text-lg"
        size="lg"
        onClick={onBack}
      >
        Back
      </Button>
      <Button
        variant="secondary"
        size="lg"
        className="font-[400] md:text-lg"
        onClick={onNext}
      >
        Next
      </Button>
    </div>
  );
};
