import useScreenSize from "@/lib/useScreenSize";
import { Intro as IntroType } from "../types/intro";

interface IntroProps {
  intro: IntroType;
  textAlignment?: "left" | "center" | "right" | "justify";
}

const Intro = ({ intro, textAlignment = "left" }: IntroProps) => {
  const { isMobile } = useScreenSize();
  const { title, description, image } = intro;
  return (
    <div
      className="relative flex h-[348px] items-center bg-cover bg-center px-8 font-poppins md:bg-center md:pl-[56px] lg:h-[600px] lg:bg-cover lg:pl-[70px] xl:pl-[170px]"
      style={{
        backgroundImage: `url(${isMobile ? image[0] : image[1]})`,
      }}
    >
      <div
        className="absolute inset-0"
        style={{ backgroundColor: "rgba(70, 70, 70, 0.60)" }}
      />

      <div
        className={`z-10 flex flex-col gap-5 text-white lg:gap-[50px] ${
          textAlignment === "center" ? "w-full" : "md:w-1/2"
        } text-${textAlignment}`}
      >
        <h1 className="font-poppins text-2xl font-semibold leading-7 lg:text-5xl lg:text-[48px] lg:font-medium lg:leading-[44.8px]">
          {title}
        </h1>
        <p className="w-full font-quicksand text-lg font-normal leading-snug tracking-tight sm:text-base lg:w-[120%] lg:pb-[28px] lg:text-[28px] lg:leading-[33.6px]">
          {description}
        </p>
      </div>
    </div>
  );
};

export default Intro;
